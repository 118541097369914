export const GET_EXPENSE_CODES_FAILURE = 'GET_EXPENSE_CODES_FAILURE';
export const GET_EXPENSE_CODES_REQUEST = 'GET_EXPENSE_CODES_REQUEST';
export const GET_EXPENSE_CODES_SUCCESS = 'GET_EXPENSE_CODES_SUCCESS';

export const POST_EXPENSE_CODES_FAILURE = 'POST_EXPENSE_CODES_FAILURE';
export const POST_EXPENSE_CODES_REQUEST = 'POST_EXPENSE_CODES_REQUEST';
export const POST_EXPENSE_CODES_SUCCESS = 'POST_EXPENSE_CODES_SUCCESS';

export const UPDATE_EXPENSE_CODES_FAILURE = 'UPDATE_EXPENSE_CODES_FAILURE';
export const UPDATE_EXPENSE_CODES_REQUEST = 'UPDATE_EXPENSE_CODES_REQUEST';
export const UPDATE_EXPENSE_CODES_SUCCESS = 'UPDATE_EXPENSE_CODES_SUCCESS';

export const DELETE_EXPENSE_CODES_FAILURE = 'DELETE_EXPENSE_CODES_FAILURE';
export const DELETE_EXPENSE_CODES_REQUEST = 'DELETE_EXPENSE_CODES_REQUEST';
export const DELETE_EXPENSE_CODES_SUCCESS = 'DELETE_EXPENSE_CODES_SUCCESS';

export const GET_ALL_BROKER_AGENTS_FAILURE = 'GET_ALL_BROKER_AGENTS_FAILURE';
export const GET_ALL_BROKER_AGENTS_REQUEST = 'GET_ALL_BROKER_AGENTS_REQUEST';
export const GET_ALL_BROKER_AGENTS_SUCCESS = 'GET_ALL_BROKER_AGENTS_SUCCESS';

export const GET_BROKER_AGENTS_BY_ID_FAILURE = 'GET_BROKER_AGENTS_BY_ID_FAILURE';
export const GET_BROKER_AGENTS_BY_ID_REQUEST = 'GET_BROKER_AGENTS_BY_ID_REQUEST';
export const GET_BROKER_AGENTS_BY_ID_SUCCESS = 'GET_BROKER_AGENTS_BY_ID_SUCCESS';

export const POST_BROKER_AGENTS_FAILURE = 'POST_BROKER_AGENTS_FAILURE';
export const POST_BROKER_AGENTS_REQUEST = 'POST_BROKER_AGENTS_REQUEST';
export const POST_BROKER_AGENTS_SUCCESS = 'POST_BROKER_AGENTS_SUCCESS';

export const UPDATE_BROKER_AGENTS_FAILURE = 'UPDATE_BROKER_AGENTS_FAILURE';
export const UPDATE_BROKER_AGENTS_REQUEST = 'UPDATE_BROKER_AGENTS_REQUEST';
export const UPDATE_BROKER_AGENTS_SUCCESS = 'UPDATE_BROKER_AGENTS_SUCCESS';

export const DELETE_BROKER_AGENTS_FAILURE = 'DELETE_BROKER_AGENTS_FAILURE';
export const DELETE_BROKER_AGENTS_REQUEST = 'DELETE_BROKER_AGENTS_REQUEST';
export const DELETE_BROKER_AGENTS_SUCCESS = 'DELETE_BROKER_AGENTS_SUCCESS';

export const GET_TAX_POLICIES_FAILURE = 'GET_TAX_POLICIES_FAILURE';
export const GET_TAX_POLICIES_REQUEST = 'GET_TAX_POLICIES_REQUEST';
export const GET_TAX_POLICIES_SUCCESS = 'GET_TAX_POLICIES_SUCCESS';

export const POST_TAX_POLICY_FAILURE = 'POST_TAX_POLICY_FAILURE';
export const POST_TAX_POLICY_REQUEST = 'POST_TAX_POLICY_REQUEST';
export const POST_TAX_POLICY_SUCCESS = 'POST_TAX_POLICY_SUCCESS';

export const UPDATE_TAX_POLICY_FAILURE = 'UPDATE_TAX_POLICY_FAILURE';
export const UPDATE_TAX_POLICY_REQUEST = 'UPDATE_TAX_POLICY_REQUEST';
export const UPDATE_TAX_POLICY_SUCCESS = 'UPDATE_TAX_POLICY_SUCCESS';

export const DELETE_TAX_POLICY_FAILURE = 'DELETE_TAX_POLICY_FAILURE';
export const DELETE_TAX_POLICY_REQUEST = 'DELETE_TAX_POLICY_REQUEST';
export const DELETE_TAX_POLICY_SUCCESS = 'DELETE_TAX_POLICY_SUCCESS';

export const GET_VENDORS_FAILURE = 'GET_VENDORS_FAILURE';
export const GET_VENDORS_REQUEST = 'GET_VENDORS_REQUEST';
export const GET_VENDORS_SUCCESS = 'GET_VENDORS_SUCCESS';

export const POST_VENDORS_FAILURE = 'POST_VENDORS_FAILURE';
export const POST_VENDORS_REQUEST = 'POST_VENDORS_REQUEST';
export const POST_VENDORS_SUCCESS = 'POST_VENDORS_SUCCESS';

export const UPDATE_VENDORS_FAILURE = 'UPDATE_VENDORS_FAILURE';
export const UPDATE_VENDORS_REQUEST = 'UPDATE_VENDORS_REQUEST';
export const UPDATE_VENDORS_SUCCESS = 'UPDATE_VENDORS_SUCCESS';

export const DELETE_VENDORS_FAILURE = 'DELETE_VENDORS_FAILURE';
export const DELETE_VENDORS_REQUEST = 'DELETE_VENDORS_REQUEST';
export const DELETE_VENDORS_SUCCESS = 'DELETE_VENDORS_SUCCESS';

export const GET_VENDOR_CONTACT_FAILURE = 'GET_VENDOR_CONTACT_FAILURE';
export const GET_VENDOR_CONTACT_REQUEST = 'GET_VENDOR_CONTACT_REQUEST';
export const GET_VENDOR_CONTACT_SUCCESS = 'GET_VENDOR_CONTACT_SUCCESS';

export const POST_VENDOR_CONTACT_FAILURE = 'POST_VENDOR_CONTACT_FAILURE';
export const POST_VENDOR_CONTACT_REQUEST = 'POST_VENDOR_CONTACT_REQUEST';
export const POST_VENDOR_CONTACT_SUCCESS = 'POST_VENDOR_CONTACT_SUCCESS';

export const UPDATE_VENDOR_CONTACT_FAILURE = 'UPDATE_VENDOR_CONTACT_FAILURE';
export const UPDATE_VENDOR_CONTACT_REQUEST = 'UPDATE_VENDOR_CONTACT_REQUEST';
export const UPDATE_VENDOR_CONTACT_SUCCESS = 'UPDATE_VENDOR_CONTACT_SUCCESS';

export const DELETE_VENDOR_CONTACT_FAILURE = 'DELETE_VENDOR_CONTACT_FAILURE';
export const DELETE_VENDOR_CONTACT_REQUEST = 'DELETE_VENDOR_CONTACT_REQUEST';
export const DELETE_VENDOR_CONTACT_SUCCESS = 'DELETE_VENDOR_CONTACT_SUCCESS';

export const GET_INSPECTORS_FAILURE = 'GET_INSPECTOR_FAILURE';
export const GET_INSPECTORS_REQUEST = 'GET_INSPECTOR_REQUEST';
export const GET_INSPECTORS_SUCCESS = 'GET_INSPECTOR_SUCCESS';

export const POST_INSPECTORS_FAILURE = 'POST_INSPECTOR_FAILURE';
export const POST_INSPECTORS_REQUEST = 'POST_INSPECTOR_REQUEST';
export const POST_INSPECTORS_SUCCESS = 'POST_INSPECTOR_SUCCESS';

export const UPDATE_INSPECTORS_FAILURE = 'UPDATE_INSPECTOR_FAILURE';
export const UPDATE_INSPECTORS_REQUEST = 'UPDATE_INSPECTOR_REQUEST';
export const UPDATE_INSPECTORS_SUCCESS = 'UPDATE_INSPECTOR_SUCCESS';

export const DELETE_INSPECTORS_FAILURE = 'DELETE_INSPECTOR_FAILURE';
export const DELETE_INSPECTORS_REQUEST = 'DELETE_INSPECTOR_REQUEST';
export const DELETE_INSPECTORS_SUCCESS = 'DELETE_INSPECTOR_SUCCESS';

export const GET_TITLE_REP_FAILURE = 'GET_TITLE_REP_FAILURE';
export const GET_TITLE_REP_REQUEST = 'GET_TITLE_REP_REQUEST';
export const GET_TITLE_REP_SUCCESS = 'GET_TITLE_REP_SUCCESS';

export const POST_TITLE_REP_FAILURE = 'POST_TITLE_REP_FAILURE';
export const POST_TITLE_REP_REQUEST = 'POST_TITLE_REP_REQUEST';
export const POST_TITLE_REP_SUCCESS = 'POST_TITLE_REP_SUCCESS';

export const UPDATE_TITLE_REP_FAILURE = 'UPDATE_TITLE_REP_FAILURE';
export const UPDATE_TITLE_REP_REQUEST = 'UPDATE_TITLE_REP_REQUEST';
export const UPDATE_TITLE_REP_SUCCESS = 'UPDATE_TITLE_REP_SUCCESS';

export const DELETE_TITLE_REP_FAILURE = 'DELETE_TITLE_REP_FAILURE';
export const DELETE_TITLE_REP_REQUEST = 'DELETE_TITLE_REP_REQUEST';
export const DELETE_TITLE_REP_SUCCESS = 'DELETE_TITLE_REP_SUCCESS';

export const GET_VENDOR_DOCUMENTS_FAILURE = 'GET_VENDOR_DOCUMENTS_FAILURE';
export const GET_VENDOR_DOCUMENTS_REQUEST = 'GET_VENDOR_DOCUMENTS_REQUEST';
export const GET_VENDOR_DOCUMENTS_SUCCESS = 'GET_VENDOR_DOCUMENTS_SUCCESS';

export const POST_VENDOR_DOCUMENT_FAILURE = 'POST_VENDOR_DOCUMENT_FAILURE';
export const POST_VENDOR_DOCUMENT_REQUEST = 'POST_VENDOR_DOCUMENT_REQUEST';
export const POST_VENDOR_DOCUMENT_SUCCESS = 'POST_VENDOR_DOCUMENT_SUCCESS';

export const UPDATE_VENDOR_DOCUMENT_FAILURE = 'UPDATE_VENDOR_DOCUMENT_FAILURE';
export const UPDATE_VENDOR_DOCUMENT_REQUEST = 'UPDATE_VENDOR_DOCUMENT_REQUEST';
export const UPDATE_VENDOR_DOCUMENT_SUCCESS = 'UPDATE_VENDOR_DOCUMENT_SUCCESS';

export const DELETE_VENDOR_DOCUMENT_FAILURE = 'DELETE_VENDOR_DOCUMENT_FAILURE';
export const DELETE_VENDOR_DOCUMENT_REQUEST = 'DELETE_VENDOR_DOCUMENT_REQUEST';
export const DELETE_VENDOR_DOCUMENT_SUCCESS = 'DELETE_VENDOR_DOCUMENT_SUCCESS';

export const GET_VENDOR_HISTORY_FAILURE = 'GET_VENDOR_HISTORY_FAILURE';
export const GET_VENDOR_HISTORY_REQUEST = 'GET_VENDOR_HISTORY_REQUEST';
export const GET_VENDOR_HISTORY_SUCCESS = 'GET_VENDOR_HISTORY_SUCCESS';

export const GET_VENDOR_NOTES_FAILURE = 'GET_VENDOR_NOTES_FAILURE';
export const GET_VENDOR_NOTES_REQUEST = 'GET_VENDOR_NOTES_REQUEST';
export const GET_VENDOR_NOTES_SUCCESS = 'GET_VENDOR_NOTES_SUCCESS';

export const GET_VENDOR_NOTES_BY_AUTH_FAILURE = 'GET_VENDOR_NOTES_BY_AUTH_FAILURE';
export const GET_VENDOR_NOTES_BY_AUTH_REQUEST = 'GET_VENDOR_NOTES_BY_AUTH_REQUEST';
export const GET_VENDOR_NOTES_BY_AUTH_SUCCESS = 'GET_VENDOR_NOTES_BY_AUTH_SUCCESS';

export const POST_VENDOR_NOTE_FAILURE = 'POST_VENDOR_NOTE_FAILURE';
export const POST_VENDOR_NOTE_REQUEST = 'POST_VENDOR_NOTE_REQUEST';
export const POST_VENDOR_NOTE_SUCCESS = 'POST_VENDOR_NOTE_SUCCESS';

export const UPDATE_VENDOR_NOTE_FAILURE = 'UPDATE_VENDOR_NOTE_FAILURE';
export const UPDATE_VENDOR_NOTE_REQUEST = 'UPDATE_VENDOR_NOTE_REQUEST';
export const UPDATE_VENDOR_NOTE_SUCCESS = 'UPDATE_VENDOR_NOTE_SUCCESS';

export const DELETE_VENDOR_NOTE_FAILURE = 'DELETE_VENDOR_NOTE_FAILURE';
export const DELETE_VENDOR_NOTE_REQUEST = 'DELETE_VENDOR_NOTE_REQUEST';
export const DELETE_VENDOR_NOTE_SUCCESS = 'DELETE_VENDOR_NOTE_SUCCESS';

export const GET_VENDOR_NOTE_FAILURE = 'GET_VENDOR_NOTE_FAILURE';
export const GET_VENDOR_NOTE_REQUEST = 'GET_VENDOR_NOTE_REQUEST';
export const GET_VENDOR_NOTE_SUCCESS = 'GET_VENDOR_NOTE_SUCCESS';

export const GET_VENDOR_BANKING_INFO_FAILURE = 'GET_VENDOR_BANKING_INFO_FAILURE';
export const GET_VENDOR_BANKING_INFO_REQUEST = 'GET_VENDOR_BANKING_INFO_REQUEST';
export const GET_VENDOR_BANKING_INFO_SUCCESS = 'GET_VENDOR_BANKING_INFO_SUCCESS';

export const GET_VENDOR_BANKING_INFO_BY_VENDOR_ID_FAILURE = 'GET_VENDOR_BANKING_INFO_BY_VENDOR_ID_FAILURE';
export const GET_VENDOR_BANKING_INFO_BY_VENDOR_ID_REQUEST = 'GET_VENDOR_BANKING_INFO_BY_VENDOR_ID_REQUEST';
export const GET_VENDOR_BANKING_INFO_BY_VENDOR_ID_SUCCESS = 'GET_VENDOR_BANKING_INFO_BY_VENDOR_ID_SUCCESS';

export const GET_VENDOR_BANKING_INFO_BY_ID_FAILURE = 'GET_VENDOR_BANKING_INFO_BY_ID_FAILURE';
export const GET_VENDOR_BANKING_INFO_BY_ID_REQUEST = 'GET_VENDOR_BANKING_INFO_BY_ID_REQUEST';
export const GET_VENDOR_BANKING_INFO_BY_ID_SUCCESS = 'GET_VENDOR_BANKING_INFO_BY_ID_SUCCESS';

export const POST_VENDOR_BANKING_INFO_FAILURE = 'POST_VENDOR_BANKING_INFO_FAILURE';
export const POST_VENDOR_BANKING_INFO_REQUEST = 'POST_VENDOR_BANKING_INFO_REQUEST';
export const POST_VENDOR_BANKING_INFO_SUCCESS = 'POST_VENDOR_BANKING_INFO_SUCCESS';

export const UPDATE_VENDOR_BANKING_INFO_FAILURE = 'UPDATE_VENDOR_BANKING_INFO_FAILURE';
export const UPDATE_VENDOR_BANKING_INFO_REQUEST = 'UPDATE_VENDOR_BANKING_INFO_REQUEST';
export const UPDATE_VENDOR_BANKING_INFO_SUCCESS = 'UPDATE_VENDOR_BANKING_INFO_SUCCESS';

export const DELETE_VENDOR_BANKING_INFO_FAILURE = 'DELETE_VENDOR_BANKING_INFO_FAILURE';
export const DELETE_VENDOR_BANKING_INFO_REQUEST = 'DELETE_VENDOR_BANKING_INFO_REQUEST';
export const DELETE_VENDOR_BANKING_INFO_SUCCESS = 'DELETE_VENDOR_BANKING_INFO_SUCCESS';
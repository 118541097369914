import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { styled } from '@mui/material/styles';
import { Typography } from '@material-ui/core';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 350,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
}));

const InlineToolTip = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { classes, alignCenter, ...toolTipProps } = props;
  const { label } = toolTipProps;
  const [isOpen, setIsOpen] = useState(false);

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
     
  });

  return (
    <ThemeProvider theme={theme}>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">TPI Tip:</Typography>
            <b>{label}</b>
          </React.Fragment>
        }
        placement="right"
      >
        <IconButton>
          <FontAwesomeIcon size="1x" icon={['fas', 'comments']} color={'#41B7C0'} onClick={() => {setIsOpen(!isOpen);}} />
        </IconButton>
      </HtmlTooltip>
    </ThemeProvider>
  );
};

export default (InlineToolTip);

/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import AuthorizationDetails from './authorizationDetails.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';

import { setPageTitle, showToast } from 'modules/layout/layout.actions';


import {
  getAuthClientPolicies,
  getAuthDateSummary,
  getAuthorizationSummary,
  getCommunicationInfo,
  getDepartureAddress,
  getDestinationAddress,
  getEmployeeInfo,
  getFamilyMembers,
  getFamilyPets,
  getServices,
  getTeam,
  getUserSettings,
  setAuthId,
  setLoadingComplete,
  getFinanceAndTax,
} from '../store/actions/authorizations.actions';

import { getVendors } from '../store/actions/dataManagement.actions';
import { getCommentsAndNotes } from '../store/actions/commentsAndNotes.actions';
import { fetchAuthDetails, fetchVendors, fetchCommentsAndNotes } from '../utils/fetchDataDetailsService';

const styles = theme => ({
  wideControl: {
    minWidth: 174
  },
  section: {
    marginBottom: theme.spacing(2)
  },
  selfServicePolicySelect: {
    minWidth: 300
  }
});

const AuthorizationDetailsView = props => {
  const details = props.authorizationDetails ? props.authorizationDetails[props.match.params.id] : null;
  const clientInfo =
    details && details?.authDetails?.summary && props.unmappedClients
      ? props.unmappedClients.find(e => e.description === details?.authDetails?.summary.clientName)
      : null;

  useEffect(() => {
    props.setPageTitle('Authorization Details');

    async function fetchData() {
      const id = props.match.params.id;
      props.setAuthId(id);

      await fetchAuthDetails(details, id, props);
      await fetchVendors(props?.vendors, props);
      await fetchCommentsAndNotes(details, id, props);

      props.setLoadingComplete();
    }

    fetchData();
  }, []);

  return (
    <Box>
      {!props.isLoading && details && details?.authDetails?.summary && (
        <AuthorizationDetails
          classes={props.classes}
          clientInfo={clientInfo}
          details={details}
          id={props.match.params.id}
          vendors={props.vendors}
        />
      )}
      {(props.isLoading || props.isFamilyLoading) && <FullscreenSpinner />}
    </Box>
  );
};

AuthorizationDetailsView.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { authorizationDetails, isLoading, vendors } = state.authorizations;
  const { unmappedClients } = state.clients;
  return {
    authorizationDetails,
    unmappedClients,
    isLoading,
    vendors
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getEmployeeInfo,
    getCommunicationInfo,
    getAuthClientPolicies,
    getAuthDateSummary,
    getDepartureAddress,
    getDestinationAddress,
    getTeam,
    getServices,
    getUserSettings,
    getVendors,
    getFamilyMembers,
    getFamilyPets,
    setPageTitle,
    showToast,
    setAuthId,
    setLoadingComplete,
    getFinanceAndTax,
    getCommentsAndNotes,
  })
)(AuthorizationDetailsView);

//TODO - These back be driven by the db for flexibility
export const assignmentTypeOptions = ['Inbound', 'Outbound'];
export const vendorOptions = ['Reloquest', 'Lodging Source', 'Self-Source', 'Client Direct'];
export const costCapOptions = ['Exceptions Approved', 'Excess to Employee', 'None'];
export const noticeToVacateOptions = ['2 Weeks', '30 Days', '45 Days', '60 Days'];
export const PetTypesOptions = ['Cat', 'Dog', 'Other'];

export const New = 'New';
export const Initiated = 'Initiated';
export const MovedIn = 'MovedIn';
export const MovedOut = 'MovedOut';

export const InitializationFormFields = [
  'assignmentType',
  'estimatedMoveIn',
  'estimatedMoveOut',
  'vendor',
  'costCap',
  'dailyCostCap',
  'daysAllowed',
  'noticeDueDate',
  'moveToCity',
  'moveToState',
  'numberOfAdults',
  'numberOfChildren',
  'numberOfPets',
  'numberOfBedrooms',
  'internetAccess',
  'costCapOption',
  'noticeToVacate',
  'petOneName',
  'petOneType',
  'petOneBreed',
  'petOneWeight',
  'petOneNotes',
  'petTwoName',
  'petTwoType',
  'petTwoBreed',
  'petTwoWeight',
  'petTwoNotes',
  'petThreeName',
  'petThreeType',
  'petThreeBreed',
  'petThreeWeight',
  'petThreeNotes',
  'specialNeeds',
];

export const MovedOutAndMovedInFormFields = [
  'movedInDate',
  'movedOutDate',
  'noticeDueDate',
  'daysNeeded',
  'daysUsed',
  'exceptionDays',
  'noticeGiven',
];

export const PropertyFormFields = [
  'vendorName',
  'vendorContact',
  'complexName',
  'streetAddress',
  'unitNumber',
  'city',
  'state',
  'country',
  'zipCode',
  'dailyRate',
];

export const TemporaryLivingStatus = {
  New: { sequenceOrder: 0, buttonText: 'Complete Initiation', showCompleteButtonOnField: PropertyFormFields },
  Initiated: {
    sequenceOrder: 1,
    buttonText: 'Complete Move In',
    name: 'Initiated',
    dateFieldName: 'initializationDate',
    showCompleteButtonOnField: ['movedInDate'],
  },
  MovedIn: {
    sequenceOrder: 2,
    buttonText: 'Complete Moved Out',
    name: 'Moved In',
    dateFieldName: 'updatedToMoveInStatusDate',
    showCompleteButtonOnField: ['movedOutDate', 'noticeGiven', 'daysNeeded', 'daysUsed', 'exceptionDays'],
  },
  MovedOut: {
    sequenceOrder: 3,
    name: 'Moved Out',
    dateFieldName: 'updatedToMoveOutStatusDate',
  },
};

/* eslint-disable array-callback-return */
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  Fab,
  FormControl,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { showToast } from 'modules/layout/layout.actions';
// import CancelIcon from '@mui/icons-material/Cancel';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Spinner from 'modules/common/spinner.component';

import { Link } from 'react-router-dom';
import { PauseCircle } from '@mui/icons-material';

const useStyles = makeStyles((theme) => (
  {
    card: {
      width: '100%',
      flexGrow: 1,
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    cardContainer: {
      maxWidth: '100% !important',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

    },
    iconRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    dialogIconRow: {
      display: 'flex',
      flexDirection: 'row',
      padding: '40px',
      paddingBottom: '20px',
    },
    cardDisplayContent: {
      maxWidth: '100% !important',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      margin: '10px',
    },
    chip: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    chipClickable: {
      cursor: 'pointer',
    },
    iconOverlayImg: {
      position: 'absolute',
    },
    spacingX: {
      marginRight: theme.spacing(1),
    },
    footer: {
      height: 15,
      marginBottom: theme.spacing(2),
    },
    mb2: {
      marginBottom: '3px',
      marginTop: '-6px',
      marginRight: '16px',
    },
    editModalContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100% !important',
      marginLeft: '0px !important',
    },
    editModalColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: '100% !important',
    },
    editModalInput: {
      minWidth: '332px',
    },
    dialogContentContainer: {
      margin: '15px',
    },
    labelText: {
      minWidth: 330,
      marginBottom: 0,
    },
    initiatedStatusBox: {
      border: '1px solid #67a783',
      borderRadius: '5px',
      padding: '5px',
      textAlign: 'center',
      minHeight: 30,
      background: '#67a783',
    },
    notInitiatedStatusBox: {
      border: '1px solid #fd6120',
      borderRadius: '5px',
      padding: '5px',
      textAlign: 'center',
      minHeight: 30,
      background: '#fd6120',
    },
    expensesStatusBox: {
      border: '1px solid #868686',
      borderRadius: '5px',
      padding: '5px',
      textAlign: 'center',
      minHeight: 30,
      background: '#868686',
    },
  }
));

/*
      - Required Props: 
       * tabs - a list of tabs to be included in the card
       * summaries - a list of objects detailing the specific textfield requirements for the editing feature as well as the necessary values to display
       * numColumns - number of xl device columns
      - Optional Props: 
       * updateFields (typically an API PUT call followed by GET call to refresh the data, returns boolean) 
  
      - Required Label Fields: accessorKey, header, type, options (only required for dropdown type)
      - Optional Label Fields: required (defaults to false), size, enableColumnOrdering, enableEditing, enableSorting, hideInTable (if column should be displayed in the CRUD table or not)
  
      - Label Types: text, notes, number, date, dropdown, dropdownv2
  
      Tabs are displayed in the order they appear in the list. If there is only one item in the list, then it will be displayed as a 'header' (not clickable, no other visible tabs) and all items in the summaries list will be displayed and editable by default. If within the summaries list
      there is an item with a 'tab' option of a different value than the 'order' in the single item within the tabs list. (Example - there is an item in the summaries list with a 'tab' value of 3. There is only one item in the tabs list. That item will still be displayed within the card.)
  
      const tabs = [
          { accessorKey: 'nameOfTabMatchingInSummaries', header: 'displayTitleOfTab', order: 1, iconPath: ['fas', 'user-friends'] },
      ];
  
      The editable card is largely based off of the CRUD table functionality. An example of a label input is:
      const summaries = [
          { header: 'Text Example', type: 'text', required: true, size: 72 },
          { header: 'Dropdown Example', type: 'dropdown', options: ['Yes', 'No'], required: true, size: 68 },
          { header: 'Dropdown Version 2 Example', type: 'dropdown-v2', options: arrayOfOptions, defaultVal: 0, required: true, Cell: ({cell}) => cell.fieldValues.original.dropdownV2Example != null && <p>{dropdownV2Exampoe.find(o => o.id === cell.fieldValues.dropdownV2Example).title}</p>} ,
          { header: 'Checkbox Example', type: 'checkbox', defaultVal: false, required: true, Cell: ({cell}) => <Checkbox name="checkboxExample" label="Active" checked=(cell.fieldValues.original.checkboxExample} disabled={false} },
          { header: 'Date Example', type: 'date', required: true, size: 72 },
          { header: 'Notes Example', type: 'notes', required: true},
      ]
  
      Included in the summaries array, you need a VALUE that contains the proper value you want displayed. An example is that fields = authorizationInformation. Values will then be gathered by the accessor key in the summaries array:
  
            We want to display the values for an authorization's ID and Client:
            const summaries = [
              { header: 'File #', value: this.props.authorizationInformation.mtAuthorizationId, type: 'text', required: true, disabled: true },
              { header: 'Client', value: this.props.authorizationInformation.client.description, type: 'text', required: true, disabled: true },
            ]
  
      The updateFields props is a FUNCTION for updating the fields via the editing modal. Here is an example of how to write an updateFields function:
      
      const updateAuthorizationInformation = async (authorizationInformation, values) => {
        const id = authorizationInformation.mtAuthorizationId;
        values.mtAuthorizationId = id;
        values.clientId = authorizationInformation.clients.clientId;
        const resp = await props.updateAuthorizationInformation(values);
        if (resp.type === UPDATE_AUTHORIZATION_INFORMATION_FAILURE) {
          return false;
        } else {
        const getResp = await props.getAuthorizationInformation(authorizationInformation.clients.clientId);
        if (getResp.type === GET_AUTHORIZATION_INFORMATION_FAILURE) {
          return false;
        } else {
          return true;
        }
      }
    };
  
  */


const ServicesEditableCard = (props) => {
  const { status, servicesInfo, updateFields, isLoading } = props; // webAccess accessible in props
  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },

  });

 // const listingInfo = (homesaleService?.listings && homesaleService?.listings[0]) ? homesaleService?.listings[0] : null;


  const [editingModalOpen, setEditingModalOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});


  const handleSaveEdits = async (temporaryLiving, taxAssistance, homesale, global, homefindRenter, homefindBuyer, householdGoods) => {
    if (!Object.keys(validationErrors).length) {
      // Send update call to parent to update and refresh values.
      if (updateFields) {
        const resp = await updateFields(temporaryLiving, taxAssistance, homesale, global, homefindRenter, homefindBuyer, householdGoods);
        if (!resp) {
          showToast('Failed to update card data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        } else {
          setEditingModalOpen(false);
        }
      }
      setEditingModalOpen(false); //required to exit editing mode and close modal
    }
  };

  const handleCancelEdits = () => {
    setValidationErrors({});
    setEditingModalOpen(false); //required to exit editing mode and close modal
  };

  const tempLivingActive = servicesInfo.temporaryLiving && (servicesInfo.temporaryLiving === 'Completed' || servicesInfo.temporaryLiving === 'Initiated');
  const destinationServicesActive = servicesInfo.homefindRenter && (servicesInfo.homefindRenter === 'Completed' || servicesInfo.homefindRenter === 'Initiated');
  const candidateServicesActive = servicesInfo.homefindBuyer && (servicesInfo.homefindBuyer === 'Completed' || servicesInfo.homefindBuyer === 'Initiated');
  const householdGoodsActive = servicesInfo.householdGoods && (servicesInfo.householdGoods === 'Completed' || servicesInfo.householdGoods === 'Initiated');
  const globalActive = servicesInfo.global && (servicesInfo.global === 'Completed' || servicesInfo.global === 'Initiated');
  const homesaleActive = servicesInfo.homesale && (servicesInfo.homesale === 'Completed' || servicesInfo.homesale === 'Initiated');

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card}>
        <Box>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            <Grid container columns="15">
              <Grid item md="14">
              <Grid container spacing={{ xs: 1, md: 1 }} columns="14">
                <Box sx={{ width: '100%', padding: '5px' }}></Box>
              <Grid item md="2" >
                {status &&
                  <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '5px'}}>
                      {status === 'Closed' && <FontAwesomeIcon size="lg" icon={['fas', 'check-circle']} color={COLOR_SECONDARY.main} />}
                      {status === 'On Hold' && <PauseCircle fontSize="small" color="secondary"/>}
                      {status === 'On Assignment' && <FontAwesomeIcon size="lg" icon={['fas', 'stroopwafel']} color={COLOR_SECONDARY.main} />}
                      {status !== 'On Assignment' && status !== 'Closed' && status !== 'On Hold' && <FontAwesomeIcon size="lg" icon={['fas', 'circle-arrow-right']} color={COLOR_SECONDARY.main} />}
                      <Box sx={{ paddingLeft: '10px'}}>
                        <Typography variant="subtitle2">
                          File Timeline
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ width: '125px' }}>
                        <Typography variant="body2" sx={{backgroundColor: '#edf7ed', padding: '5px', borderRadius: '4px'}}>
                          {status}
                        </Typography>
                    </Box>
                  </>
                }
              </Grid>
              <Grid item md="12" >
                <Box> {/*sx={{ maxWidth: '330px', overflowX: 'scroll'}}*/}
                  <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                    <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                            Placeholder Label
                          </Typography>
                        </div>
                      </Box>
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                            <div>&mdash;</div>
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                    <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                            Placeholder Label
                          </Typography>
                        </div>
                      </Box>
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                            <div>&mdash;</div>
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                    <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                            Placeholder Label
                          </Typography>
                        </div>
                      </Box>
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                            <div>&mdash;</div>
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                    <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                            Placeholder Label
                          </Typography>
                        </div>
                      </Box>
                      <Box>
                        <div className={classes.mb2}>
                          <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                            <div>&mdash;</div>
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                
              </Grid>
              
              
              
              {/* <Box sx={{ width: '100%' }}>
                  <hr />
              </Box> */}
              {/* <Grid item md="2" >
                <div className={classes.mb2}>
                  <Typography variant="subtitle2" sx={{ 'a': { color: (servicesInfo.householdGoods && (servicesInfo.householdGoods === 'Completed' || servicesInfo.householdGoods === 'Initiated')) ? '#36939B' : '#868686' } }}>
                    Web Access
                  </Typography>
                </div>
              </Grid>
              <Grid item md="2">
                {webAccess &&
                  <>
                    <Alert
                      iconMapping={{
                        success: <CheckCircleIcon fontSize="small" />,
                      }}
                      sx={{
                        fontSize: '0.875rem',
                        maxHeight: 35,
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '70px',
                        marginLeft: '-70px',
                      }}
                    >
                      <Typography variant="body2">
                        Web Access Granted
                      </Typography>
                    </Alert>
                  </>
                }
                {!webAccess &&
                  <>
                    <Alert
                      severity="error"
                      iconMapping={{
                        error: <CancelIcon fontSize="small" sx={{ marginRight: '-5px' }} />,
                      }}
                      sx={{
                        fontSize: '0.875rem',
                        maxHeight: 35,
                        padding: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '70px',
                        marginLeft: '-70px',
                      }}
                    >
                      <Typography variant="body2">
                        No Web Access
                      </Typography>
                    </Alert>
                  </>
                }
              </Grid>
              <Grid item md="8" >
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                      Granted Date
                    </Typography>
                  </div>
                </Box>
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                      <div>&mdash;</div>
                    </Typography>
                  </div>
                </Box>
              </Grid> */}
              {(servicesInfo.homesale && (servicesInfo.homesale === 'Completed' || servicesInfo.homesale === 'Initiated' || servicesInfo.homesale === 'Cancelled' || servicesInfo.homesale === 'On Hold')) &&
                  <>
                    <Box sx={{ width: '100%' }}>
                        <hr />
                    </Box>
                    <Grid item md="2" >
                      {(servicesInfo.homesale && (servicesInfo.homesale === 'Completed' || servicesInfo.homesale === 'Initiated' || servicesInfo.homesale === 'Cancelled' || servicesInfo.homesale === 'On Hold')) &&
                        <>
                          <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '5px'}}>
                            {servicesInfo.homesale === 'Completed' && <FontAwesomeIcon size="lg" icon={['fas', 'check-circle']} color={COLOR_SECONDARY.main} />}
                            {servicesInfo.homesale === 'Cancelled' && <FontAwesomeIcon size="lg" icon={['fas', 'circle-xmark']} color={COLOR_SECONDARY.main} />}
                            {servicesInfo.homesale === 'On Hold' && <PauseCircle fontSize="small" color="secondary"/>}
                            {servicesInfo.homesale !== 'Cancelled' && servicesInfo.homesale !== 'Completed' && servicesInfo.homesale !== 'On Hold' && <FontAwesomeIcon size="lg" icon={['fas', 'circle-arrow-right']} color={COLOR_SECONDARY.main} />}
                            <Box sx={{ paddingLeft: '10px'}}>
                              <Typography variant="subtitle2" sx={{ 'a': { color: homesaleActive ? '#36939B' : '#868686' } }}>
                                <Link to={homesaleActive ? `/authorizations/${servicesInfo.authorizationId}/homesale` : null}> Homesale </Link>
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ width: '125px' }}>
                              <Typography variant="body2" sx={{backgroundColor: '#edf7ed', padding: '5px', borderRadius: '4px'}}>
                                {servicesInfo && servicesInfo.homesale}
                              </Typography>
                          </Box>
                        </>
                      }
                    </Grid>
                    <Grid item md="12" >
                      <Box> {/*sx={{ maxWidth: '330px', overflowX: 'scroll'}}*/}
                        <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Listing Date
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Close Date
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Placeholder Label
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Placeholder Label
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      
                    </Grid>
                  </>
                }
              
              {(servicesInfo.global && (servicesInfo.global === 'Completed' || servicesInfo.global === 'Initiated' || servicesInfo.global === 'Cancelled' || servicesInfo.global === 'On Hold')) &&
                  <>
                    <Box sx={{ width: '100%' }}>
                        <hr />
                    </Box>
                    <Grid item md="2" >
                      {(servicesInfo.global && (servicesInfo.global === 'Completed' || servicesInfo.global === 'Initiated' || servicesInfo.global === 'Cancelled' || servicesInfo.global === 'On Hold')) &&
                        <>
                          <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '5px'}}>
                            {servicesInfo.global === 'Completed' && <FontAwesomeIcon size="lg" icon={['fas', 'check-circle']} color={COLOR_SECONDARY.main} />}
                            {servicesInfo.global === 'Cancelled' && <FontAwesomeIcon size="lg" icon={['fas', 'circle-xmark']} color={COLOR_SECONDARY.main} />}
                            {servicesInfo.global === 'On Hold' && <PauseCircle fontSize="small" color="secondary"/>}
                            {servicesInfo.global !== 'Cancelled' && servicesInfo.global !== 'Completed' && servicesInfo.global !== 'On Hold' && <FontAwesomeIcon size="lg" icon={['fas', 'circle-arrow-right']} color={COLOR_SECONDARY.main} />}
                            <Box sx={{ paddingLeft: '10px'}}>
                              <Typography variant="subtitle2" sx={{ 'a': { color: globalActive ? '#36939B' : '#868686' } }}>
                                <Link to={globalActive ? `/authorizations/${servicesInfo.authorizationId}/global` : null} > Global </Link>
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ width: '125px' }}>
                              <Typography variant="body2" sx={{backgroundColor: '#edf7ed', padding: '5px', borderRadius: '4px'}}>
                                {servicesInfo && servicesInfo.global}
                              </Typography>
                          </Box>
                        </>
                      }
                    </Grid>
                    <Grid item md="12" >
                      <Box > {/*sx={{ maxWidth: '330px', overflowX: 'scroll'}}*/}
                        <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Estimated Start
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Estimated End
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Actual Start
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Actual End
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      
                    </Grid>
                  </>
                }
              
              {(servicesInfo.homefindRenter && (servicesInfo.homefindRenter === 'Completed' || servicesInfo.homefindRenter === 'Initiated' || servicesInfo.homefindRenter === 'Cancelled' || servicesInfo.homefindRenter === 'On Hold')) &&
                  <>
                    <Box sx={{ width: '100%' }}>
                        <hr />
                    </Box>
                    <Grid item md="2" >
                      {(servicesInfo.homefindRenter && (servicesInfo.homefindRenter === 'Completed' || servicesInfo.homefindRenter === 'Initiated' || servicesInfo.homefindRenter === 'Cancelled' || servicesInfo.homefindRenter === 'On Hold')) &&
                        <>
                          <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '5px'}}>
                            {servicesInfo.homefindRenter === 'Completed' && <FontAwesomeIcon size="lg" icon={['fas', 'check-circle']} color={COLOR_SECONDARY.main} />}
                            {servicesInfo.homefindRenter === 'Cancelled' && <FontAwesomeIcon size="lg" icon={['fas', 'circle-xmark']} color={COLOR_SECONDARY.main} />}
                            {servicesInfo.homefindRenter === 'On Hold' && <PauseCircle fontSize="small" color="secondary"/>}
                            {servicesInfo.homefindRenter !== 'Cancelled' && servicesInfo.homefindRenter !== 'Completed' && servicesInfo.homefindRenter !== 'On Hold' && <FontAwesomeIcon size="lg" icon={['fas', 'circle-arrow-right']} color={COLOR_SECONDARY.main} />}
                            <Box sx={{ paddingLeft: '10px'}}>
                              <Typography variant="subtitle2" sx={{ 'a': { color: destinationServicesActive ? '#36939B' : '#868686' } }}>
                                <Link to={destinationServicesActive ? `/authorizations/${servicesInfo.authorizationId}/destinationServices` : null} >Destination Services </Link>
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ width: '125px' }}>
                              <Typography variant="body2" sx={{backgroundColor: '#edf7ed', padding: '5px', borderRadius: '4px'}}>
                                {servicesInfo && servicesInfo.homefindRenter}
                              </Typography>
                          </Box>
                        </>
                      }
                    </Grid>
                    <Grid item md="12" >
                      <Box> {/*sx={{ maxWidth: '330px', overflowX: 'scroll'}}*/}
                        <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Service Start Date
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      
                    </Grid>
                  </>
                }
              
              {(servicesInfo.homefindBuyer && (servicesInfo.homefindBuyer === 'Completed' || servicesInfo.homefindBuyer === 'Initiated' || servicesInfo.homefindBuyer === 'Cancelled' || servicesInfo.homefindBuyer === 'On Hold')) &&
                  <>
                    <Box sx={{ width: '100%' }}>
                        <hr />
                    </Box>
                    <Grid item md="2" >
                      {(servicesInfo.homefindBuyer && (servicesInfo.homefindBuyer === 'Completed' || servicesInfo.homefindBuyer === 'Initiated' || servicesInfo.homefindBuyer === 'Cancelled' || servicesInfo.homefindBuyer === 'On Hold')) &&
                        <>
                          <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '5px'}}>
                            {servicesInfo.homefindBuyer === 'Completed' && <FontAwesomeIcon size="lg" icon={['fas', 'check-circle']} color={COLOR_SECONDARY.main} />}
                            {servicesInfo.homefindBuyer === 'Cancelled' && <FontAwesomeIcon size="lg" icon={['fas', 'circle-xmark']} color={COLOR_SECONDARY.main} />}
                            {servicesInfo.homefindBuyer === 'On Hold' && <PauseCircle fontSize="small" color="secondary"/>}
                            {servicesInfo.homefindBuyer !== 'Cancelled' && servicesInfo.homefindBuyer !== 'Completed' && servicesInfo.homefindBuyer !== 'On Hold' && <FontAwesomeIcon size="lg" icon={['fas', 'circle-arrow-right']} color={COLOR_SECONDARY.main} />}
                            <Box sx={{ paddingLeft: '10px'}}>
                              <Typography variant="subtitle2" sx={{ 'a': { color: candidateServicesActive ? '#36939B' : '#868686' } }}>
                                <Link to={candidateServicesActive ? `/authorizations/${servicesInfo.authorizationId}/candidateServices` : null}>Candidate Services</Link>
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ width: '125px' }}>
                              <Typography variant="body2" sx={{backgroundColor: '#edf7ed', padding: '5px', borderRadius: '4px'}}>
                                {servicesInfo && servicesInfo.homefindBuyer}
                              </Typography>
                          </Box>
                        </>
                      }
                    </Grid>
                    <Grid item md="12" >
                      <Box> {/*sx={{ maxWidth: '330px', overflowX: 'scroll'}}*/}
                        <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Service Start Date
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      
                    </Grid>
                  </>
                }
              
              {(servicesInfo.temporaryLiving && (servicesInfo.temporaryLiving === 'Completed' || servicesInfo.temporaryLiving === 'Initiated' || servicesInfo.temporaryLiving === 'Cancelled' || servicesInfo.temporaryLiving === 'On Hold')) &&
                  <>
                    <Box sx={{ width: '100%' }}>
                        <hr />
                    </Box>
                    <Grid item md="2" >
                      {(servicesInfo.temporaryLiving && (servicesInfo.temporaryLiving === 'Completed' || servicesInfo.temporaryLiving === 'Initiated' || servicesInfo.temporaryLiving === 'Cancelled' || servicesInfo.temporaryLiving === 'On Hold')) &&
                        <>
                          <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '5px'}}>
                            {servicesInfo.temporaryLiving === 'Completed' && <FontAwesomeIcon size="lg" icon={['fas', 'check-circle']} color={COLOR_SECONDARY.main} />}
                            {servicesInfo.temporaryLiving === 'Cancelled' && <FontAwesomeIcon size="lg" icon={['fas', 'circle-xmark']} color={COLOR_SECONDARY.main} />}
                            {servicesInfo.temporaryLiving === 'On Hold' && <PauseCircle fontSize="small" color="secondary"/>}
                            {servicesInfo.temporaryLiving !== 'Cancelled' && servicesInfo.temporaryLiving !== 'Completed' && servicesInfo.temporaryLiving !== 'On Hold' && <FontAwesomeIcon size="lg" icon={['fas', 'circle-arrow-right']} color={COLOR_SECONDARY.main} />}
                            <Box sx={{ paddingLeft: '10px'}}>
                              <Typography variant="subtitle2" sx={{ 'a': { color: tempLivingActive ? '#36939B' : '#868686' } }}>
                                <Link to={tempLivingActive ? `/authorizations/${servicesInfo.authorizationId}/tempLiving` : null}>Temporary Living</Link>
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ width: '125px' }}>
                              <Typography variant="body2" sx={{backgroundColor: '#edf7ed', padding: '5px', borderRadius: '4px'}}>
                                {servicesInfo && servicesInfo.temporaryLiving}
                              </Typography>
                          </Box>
                        </>
                      }
                    </Grid>
                    <Grid item md="12" >
                      <Box> {/*sx={{ maxWidth: '330px', overflowX: 'scroll'}}*/}
                        <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Check In (EST)
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Check Out (EST)
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Placeholder Label
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Placeholder Label
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      
                    </Grid>
                  </>
                }
              
              {(servicesInfo.householdGoods && (servicesInfo.householdGoods === 'Completed' || servicesInfo.householdGoods === 'Initiated' || servicesInfo.householdGoods === 'Cancelled' || servicesInfo.householdGoods === 'On Hold')) &&
                  <>
                    <Box sx={{ width: '100%' }}>
                        <hr />
                    </Box>
                    <Grid item md="2" >
                      {(servicesInfo.householdGoods && (servicesInfo.householdGoods === 'Completed' || servicesInfo.householdGoods === 'Initiated' || servicesInfo.householdGoods === 'Cancelled' || servicesInfo.householdGoods === 'On Hold')) &&
                        <>
                          <Box sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '5px'}}>
                            {servicesInfo.householdGoods === 'Completed' && <FontAwesomeIcon size="lg" icon={['fas', 'check-circle']} color={COLOR_SECONDARY.main} />}
                            {servicesInfo.householdGoods === 'Cancelled' && <FontAwesomeIcon size="lg" icon={['fas', 'circle-xmark']} color={COLOR_SECONDARY.main} />}
                            {servicesInfo.householdGoods === 'On Hold' && <PauseCircle fontSize="small" color="secondary"/>}
                            {servicesInfo.householdGoods !== 'Cancelled' && servicesInfo.householdGoods !== 'Completed' && servicesInfo.householdGoods !== 'On Hold' && <FontAwesomeIcon size="lg" icon={['fas', 'circle-arrow-right']} color={COLOR_SECONDARY.main} />}
                            <Box sx={{ paddingLeft: '10px'}}>
                              <Typography variant="subtitle2" sx={{ 'a': { color: (servicesInfo.householdGoods && (servicesInfo.householdGoods === 'Completed' || servicesInfo.householdGoods === 'Initiated' || servicesInfo.householdGoods === 'On Hold')) ? '#36939B' : '#868686' } }}>
                                <Link to={householdGoodsActive ? `/authorizations/${servicesInfo.authorizationId}/hhg` : null} > Household Goods </Link>
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ width: '125px' }}>
                              <Typography variant="body2" sx={{backgroundColor: '#edf7ed', padding: '5px', borderRadius: '4px'}}>
                                {servicesInfo && servicesInfo.householdGoods}
                              </Typography>
                          </Box>
                        </>
                      }
                    </Grid>
                    <Grid item md="12" >
                      <Box> {/*sx={{ maxWidth: '330px', overflowX: 'scroll'}}*/}
                        <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Pack/Load Date
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Delivery Date
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Placeholder Label
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                          <Box sx={{minWidth: '165px', paddingTop: '5px'}}>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                                  Placeholder Label
                                </Typography>
                              </div>
                            </Box>
                            <Box>
                              <div className={classes.mb2}>
                                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                                  <div>&mdash;</div>
                                </Typography>
                              </div>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      
                    </Grid>
                  </>
                }
              
            </Grid>
              </Grid>
              <Grid item md="1">
                <div className="row justify-space-between align-center" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                    <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                  </Fab>
                </div>
              </Grid>
            </Grid>
            
          </CardContent>
        </Box>
      </Card>
      {editingModalOpen &&

        <EditCardModal
          servicesInfo={servicesInfo}
          classes={classes}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={handleCancelEdits}
          onSubmit={handleSaveEdits}
        />
      }

    </ThemeProvider>
  );
};
ServicesEditableCard.propTypes = {
  updateFields: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};
export default connect(
  null,
  { showToast },
)(ServicesEditableCard);


export const EditCardModal = ({ open, classes, servicesInfo, onClose, onSubmit, isLoading }) => {

  const [temporaryLiving, setTemporaryLiving] = useState(servicesInfo.temporaryLiving ?? '');
  const [taxAssistance, setTaxAssistance] = useState(servicesInfo.taxAssistance ?? '');
  const [homesale, setHomesale] = useState(servicesInfo.homesale ?? '');
  const [global, setGlobal] = useState(servicesInfo.global ?? '');
  const [homefindRenter, setHomefindRenter] = useState(servicesInfo.homefindRenter ?? '');
  const [homefindBuyer, setHomefindBuyer] = useState(servicesInfo.homefindBuyer ?? '');
  const [householdGoods, setHouseholdGoods] = useState(servicesInfo.householdGoods ?? '');

  const handleSubmit = (e) => {
    //put your validation logic here
    // need to add 'modified on / by' logic here
    e.preventDefault();
    onSubmit(temporaryLiving, taxAssistance, homesale, global, homefindRenter, homefindBuyer, householdGoods);
  };

  const handleCancel = (e) => {
    //put your validation logic here
    e.preventDefault();
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={handleSubmit} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            <Typography variant="h5" >
              Services
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem',
            }}
          >
            <Grid container spacing={{ xs: 2, md: 3 }} sx={{ padding: '30px', paddingTop: '0px' }}>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'taxAssistance'}
                    name={'taxAssistance'}
                    label={'Tax Assistance'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={taxAssistance && (taxAssistance === 'Completed' || taxAssistance === 'Initiated')}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setTaxAssistance(e.target.checked ? 'Initiated' : 'Not Initiated')
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'homesale'}
                    name={'homesale'}
                    label={'Homesale'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={homesale && (homesale === 'Completed' || homesale === 'Initiated')}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setHomesale(e.target.checked ? 'Initiated' : 'Not Initiated')
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'global'}
                    name={'global'}
                    label={'Global'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={global && (global === 'Completed' || global === 'Initiated')}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setGlobal(e.target.checked ? 'Initiated' : 'Not Initiated')
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'homefindRenter'}
                    name={'homefindRenter'}
                    label={'Destination Services'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={homefindRenter && (homefindRenter === 'Completed' || homefindRenter === 'Initiated')}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setHomefindRenter(e.target.checked ? 'Initiated' : 'Not Initiated')
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'homefindBuyer'}
                    name={'homefindBuyer'}
                    label={'Candidate Services'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={homefindBuyer && (homefindBuyer === 'Completed' || homefindBuyer === 'Initiated')}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setHomefindBuyer(e.target.checked ? 'Initiated' : 'Not Initiated')
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'temporaryLiving'}
                    name={'temporaryLiving'}
                    label={'Temporary Living'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={temporaryLiving && (temporaryLiving === 'Completed' || temporaryLiving === 'Initiated')}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setTemporaryLiving(e.target.checked ? 'Initiated' : 'Not Initiated')
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item >
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'householdGoods'}
                    name={'householdGoods'}
                    label={'Household Goods'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    checked={householdGoods && (householdGoods === 'Completed' || householdGoods === 'Initiated')}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={(e) =>
                      setHouseholdGoods(e.target.checked ? 'Initiated' : 'Not Initiated')
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Stack>
        </div>
        {isLoading &&
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        }
        {!isLoading &&
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button color="primary" type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        }
      </form>
    </Dialog>
  );
};

/* eslint-disable no-case-declarations */
import {
  DELETE_GLOBAL_DESTINATION_SERVICES_SUCCESS,
  DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS,
  DELETE_GLOBAL_LANGUAGE_TRAINING_SUCCESS,
  GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS,
  GET_ASSIGNMENT_EXTENSIONS_SUCCESS,
  GET_ASSIGNMENT_TRIPS_SUCCESS,
  GET_GLOBAL_DESTINATION_SERVICES_SUCCESS,
  GET_GLOBAL_INFO_SUCCESS,
  GET_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS,
  GET_GLOBAL_LANGUAGE_TRAINING_SUCCESS,
  POST_GLOBAL_DESTINATION_SERVICES_SUCCESS,
  POST_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS,
  POST_GLOBAL_INFO_SUCCESS,
  POST_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS,
  POST_GLOBAL_LANGUAGE_TRAINING_SUCCESS,
  UPDATE_GLOBAL_DESTINATION_SERVICES_SUCCESS,
  UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS,
  UPDATE_GLOBAL_INFO_SUCCESS,
  UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS,
  UPDATE_GLOBAL_LANGUAGE_TRAINING_SUCCESS
} from '../types/globalTypes';
import {
  DELETE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
  DELETE_TEMPORARY_LIVING_ASSIGNMENT_REQUEST,
  DELETE_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS,
  GET_AUTH_PETS_FAILURE,
  GET_AUTH_PETS_REQUEST,
  GET_AUTH_PETS_SUCCESS,
  GET_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
  GET_TEMPORARY_LIVING_ASSIGNMENT_REQUEST,
  GET_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS,
  POST_AUTH_PETS_FAILURE,
  POST_AUTH_PETS_REQUEST,
  POST_AUTH_PETS_SUCCESS,
  POST_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
  POST_TEMPORARY_LIVING_ASSIGNMENT_REQUEST,
  POST_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS,
  UPDATE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
  UPDATE_TEMPORARY_LIVING_ASSIGNMENT_REQUEST,
  UPDATE_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS
} from '../types/temporaryLivingTypes';
import {
  GET_ACTIVITY_LOG_REQUEST,
  GET_ALL_AUTH_SERVICES_FAILURE,
  GET_ALL_AUTH_SERVICES_REQUEST,
  GET_ALL_AUTH_SERVICES_SUCCESS,
  GET_AUTHORIZATIONS_FAILURE,
  GET_AUTHORIZATIONS_REQUEST,
  GET_AUTHORIZATIONS_SUCCESS,
  GET_AUTH_CLIENT_POLICIES_REQUEST,
  GET_AUTH_CLIENT_POLICIES_SUCCESS,
  GET_AUTH_DATE_SUMMARY_REQUEST,
  GET_AUTH_DATE_SUMMARY_SUCCESS,
  GET_AUTH_LIST_CLIENT_POLICIES_BY_CLIENT_SUCCESS,
  GET_AUTH_LIST_CLIENT_POLICIES_FAILURE,
  GET_AUTH_LIST_CLIENT_POLICIES_REQUEST,
  GET_AUTH_LIST_CLIENT_POLICIES_SUCCESS,
  GET_AUTH_SERVICES_REQUEST,
  GET_AUTH_SERVICES_SUCCESS,
  GET_AUTH_SUMMARY_SUCCESS,
  GET_AUTH_TEAM_SUCCESS,
  GET_COMMUNICATION_INFO_REQUEST,
  GET_COMMUNICATION_INFO_SUCCESS,
  GET_DEPARTURE_ADDRESS_SUCCESS,
  GET_DESTINATION_ADDRESS_SUCCESS,
  GET_EMPLOYEE_INFO_REQUEST,
  GET_EMPLOYEE_INFO_SUCCESS,
  GET_FAMILY_PET_REQUEST,
  GET_FAMILY_PET_SUCCESS,
  GET_FAMILY_REQUEST,
  GET_FAMILY_SUCCESS,
  GET_FINANCE_AND_TAX_REQUEST,
  GET_FINANCE_AND_TAX_SUCCESS,
  GET_SO_INFO_REQUEST,
  GET_SO_INFO_SUCCESS,
  GET_USER_SETTINGS_REQUEST,
  GET_USER_SETTINGS_SUCCESS,
  POST_AUTH_CLIENT_POLICIES_SUCCESS,
  POST_AUTH_DATE_SUMMARY_SUCCESS,
  POST_AUTH_SERVICES_SUCCESS,
  POST_AUTH_TEAM_SUCCESS,
  POST_COMMUNICATION_INFO_SUCCESS,
  POST_DEPARTURE_ADDRESS_SUCCESS,
  POST_DESTINATION_ADDRESS_SUCCESS,
  POST_EMPLOYEE_INFO_SUCCESS,
  POST_FAMILY_PET_SUCCESS,
  POST_FAMILY_SUCCESS,
  POST_FINANCE_AND_TAX_SUCCESS,
  POST_SO_INFO_SUCCESS,
  POST_USER_SETTINGS_SUCCESS,
  SET_AUTH_ID,
  SET_LOADING_COMPLETE,
  UPDATE_AUTHORIZATIONS_SUCCESS,
  UPDATE_AUTH_CLIENT_POLICIES_SUCCESS,
  UPDATE_AUTH_DATE_SUMMARY_SUCCESS,
  UPDATE_AUTH_SERVICES_SUCCESS,
  UPDATE_AUTH_TEAM_SUCCESS,
  UPDATE_COMMUNICATION_INFO_SUCCESS,
  UPDATE_DEPARTURE_ADDRESS_SUCCESS,
  UPDATE_DESTINATION_ADDRESS_SUCCESS,
  UPDATE_EMPLOYEE_INFO_SUCCESS,
  UPDATE_FAMILY_DATA,
  UPDATE_FAMILY_PET_DATA,
  UPDATE_FAMILY_PET_SUCCESS,
  UPDATE_FAMILY_SUCCESS,
  UPDATE_FINANCE_AND_TAX_SUCCESS,
  UPDATE_SO_INFO_SUCCESS,
  UPDATE_USER_SETTINGS_SUCCESS
} from '../types/authorizationTypes';
import {
  GET_ALL_BROKER_AGENTS_FAILURE,
  GET_ALL_BROKER_AGENTS_REQUEST,
  GET_ALL_BROKER_AGENTS_SUCCESS,
  GET_EXPENSE_CODES_SUCCESS,
  GET_INSPECTORS_FAILURE,
  GET_INSPECTORS_REQUEST,
  GET_INSPECTORS_SUCCESS,
  GET_TAX_POLICIES_FAILURE,
  GET_TAX_POLICIES_REQUEST,
  GET_TAX_POLICIES_SUCCESS,
  GET_TITLE_REP_FAILURE,
  GET_TITLE_REP_REQUEST,
  GET_TITLE_REP_SUCCESS,
  GET_VENDORS_SUCCESS,
  GET_VENDOR_CONTACT_SUCCESS,
  GET_VENDOR_DOCUMENTS_SUCCESS,
  UPDATE_VENDORS_SUCCESS,
  GET_VENDOR_NOTES_SUCCESS,
  GET_VENDOR_BANKING_INFO_BY_VENDOR_ID_SUCCESS,
} from '../types/dataManagementTypes';
import {
  GET_ALL_CANDIDATE_TRIPS_SUCCESS,
  GET_CANDIDATE_SERVICES_SUCCESS,
  GET_COST_ESTIMATES_SUCCESS,
  GET_INTERN_SERVICES_SUCCESS,
  POST_CANDIDATE_SERVICES_SUCCESS,
  POST_CANDIDATE_TRIP_SUCCESS,
  POST_COST_ESTIMATES_SUCCESS,
  POST_INTERN_SERVICES_SUCCESS,
  UPDATE_CANDIDATE_SERVICES_SUCCESS,
  UPDATE_CANDIDATE_TRIP_SUCCESS,
  UPDATE_COST_ESTIMATES_SUCCESS,
  UPDATE_INTERN_SERVICES_SUCCESS
} from '../types/canidateTripTypes';
import {
  GET_ALL_HOMESALE_RESALE_OFFER_SUCCESS,
  GET_APPRAISALS_SUCCESS,
  GET_CREDIT_SUCCESS,
  GET_EQUITY_SUCCESS,
  GET_HOMESALE_INFO_SUCCESS,
  GET_INSPECTIONS_SUCCESS,
  GET_LISTINGS_SUCCESS,
  GET_TAX_SUCCESS,
  POST_HOMESALE_INFO_SUCCESS,
  POST_HOMESALE_RESALE_OFFER_SUCCESS,
  POST_INSPECTIONS_SUCCESS,
  UPDATE_APPRAISALS_SUCCESS,
  UPDATE_EQUITY_SUCCESS,
  UPDATE_HOMESALE_INFO_SUCCESS,
  UPDATE_HOMESALE_RESALE_OFFER_SUCCESS,
  UPDATE_INSPECTIONS_STATE,
  UPDATE_INSPECTIONS_SUCCESS,
  UPDATE_LISTINGS_SUCCESS
} from '../types/homesaleTypes';
import {
  GET_AUTH_DESTINATION_SERVICES_SUCCESS,
  GET_AUTH_HOUSE_HUNTING_LIST_SUCCESS,
  GET_AUTH_OFFER_HISTORY_LIST_SUCCESS,
  POST_AUTH_DESTINATION_SERVICES_SUCCESS,
  POST_AUTH_HOUSE_HUNTING_LIST_SUCCESS,
  POST_AUTH_OFFER_HISTORY_LIST_SUCCESS,
  UPDATE_AUTH_DESTINATION_SERVICES_SUCCESS,
  UPDATE_AUTH_HOUSE_HUNTING_LIST_SUCCESS,
  UPDATE_AUTH_OFFER_HISTORY_LIST_SUCCESS
} from '../types/destinationServiceTypes';
import {
  GET_AUTH_TAX_ASSISTANCE_SUCCESS,
  GET_TAX_POLICIES_BY_CLIENT_SUCCESS,
  POST_AUTH_TAX_ASSISTANCE_SUCCESS,
  UPDATE_AUTH_TAX_ASSISTANCE_SUCCESS
} from '../types/taxAssistanceTypes';
import {
  GET_HHG_ESTIMATES_SUCCESS,
  GET_HHG_ITEMS_SUCCESS,
  GET_HHG_MOVING_DATE_SUCCESS,
  GET_HHG_SEGMENTS_SUCCESS,
  GET_HHG_VEHICLES_SUCCESS,
  POST_HHG_SEGMENT_SUCCESS,
  POST_HHG_VEHICLES_SUCCESS,
  UPDATE_HHG_ESTIMATE_SUCCESS,
  UPDATE_HHG_SEGMENT_SUCCESS,
  UPDATE_HHG_VEHICLES_SUCCESS
} from '../types/houseHoldGoodsTypes';
import handleAuthorizationReducer from './authorizations.reducer';
import handleCanidateTripsReducer from './canidateTrip.reducer';
import handleDataManagementReducer from './dataManagement.reducer';
import handleDestinationServiceReducer from './destinationService.reducer';
import handleGlobalReducer from './global.reducer';
import handleHomesaleReducer from './homesale.reducer';
import handleHouseHoldGoodsReducer from './houseHoldGoods.reducer';
import handleTaxAssistanceReducer from './taxAssistance.reducer';
import handleTemporaryLivingReducer from './temporaryLiving.reducer';
import produce from 'immer';
import {
  GET_EXPENSE_LINES_BY_AUTH_SUCCESS,
  GET_EXPENSE_LINE_BY_SUMMARY_ID_SUCCESS,
  GET_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS,
  GET_EXPENSE_SUMMARIES_BY_AUTH_SUCCESS,
  POST_EXPENSE_LINE_SUCCESS,
  UPDATE_EXPENSE_LINE_SUCCESS,
  UPDATE_EXPENSE_SUMMARY_SUCCESS
} from 'modules/expenses/types';
import handleAuthExpensesReducer from './authExpenses.reducer';
import {
  DELETE_COMMENT_AND_NOTES_SUCCESS,
  GET_COMMENT_AND_NOTES_SUCCESS,
  POST_COMMENT_AND_NOTES_SUCCESS,
  UPDATE_COMMENT_AND_NOTES_SUCCESS
} from '../types/commentsAndNotesTypes';
import handleCommentsAndNotesReducer from './commentAndNotes.reducer';

const initialState = {
  allServices: null,
  authorizationSummaries: null,
  authorizationSummariesLoading: false,
  allServicesLoading: false,
  authorizationDetails: {},
  clientPolicies: null,
  clientPoliciesLoading: false,
  currentAuthId: null,
  isLoading: false,
  taxPolicies: null,
  taxPoliciesLoading: false,
  brokerAgents: null,
  brokerAgentsLoading: false,
  vendors: null,
  vendorContacts: null,
  vendorDocuments: null,
  vendorBanks: null,
  vendorAuthNotes: null,
  isInspectorsLoading: false,
  titleReps: null,
  isTitleRepIsLoading: false
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_AUTHORIZATIONS_REQUEST:
    case GET_ALL_AUTH_SERVICES_REQUEST:
    case GET_EMPLOYEE_INFO_REQUEST:
    case GET_SO_INFO_REQUEST:
    case GET_AUTH_DATE_SUMMARY_REQUEST:
    case GET_COMMUNICATION_INFO_REQUEST:
    case GET_FINANCE_AND_TAX_REQUEST:
    case GET_AUTH_CLIENT_POLICIES_REQUEST:
    case GET_AUTH_SERVICES_REQUEST:
    case GET_USER_SETTINGS_REQUEST:
    case GET_FAMILY_REQUEST:
    case GET_FAMILY_PET_REQUEST:
    case GET_AUTHORIZATIONS_FAILURE:
    case GET_AUTHORIZATIONS_SUCCESS:
    case GET_ALL_AUTH_SERVICES_FAILURE:
    case GET_ALL_AUTH_SERVICES_SUCCESS:
    case SET_AUTH_ID:
    case GET_USER_SETTINGS_SUCCESS:
    case SET_LOADING_COMPLETE:
    case GET_AUTH_SUMMARY_SUCCESS:
    case GET_EMPLOYEE_INFO_SUCCESS:
    case GET_SO_INFO_SUCCESS:
    case GET_COMMUNICATION_INFO_SUCCESS:
    case GET_FINANCE_AND_TAX_SUCCESS:
    case GET_AUTH_CLIENT_POLICIES_SUCCESS:
    case GET_AUTH_SERVICES_SUCCESS:
    case UPDATE_SO_INFO_SUCCESS:
    case UPDATE_EMPLOYEE_INFO_SUCCESS:
    case UPDATE_COMMUNICATION_INFO_SUCCESS:
    case UPDATE_FINANCE_AND_TAX_SUCCESS:
    case UPDATE_AUTH_CLIENT_POLICIES_SUCCESS:
    case UPDATE_AUTH_SERVICES_SUCCESS:
    case UPDATE_USER_SETTINGS_SUCCESS:
    case POST_EMPLOYEE_INFO_SUCCESS:
    case POST_SO_INFO_SUCCESS:
    case POST_AUTH_SERVICES_SUCCESS:
    case GET_AUTH_DATE_SUMMARY_SUCCESS:
    case UPDATE_AUTH_DATE_SUMMARY_SUCCESS:
    case POST_AUTH_DATE_SUMMARY_SUCCESS:
    case POST_COMMUNICATION_INFO_SUCCESS:
    case POST_FINANCE_AND_TAX_SUCCESS:
    case POST_AUTH_CLIENT_POLICIES_SUCCESS:
    case GET_DEPARTURE_ADDRESS_SUCCESS:
    case GET_DESTINATION_ADDRESS_SUCCESS:
    case UPDATE_DEPARTURE_ADDRESS_SUCCESS:
    case POST_DEPARTURE_ADDRESS_SUCCESS:
    case UPDATE_DESTINATION_ADDRESS_SUCCESS:
    case POST_DESTINATION_ADDRESS_SUCCESS:
    case GET_AUTH_LIST_CLIENT_POLICIES_REQUEST:
    case GET_AUTH_LIST_CLIENT_POLICIES_SUCCESS:
    case GET_AUTH_LIST_CLIENT_POLICIES_FAILURE:
    case GET_AUTH_TEAM_SUCCESS:
    case UPDATE_AUTH_TEAM_SUCCESS:
    case POST_AUTH_TEAM_SUCCESS:
    case POST_USER_SETTINGS_SUCCESS:
    case GET_ACTIVITY_LOG_REQUEST:
    case GET_FAMILY_SUCCESS:
    case UPDATE_FAMILY_SUCCESS:
    case POST_FAMILY_SUCCESS:
    case UPDATE_FAMILY_DATA:
    case GET_FAMILY_PET_SUCCESS:
    case UPDATE_FAMILY_PET_SUCCESS:
    case POST_FAMILY_PET_SUCCESS:
    case UPDATE_FAMILY_PET_DATA:
    case GET_AUTH_LIST_CLIENT_POLICIES_BY_CLIENT_SUCCESS:
    case UPDATE_AUTHORIZATIONS_SUCCESS:
      handleAuthorizationReducer(draft, action);
      break;

    case GET_ALL_BROKER_AGENTS_REQUEST:
    case GET_ALL_BROKER_AGENTS_SUCCESS:
    case GET_ALL_BROKER_AGENTS_FAILURE:
    case GET_TAX_POLICIES_REQUEST:
    case GET_TAX_POLICIES_FAILURE:
    case GET_TAX_POLICIES_SUCCESS:
    case GET_VENDORS_SUCCESS:
    case GET_VENDOR_CONTACT_SUCCESS:
    case GET_VENDOR_DOCUMENTS_SUCCESS:
    case GET_VENDOR_BANKING_INFO_BY_VENDOR_ID_SUCCESS:
    case GET_INSPECTORS_REQUEST:
    case GET_INSPECTORS_SUCCESS:
    case GET_INSPECTORS_FAILURE:
    case GET_EXPENSE_CODES_SUCCESS:
    case GET_TITLE_REP_REQUEST:
    case GET_TITLE_REP_SUCCESS:
    case GET_TITLE_REP_FAILURE:
    case UPDATE_VENDORS_SUCCESS:
    case GET_VENDOR_NOTES_SUCCESS:
      handleDataManagementReducer(draft, action);
      break;

    case UPDATE_AUTH_TAX_ASSISTANCE_SUCCESS:
    case POST_AUTH_TAX_ASSISTANCE_SUCCESS:
    case GET_AUTH_TAX_ASSISTANCE_SUCCESS:
    case GET_TAX_POLICIES_BY_CLIENT_SUCCESS:
      handleTaxAssistanceReducer(draft, action);
      break;

    case UPDATE_AUTH_DESTINATION_SERVICES_SUCCESS:
    case POST_AUTH_DESTINATION_SERVICES_SUCCESS:
    case GET_AUTH_DESTINATION_SERVICES_SUCCESS:
    case GET_AUTH_HOUSE_HUNTING_LIST_SUCCESS:
    case UPDATE_AUTH_HOUSE_HUNTING_LIST_SUCCESS:
    case POST_AUTH_HOUSE_HUNTING_LIST_SUCCESS:
    case GET_AUTH_OFFER_HISTORY_LIST_SUCCESS:
    case UPDATE_AUTH_OFFER_HISTORY_LIST_SUCCESS:
    case POST_AUTH_OFFER_HISTORY_LIST_SUCCESS:
      handleDestinationServiceReducer(draft, action);
      break;

    case GET_AUTH_PETS_SUCCESS:
    case POST_AUTH_PETS_SUCCESS:
    case GET_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS:
    case UPDATE_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS:
    case DELETE_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS:
    case POST_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS:
    case POST_TEMPORARY_LIVING_ASSIGNMENT_REQUEST:
    case UPDATE_TEMPORARY_LIVING_ASSIGNMENT_REQUEST:
    case POST_TEMPORARY_LIVING_ASSIGNMENT_FAILURE:
    case UPDATE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE:
    case GET_AUTH_PETS_FAILURE:
    case GET_TEMPORARY_LIVING_ASSIGNMENT_FAILURE:
    case POST_AUTH_PETS_FAILURE:
    case DELETE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE:
    case GET_TEMPORARY_LIVING_ASSIGNMENT_REQUEST:
    case GET_AUTH_PETS_REQUEST:
    case POST_AUTH_PETS_REQUEST:
    case DELETE_TEMPORARY_LIVING_ASSIGNMENT_REQUEST:
      handleTemporaryLivingReducer(draft, action);
      break;

    case GET_HHG_SEGMENTS_SUCCESS:
    case UPDATE_HHG_SEGMENT_SUCCESS:
    case POST_HHG_SEGMENT_SUCCESS:
    case GET_HHG_MOVING_DATE_SUCCESS:
    case GET_HHG_ITEMS_SUCCESS:
    case GET_HHG_ESTIMATES_SUCCESS:
    case UPDATE_HHG_ESTIMATE_SUCCESS:
    case GET_HHG_VEHICLES_SUCCESS:
    case UPDATE_HHG_VEHICLES_SUCCESS:
    case POST_HHG_VEHICLES_SUCCESS:
      handleHouseHoldGoodsReducer(draft, action);
      break;

    case GET_GLOBAL_INFO_SUCCESS:
    case UPDATE_GLOBAL_INFO_SUCCESS:
    case POST_GLOBAL_INFO_SUCCESS:
    case GET_ASSIGNMENT_TRIPS_SUCCESS:
    case GET_ASSIGNMENT_EXTENSIONS_SUCCESS:
    case GET_GLOBAL_DESTINATION_SERVICES_SUCCESS:
    case GET_GLOBAL_LANGUAGE_TRAINING_SUCCESS:
    case GET_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS:
    case POST_GLOBAL_DESTINATION_SERVICES_SUCCESS:
    case POST_GLOBAL_LANGUAGE_TRAINING_SUCCESS:
    case POST_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS:
    case UPDATE_GLOBAL_DESTINATION_SERVICES_SUCCESS:
    case UPDATE_GLOBAL_LANGUAGE_TRAINING_SUCCESS:
    case UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS:
    case DELETE_GLOBAL_DESTINATION_SERVICES_SUCCESS:
    case DELETE_GLOBAL_LANGUAGE_TRAINING_SUCCESS:
    case DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS:
    case GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS:
    case UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS:
    case POST_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS:
      handleGlobalReducer(draft, action);
      break;

    case GET_HOMESALE_INFO_SUCCESS:
    case UPDATE_HOMESALE_INFO_SUCCESS:
    case POST_HOMESALE_INFO_SUCCESS:
    case GET_APPRAISALS_SUCCESS:
    case UPDATE_APPRAISALS_SUCCESS:
    case GET_INSPECTIONS_SUCCESS:
    case UPDATE_INSPECTIONS_SUCCESS:
    case POST_INSPECTIONS_SUCCESS:
    case UPDATE_INSPECTIONS_STATE:
    case GET_LISTINGS_SUCCESS:
    case UPDATE_LISTINGS_SUCCESS:
    case GET_ALL_HOMESALE_RESALE_OFFER_SUCCESS:
    case UPDATE_HOMESALE_RESALE_OFFER_SUCCESS:
    case POST_HOMESALE_RESALE_OFFER_SUCCESS:
    case GET_EQUITY_SUCCESS:
    case UPDATE_EQUITY_SUCCESS:
    case GET_TAX_SUCCESS:
    case GET_CREDIT_SUCCESS:
      handleHomesaleReducer(draft, action);
      break;

    case GET_CANDIDATE_SERVICES_SUCCESS:
    case UPDATE_CANDIDATE_SERVICES_SUCCESS:
    case POST_CANDIDATE_SERVICES_SUCCESS:
    case GET_INTERN_SERVICES_SUCCESS:
    case UPDATE_INTERN_SERVICES_SUCCESS:
    case POST_INTERN_SERVICES_SUCCESS:
    case GET_ALL_CANDIDATE_TRIPS_SUCCESS:
    case UPDATE_CANDIDATE_TRIP_SUCCESS:
    case POST_CANDIDATE_TRIP_SUCCESS:
    case GET_COST_ESTIMATES_SUCCESS:
    case UPDATE_COST_ESTIMATES_SUCCESS:
    case POST_COST_ESTIMATES_SUCCESS:
      handleCanidateTripsReducer(draft, action);
      break;

    case GET_EXPENSE_LINES_BY_AUTH_SUCCESS:
    case GET_EXPENSE_SUMMARIES_BY_AUTH_SUCCESS:
    case GET_EXPENSE_LINE_BY_SUMMARY_ID_SUCCESS:
    case UPDATE_EXPENSE_LINE_SUCCESS:
    case UPDATE_EXPENSE_SUMMARY_SUCCESS:
    case POST_EXPENSE_LINE_SUCCESS:
    case GET_EXPENSE_LINE_CUSTOM_TAXES_SUCCESS:
      handleAuthExpensesReducer(draft, action);
      break;

    case GET_COMMENT_AND_NOTES_SUCCESS:
    case POST_COMMENT_AND_NOTES_SUCCESS:
    case UPDATE_COMMENT_AND_NOTES_SUCCESS:
    case DELETE_COMMENT_AND_NOTES_SUCCESS:
      handleCommentsAndNotesReducer(draft, action);
      break;

    default:
      break;
  }

  return draft;
}, initialState);

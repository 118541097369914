import { CALL_INTAKE_V2_API } from 'middleware/intakeV2Api';
import {
  DELETE_COMMENT_AND_NOTES_FAILURE,
  DELETE_COMMENT_AND_NOTES_REQUEST,
  DELETE_COMMENT_AND_NOTES_SUCCESS,
  GET_COMMENT_AND_NOTES_FAILURE,
  GET_COMMENT_AND_NOTES_REQUEST,
  GET_COMMENT_AND_NOTES_SUCCESS,
  POST_COMMENT_AND_NOTES_FAILURE,
  POST_COMMENT_AND_NOTES_REQUEST,
  POST_COMMENT_AND_NOTES_SUCCESS,
  UPDATE_COMMENT_AND_NOTES_FAILURE,
  UPDATE_COMMENT_AND_NOTES_REQUEST,
  UPDATE_COMMENT_AND_NOTES_SUCCESS
} from '../types/commentsAndNotesTypes';

export const getCommentsAndNotes = authId => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_COMMENT_AND_NOTES_REQUEST, GET_COMMENT_AND_NOTES_SUCCESS, GET_COMMENT_AND_NOTES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${authId}/comments`,
      method: 'GET'
    }
  };
};

export const postCommentsAndNotes = values => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_COMMENT_AND_NOTES_REQUEST, POST_COMMENT_AND_NOTES_SUCCESS, POST_COMMENT_AND_NOTES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/comments/${username}`,
      method: 'POST',
      payload: values
    }
  };
};

export const updateCommentsAndNotes = values => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_COMMENT_AND_NOTES_REQUEST, UPDATE_COMMENT_AND_NOTES_SUCCESS, UPDATE_COMMENT_AND_NOTES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/comments/${username}`,
      method: 'PUT',
      payload: values
    }
  };
};

export const deleteCommentsAndNotes = commentId => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_COMMENT_AND_NOTES_REQUEST, DELETE_COMMENT_AND_NOTES_SUCCESS, DELETE_COMMENT_AND_NOTES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/comments/${commentId}`,
      method: 'DELETE'
    }
  };
};

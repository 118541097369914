import { Checkbox } from '@mui/material';
import { POST_GLOBAL_INFO_FAILURE, UPDATE_GLOBAL_INFO_FAILURE } from 'modules/authorizations/store/types/globalTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postGlobalInfo, updateGlobalInfo } from 'modules/authorizations/store/actions/global.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

import countries from 'modules/common/countries.json';
import { getAttachment } from 'modules/authorizations/store/actions/attachments.actions';
import { fetchAttachment } from 'modules/authorizations/utils/fetchDataDetailsService';
import { createFormFileObject, createSubmissionFileObject, openFile } from 'modules/authorizations/utils/fileHelper';

const TenancyManagementCard = props => {
  const [isLoading, setIsLoading] = useState(false);

  const { globalInfo, id, vendors, vendorContacts, isReadOnly } = props;

  const statusTypes = [
    { title: 'Active', id: 1 },
    { title: 'Inactive', id: 2 },
    { title: 'Occupied', id: 3 },
    { title: 'Sold', id: 4 },
    { title: 'Vacant', id: 5 }
  ];

  const residenceTypes = [
    { title: 'Condo', id: 1 },
    { title: 'Detached', id: 2 },
    { title: 'Duplex', id: 3 },
    { title: 'Farm', id: 4 },
    { title: 'Land', id: 5 },
    { title: 'Mobile Home', id: 6 },
    { title: 'Multi Family', id: 7 },
    { title: 'Single Family', id: 8 },
    { title: 'Unspecified', id: 9 }
  ];

  const vendorSearchInfo = {
    searchingColumns: [
      { name: 'vendorId', title: 'Vendor Id' },
      { name: 'companyName', title: 'Company Name' },
      { name: 'city', title: 'City' },
      { name: 'state', title: 'State' },
      { name: 'phone', title: 'Company Phone' },
      { name: 'email', title: 'Company Email' }
    ],
    searchingColumnExtensions: [
      { columnName: 'vendorId', width: 150 },
      { columnName: 'companyName', width: 250 },
      { name: 'city', width: 175 },
      { name: 'state', width: 175 },
      { columnName: 'phone', width: 175 },
      { columnName: 'email', width: 175 }
    ],
    searchingRows: vendors,
    idKey: 'vendorId',
    nameKey: 'companyName'
  };

  const vendorContactSearchInfo = {
    searchingColumns: [
      { name: 'vendorContactId', title: 'Vendor Contact Id' },
      { name: 'vendorId', title: 'Vendor Id' },
      { name: 'fullName', title: 'Full Name' },
      { name: 'officePhone', title: 'Contact Phone' },
      { name: 'officeEmail', title: 'Contact Email' }
    ],
    searchingColumnExtensions: [
      { columnName: 'vendorContactId', width: 150 },
      { columnName: 'vendorId', width: 150 },
      { columnName: 'fullName', width: 200 },
      { columnName: 'officePhone', width: 175 },
      { columnName: 'officeEmail', width: 175 }
    ],
    searchingRows: vendorContacts,
    idKey: 'vendorContactId',
    nameKey: 'fullName'
  };

  const tabs = [
    { header: 'Tenancy Management', order: 1, iconPath: ['fas', 'house-flag'] },
    { header: 'Additional Details', order: 2, iconPath: ['fas', 'file-contract'] },
    { header: 'Files', order: 3, iconPath: ['fas', 'file-download'] }
  ];

  const fetchAndOpenFile = async id => {
    try {
      const fileData = await fetchAttachment(id, props);
      openFile(fileData);
    } catch (error) {
      console.error('Error fetching file:', error);
    }
  };

  const summaries = [
    {
      header: 'Vendor Name',
      accessorKey: 'tenancyVendorId',
      nameKey: 'companyName',
      nameValue: globalInfo ? globalInfo.tenancyVendorCompanyName : '',
      type: 'advanced-search',
      searchInfo: vendorSearchInfo,
      required: false,
      value: globalInfo ? globalInfo.tenancyVendorId : '',
      hideInCard: true,
      tab: 1
    },
    {
      header: 'Vendor Contact',
      accessorKey: 'tenancyVendorContactId',
      nameKey: 'fullName',
      nameValue: globalInfo ? globalInfo.tenancyVendorContactName : '',
      type: 'advanced-search',
      searchInfo: vendorContactSearchInfo,
      required: false,
      value: globalInfo ? globalInfo.tenancyVendorContactId : '',
      hideInCard: true,
      tab: 1
    },

    {
      header: 'Vendor Name',
      accessorKey: 'tenancyVendorCompanyName',
      type: 'text',
      required: false,
      value: globalInfo ? globalInfo.tenancyVendorCompanyName : '',
      hideInModal: true,
      tab: 1
    },
    {
      header: 'Vendor Email',
      accessorKey: 'tenancyVendorEmail',
      type: 'text',
      required: false,
      value: globalInfo ? globalInfo.tenancyVendorEmail : '',
      hideInModal: true,
      tab: 1
    },
    {
      header: 'Vendor Contact',
      accessorKey: 'tenancyVendorContactName',
      type: 'text',
      required: false,
      value: globalInfo ? globalInfo.tenancyVendorContactName : '',
      hideInModal: true,
      tab: 1
    },
    {
      header: 'Vendor Contact Email',
      accessorKey: 'tenancyVendorContactEmail',
      type: 'text',
      required: false,
      value: globalInfo ? globalInfo.tenancyVendorContactEmail : '',
      hideInModal: true,
      tab: 1
    },
    {
      header: 'Property Name',
      accessorKey: 'tenancyPropertyName',
      type: 'text',
      required: false,
      value: globalInfo ? globalInfo.tenancyPropertyName : '',
      tab: 1
    },
    { header: 'City', accessorKey: 'tenancyCity', type: 'text', required: false, value: globalInfo ? globalInfo.tenancyCity : '', tab: 1 },
    {
      header: 'State',
      accessorKey: 'tenancyState',
      type: 'text',
      required: false,
      value: globalInfo ? globalInfo.tenancyState : '',
      tab: 1
    },
    { header: 'Zip', accessorKey: 'tenancyZip', type: 'text', required: false, value: globalInfo ? globalInfo.tenancyZip : '', tab: 1 },
    {
      header: 'Country',
      accessorKey: 'tenancyCountry',
      type: 'dropdown-v2',
      required: false,
      value: globalInfo ? globalInfo.tenancyCountry : '',
      options: countries,
      tab: 1
    },
    {
      header: 'Status',
      accessorKey: 'tenancyStatus',
      type: 'dropdown-v2',
      required: false,
      value: globalInfo ? globalInfo.tenancyStatus : '',
      options: statusTypes,
      tab: 1
    },
    {
      header: 'Type of Residence',
      accessorKey: 'typeOfResidence',
      type: 'dropdown-v2',
      required: false,
      value: globalInfo ? globalInfo.typeOfResidence : '',
      options: residenceTypes,
      tab: 1
    },
    {
      header: 'Landlord Contact',
      accessorKey: 'landlordContact',
      type: 'text',
      required: false,
      value: globalInfo ? globalInfo.landlordContact : '',
      tab: 1
    },
    {
      header: 'Term of Lease',
      accessorKey: 'termOfLease',
      type: 'text',
      required: false,
      value: globalInfo ? globalInfo.termOfLease : '',
      tab: 1
    },
    { dividerLine: true, tab: 1 },
    {
      header: 'Vendor Notified',
      accessorKey: 'vendorNotified',
      type: 'checkbox',
      required: false,
      value: globalInfo ? globalInfo.vendorNotified : '',
      tab: 1,
      Cell: ({ cell }) => <Checkbox name="vendorNotified" label="Active" checked={cell.row.original.vendorNotified} disabled={true} />
    },
    {
      header: 'Early Termination',
      accessorKey: 'earlyTermination',
      type: 'checkbox',
      required: false,
      value: globalInfo ? globalInfo.earlyTermination : '',
      tab: 1,
      Cell: ({ cell }) => <Checkbox name="earlyTermination" label="Active" checked={cell.row.original.earlyTermination} disabled={true} />
    },
    {
      header: 'Needs Assessment Completed',
      accessorKey: 'needsAssessmentCompleted',
      type: 'checkbox',
      required: false,
      value: globalInfo ? globalInfo.needsAssessmentCompleted : '',
      tab: 1,
      Cell: ({ cell }) => (
        <Checkbox name="needsAssessmentCompleted" label="Active" checked={cell.row.original.needsAssessmentCompleted} disabled={true} />
      )
    },
    {
      header: 'Review Policies Procedure',
      accessorKey: 'reviewPoliciesProcedures',
      type: 'checkbox',
      required: false,
      value: globalInfo ? globalInfo.reviewPoliciesProcedures : '',
      tab: 1,
      Cell: ({ cell }) => (
        <Checkbox name="reviewPoliciesProcedure" label="Active" checked={cell.row.original.reviewPoliciesProcedures} disabled={true} />
      )
    },
    {
      header: 'Market Analysis Complete',
      accessorKey: 'marketAnalysisComplete',
      type: 'checkbox',
      required: false,
      value: globalInfo ? globalInfo.marketAnalysisComplete : '',
      tab: 1,
      Cell: ({ cell }) => (
        <Checkbox name="marketAnalysisComplete" label="Active" checked={cell.row.original.marketAnalysisComplete} disabled={true} />
      )
    },
    { dividerLine: true, tab: 1 },
    {
      header: 'Budget Rent (Local Currency)',
      accessorKey: 'budgetRentLocal',
      type: 'money',
      required: false,
      value: globalInfo ? globalInfo.budgetRentLocal : '',
      tab: 1
    },
    {
      header: 'Budget Rent (Reporting Currency)',
      accessorKey: 'budgetRentReporting',
      type: 'money',
      required: false,
      value: globalInfo ? globalInfo.budgetRentReporting : '',
      tab: 1
    },
    {
      header: 'Actual Rent (Local Currency)',
      accessorKey: 'actualRentLocal',
      type: 'money',
      required: false,
      value: globalInfo ? globalInfo.actualRentLocal : '',
      tab: 1
    },
    {
      header: 'Actual Rent (Reporting Currency)',
      accessorKey: 'actualRentReporting',
      type: 'money',
      required: false,
      value: globalInfo ? globalInfo.actualRentReporting : '',
      tab: 1
    },
    {
      header: 'Monthly Fee/Rent',
      accessorKey: 'monthlyFeeRent',
      type: 'money',
      required: false,
      value: globalInfo ? globalInfo.monthlyFeeRent : '',
      tab: 1
    },
    {
      header: 'Expected Shortfall',
      accessorKey: 'expectedShortfall',
      type: 'money',
      required: false,
      value: globalInfo ? globalInfo.expectedShortfall : '',
      tab: 1
    },
    {
      header: 'Actual Shortfall',
      accessorKey: 'actualShortfall',
      type: 'money',
      required: false,
      value: globalInfo ? globalInfo.actualShortfall : '',
      tab: 1
    },
    {
      header: 'Actual Rent or Deposits Made',
      accessorKey: 'actualRentPaid',
      type: 'money',
      required: false,
      value: globalInfo ? globalInfo.actualRentPaid : '',
      tab: 1
    },
    { dividerLine: true, tab: 1 },
    {
      header: 'Service Start',
      accessorKey: 'hostServiceStart',
      type: 'date',
      required: false,
      value: globalInfo ? globalInfo.hostServiceStart : '',
      tab: 1
    },
    {
      header: 'Service End',
      accessorKey: 'hostServiceEnd',
      type: 'date',
      required: false,
      value: globalInfo ? globalInfo.hostServiceEnd : '',
      tab: 1
    },
    {
      header: 'Winterization',
      accessorKey: 'winterization',
      type: 'date',
      required: false,
      value: globalInfo ? globalInfo.winterization : '',
      tab: 1
    },
    {
      header: 'De-Winterized',
      accessorKey: 'deWinterization',
      type: 'date',
      required: false,
      value: globalInfo ? globalInfo.deWinterization : '',
      tab: 1
    },
    {
      header: 'Renewal Date 1',
      accessorKey: 'renewalDateOne',
      type: 'date',
      required: false,
      value: globalInfo ? globalInfo.renewalDateOne : '',
      tab: 1
    },
    {
      header: 'Renewal Date 2',
      accessorKey: 'renewalDateTwo',
      type: 'date',
      required: false,
      value: globalInfo ? globalInfo.renewalDateTwo : '',
      tab: 1
    },
    {
      header: 'Renewal Date 3',
      accessorKey: 'renewalDateThree',
      type: 'date',
      required: false,
      value: globalInfo ? globalInfo.renewalDateThree : '',
      tab: 1
    },
    {
      header: 'Renewal Date 4',
      accessorKey: 'renewalDateFour',
      type: 'date',
      required: false,
      value: globalInfo ? globalInfo.renewalDateFour : '',
      tab: 1
    },
    {
      header: 'Renewal Date 5',
      accessorKey: 'renewalDateFive',
      type: 'date',
      required: false,
      value: globalInfo ? globalInfo.renewalDateFive : '',
      tab: 1
    },

    {
      header: 'Main Features',
      accessorKey: 'mainFeatures',
      type: 'notes',
      required: false,
      value: globalInfo ? globalInfo.mainFeatures : '',
      tab: 2
    },
    {
      header: 'Payment Details',
      accessorKey: 'paymentDetails',
      type: 'notes',
      required: false,
      value: globalInfo ? globalInfo.paymentDetails : '',
      tab: 2
    },
    {
      header: 'Contract Details',
      accessorKey: 'contractDetails',
      type: 'notes',
      required: false,
      value: globalInfo ? globalInfo.contractDetails : '',
      tab: 2
    },
    {
      header: 'Contract Restrictions',
      accessorKey: 'contractRestrictions',
      type: 'notes',
      required: false,
      value: globalInfo ? globalInfo.contractRestrictions : '',
      tab: 2
    },
    {
      header: 'Tenant Details',
      accessorKey: 'tenantDetails',
      type: 'notes',
      required: false,
      value: globalInfo ? globalInfo.tenantDetails : '',
      tab: 2
    },
    {
      header: 'Special Needs',
      accessorKey: 'specialNeeds',
      type: 'notes',
      required: false,
      value: globalInfo ? globalInfo.specialNeeds : '',
      tab: 2
    },
    {
      header: 'Property Details',
      accessorKey: 'propertyDetails',
      type: 'notes',
      required: false,
      value: globalInfo ? globalInfo.propertyDetails : '',
      tab: 2
    },
    {
      header: 'Terms and Conditions',
      accessorKey: 'termsConditions',
      type: 'notes',
      required: false,
      value: globalInfo ? globalInfo.termsConditions : '',
      tab: 2
    },

    {
      header: 'Lease Document',
      accessorKey: 'leaseDocumentFile',
      type: 'file',
      required: false,
      value: globalInfo ? createFormFileObject(globalInfo.leaseDocumentFile) : null,
      tab: 3,
      fetchFile: id => fetchAndOpenFile(id, props)
    },
    {
      header: 'Photo One',
      accessorKey: 'photoOneFile',
      type: 'file',
      required: false,
      value: globalInfo ? createFormFileObject(globalInfo.photoOneFile) : null,
      tab: 3,
      fetchFile: id => fetchAndOpenFile(id, props)
    },
    {
      header: 'Photo Two',
      accessorKey: 'photoTwoFile',
      type: 'file',
      required: false,
      value: globalInfo ? createFormFileObject(globalInfo.photoTwoFile) : null,
      tab: 3,
      fetchFile: id => fetchAndOpenFile(id, props)
    },
    {
      header: 'Photo Three',
      accessorKey: 'photoThreeFile',
      type: 'file',
      required: false,
      value: globalInfo ? createFormFileObject(globalInfo.photoThreeFile) : null,
      tab: 3,
      fetchFile: id => fetchAndOpenFile(id, props)
    },
    {
      header: 'Photo Four',
      accessorKey: 'photoFourFile',
      type: 'file',
      required: false,
      value: globalInfo ? createFormFileObject(globalInfo.photoFourFile) : null,
      tab: 3,
      fetchFile: id => fetchAndOpenFile(id, props)
    },
    {
      header: 'Photo Five',
      accessorKey: 'photoFiveFile',
      type: 'file',
      required: false,
      value: globalInfo ? createFormFileObject(globalInfo.photoFiveFile) : null,
      tab: 3,
      fetchFile: id => fetchAndOpenFile(id, props)
    }
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);
    if (globalInfo && globalInfo.length !== 0) {
      let newValues = { ...globalInfo };

      if (tab === 1) {
        newValues.tenancyPropertyName = values.tenancyPropertyName;
        newValues.tenancyCity = values.tenancyCity;
        newValues.tenancyState = values.tenancyState;
        newValues.tenancyZip = values.tenancyZip;
        newValues.tenancyCountry = values.tenancyCountry;
        newValues.tenancyStatus = values.tenancyStatus;
        newValues.typeOfResidence = values.typeOfResidence;
        newValues.landlordContact = values.landlordContact;
        newValues.termOfLease = values.termOfLease;
        newValues.vendorNotified = values.vendorNotified;
        newValues.earlyTermination = values.earlyTermination;
        newValues.needsAssessmentCompleted = values.needsAssessmentCompleted;
        newValues.reviewPoliciesProcedures = values.reviewPoliciesProcedures;
        newValues.marketAnalysisComplete = values.marketAnalysisComplete;
        newValues.budgetRentLocal = values.budgetRentLocal;
        newValues.budgetRentReporting = values.budgetRentReporting;
        newValues.actualRentLocal = values.actualRentLocal;
        newValues.actualRentReporting = values.actualRentReporting;
        newValues.monthlyFeeRent = values.monthlyFeeRent;
        newValues.expectedShortfall = values.expectedShortfall;
        newValues.actualShortfall = values.actualShortfall;
        newValues.actualRentPaid = values.actualRentPaid;
        newValues.hostServiceStart = values.hostServiceStart;
        newValues.hostServiceEnd = values.hostServiceEnd;
        newValues.winterization = values.winterization;
        newValues.deWinterization = values.deWinterization;
        newValues.renewalDateOne = values.renewalDateOne;
        newValues.renewalDateTwo = values.renewalDateTwo;
        newValues.renewalDateThree = values.renewalDateThree;
        newValues.renewalDateFour = values.renewalDateFour;
        newValues.renewalDateFive = values.renewalDateFive;

        if (values.tenancyVendorId != null) {
          const newVendor = vendors.find(v => v.vendorId === values.tenancyVendorId);
          newValues.tenancyVendorId = newVendor.vendorId;
          newValues.tenancyVendorCompanyName = newVendor.companyName;
          newValues.tenancyVendorEmail = newVendor.email;
        }

        if (values.tenancyVendorContactId != null) {
          const newVendorContact = vendorContacts.find(v => v.vendorContactId === values.tenancyVendorContactId);
          newValues.tenancyVendorContactId = newVendorContact.vendorContactId;
          newValues.tenancyVendorContactName = newVendorContact.fullName;
          newValues.tenancyVendorContactEmail = newVendorContact.officeEmail;
        }
      } else if (tab === 2) {
        newValues.mainFeatures = values.mainFeatures;
        newValues.paymentDetails = values.paymentDetails;
        newValues.contractDetails = values.contractDetails;
        newValues.contractRestrictions = values.contractRestrictions;
        newValues.tenantDetails = values.tenantDetails;
        newValues.specialNeeds = values.specialNeeds;
        newValues.propertyDetails = values.propertyDetails;
        newValues.termsConditions = values.termsConditions;
      } else {
        newValues.leaseDocumentFile = await createSubmissionFileObject(values.leaseDocumentFile);
        newValues.photoOneFile = await createSubmissionFileObject(values.photoOneFile);
        newValues.photoTwoFile = await createSubmissionFileObject(values.photoTwoFile);
        newValues.photoThreeFile = await createSubmissionFileObject(values.photoThreeFile);
        newValues.photoFourFile = await createSubmissionFileObject(values.photoFourFile);
        newValues.photoFiveFile = await createSubmissionFileObject(values.photoFiveFile);
      }

      const resp = await props.updateGlobalInfo(newValues);
      if (resp.type === UPDATE_GLOBAL_INFO_FAILURE) {
        props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    } else {
      values.authorizationId = id;

      const resp = await props.postGlobalInfo(values);
      if (resp.type === POST_GLOBAL_INFO_FAILURE) {
        props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else {
        setIsLoading(false);
        return true;
      }
    }
  };

  return (
    <>
      <EditableCard
        tabs={tabs}
        summaries={summaries}
        numColumns={5}
        updateFields={updateFields}
        isLoading={isLoading}
        hideEdit={isReadOnly}
      />
    </>
  );
};

const mapStateToProps = state => {
  const {
    authorizations: { vendors, vendorContacts }
  } = state;
  return { vendors, vendorContacts };
};

export default compose(
  connect(mapStateToProps, {
    updateGlobalInfo,
    postGlobalInfo,
    showToast,
    getAttachment
  })
)(TenancyManagementCard);

import { CALL_INTAKE_V2_API } from "middleware/intakeV2Api";
import { GET_ATTACHMENT_FAILURE, GET_ATTACHMENT_REQUEST, GET_ATTACHMENT_SUCCESS } from "../types/attachmentTypes";

export const getAttachment = (fileId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_ATTACHMENT_REQUEST, GET_ATTACHMENT_SUCCESS, GET_ATTACHMENT_FAILURE],
      authenticated: true,
      endpoint: `api/files/${fileId}`,
      method: 'GET',
    },
  };
};
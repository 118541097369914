/* eslint-disable react-hooks/exhaustive-deps */

import { withStyles } from '@material-ui/core';
import { Box, Grid } from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  getAuthClientPolicies,
  getAuthDateSummary,
  getAuthorizationSummary,
  getCommunicationInfo,
  getDepartureAddress,
  getDestinationAddress,
  getEmployeeInfo,
  getFamilyMembers,
  getFamilyPets,
  getServices,
  getTeam,
  getUserSettings,
  setAuthId,
  setLoadingComplete
} from '../../store/actions/authorizations.actions';
import { getBrokerAgents, getVendors } from 'modules/authorizations/store/actions/dataManagement.actions';
import {
  getCandidateServices,
  getCandidateTrips,
  getCostEstimates,
  getInternServices
} from 'modules/authorizations/store/actions/canidateTrip.actions';

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import CandidateServices from './candidateServices.component';
import InternServices from './internServices.component';
import {
  fetchAuthCanidateDetails,
  fetchAuthDetails,
  fetchBrokerAgents,
  fetchVendors
} from 'modules/authorizations/utils/fetchDataDetailsService';
import { showToast } from 'modules/layout/layout.actions';

const styles = theme => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  }
});

const CandidateServicesView = props => {
  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const details = props.authorizationDetails ? props.authorizationDetails[props.match.params.id] : null;
  const moveType = details?.authDetails?.summary?.moveType;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const id = props.match.params.id;

      window.scrollTo(0, 0);
      props.setAuthId(id);

      setIsLoading(true);
      await fetchAuthDetails(details, id, props);
      await fetchVendors(props?.vendors, props);
      await fetchBrokerAgents(props?.brokerAgents, props);
      await fetchAuthCanidateDetails(details, id, props);
      setIsLoading(false);
    }

    fetchData();
  }, []);

  const isValidMoveType = () => {
    if (moveType === 'Intern' || moveType === 'Candidate') return true;
    return false;
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!isLoading && details && (
          <>
            {isValidMoveType() ? (
              <Grid>
                {moveType === 'Candidate' ? (
                  <CandidateServices
                    details={details}
                    id={props.match.params.id}
                    brokerAgents={props.brokerAgents || []}
                    history={props.history}
                    location={props.location}
                  />
                ) : (
                  <InternServices
                    details={details}
                    id={props.match.params.id}
                    brokerAgents={props.brokerAgents || []}
                    history={props.history}
                    location={props.location}
                  />
                )}
              </Grid>
            ) : (
              <Box display="flex" justifyContent="center" mb={2}>
                <h5 style={{ color: 'red', fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}>
                  If this is a candidate file, please change the move type accordingly.
                </h5>
              </Box>
            )}
          </>
        )}
        {isLoading && <FullscreenSpinner />}
      </Box>
    </ThemeProvider>
  );
};

CandidateServicesView.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { authorizationDetails, brokerAgents, isLoading, vendors } = state.authorizations;
  const { unmappedClients } = state.clients;
  return {
    authorizationDetails,
    brokerAgents,
    unmappedClients,
    isLoading,
    vendors
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getEmployeeInfo,
    getCommunicationInfo,
    getAuthClientPolicies,
    setAuthId,
    setLoadingComplete,
    getAuthDateSummary,
    getDepartureAddress,
    getDestinationAddress,
    getTeam,
    getServices,
    getUserSettings,
    getBrokerAgents,
    getCandidateServices,
    getInternServices,
    getCandidateTrips,
    getCostEstimates,
    showToast,
    getVendors,
    getFamilyMembers,
    getFamilyPets
  })
)(CandidateServicesView);

/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
import { Box, Card, CardContent, FormControl, Grid, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { getMonth } from './utils';
import { makeStyles } from '@material-ui/styles';
import { showToast } from 'modules/layout/layout.actions';
import CrudTable from 'modules/clients/details/crudTable.component';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));

/*
      - Required Props: 
       * tabs - a list of tabs to be included in the card
       * summaries - a list of objects detailing the specific textfield requirements for the editing feature as well as the necessary values to display in the crud table
       * numColumns - number of xl device columns (for if there is a header row)
      - Optional Props: 
       * 
  
      - Required Label Fields: accessorKey, header, type, options (only required for dropdown type)
      - Optional Label Fields: required (defaults to false), size, enableColumnOrdering, enableEditing, enableSorting, hideInTable (if column should be displayed in the CRUD table or not)
  
      - Label Types: text, notes, number, date, dropdown, dropdownv2, advanced-search
  
      Tabs are displayed in the order they appear in the list. If there is only one item in the list, then it will be displayed as a 'header' (not clickable, no other visible tabs) and all items in the summaries list will be displayed and editable by default. If within the summaries list
      there is an item with a 'tab' option of a different value than the 'order' in the single item within the tabs list. (Example - there is an item in the summaries list with a 'tab' value of 3. There is only one item in the tabs list. That item will still be displayed within the card.)
  
        const crudTableCardTabs = [
            { header: 'Vendors', order: 1, iconPath: ['fas', 'user'] },
            { header: 'Vendor Contacts', order: 2, iconPath: ['fas', 'user'] },
        ];

        We need to include 'adjustedColumns' value for the crud table columns and input. Example:

        const vendorsAdjustedColumns = [
            { accessorKey: 'companyName', header: 'Company Name', type: 'text', required: true, size: 70 },
            { accessorKey: 'description', header: 'Description', type: 'text', required: false, size: 50 },
            { accessorKey: 'address', header: 'Address', type: 'text', required: false, size: 50 },
            { accessorKey: 'city', header: 'City', type: 'text', required: false, size: 50 },
            { accessorKey: 'state', header: 'State', type: 'text', required: false, size: 50 },
            { accessorKey: 'zip', header: 'Zip', type: 'text', required: false, size: 50 },
            { accessorKey: 'phone', header: 'Phone', type: 'text', required: false, size: 50 },
            { accessorKey: 'email', header: 'Email', type: 'text', required: false, size: 50 },
            { accessorKey: 'primary', header: 'Primary', type: 'text', required: false, size: 50 },
        ];

        const vendorContactsAdjustedColumns = [
            { accessorKey: 'vendorId', header: 'Vendor Id', type: 'number', required: true, size: 70 },
            { accessorKey: 'fullName', header: 'Full Name', type: 'text', required: false, size: 50 },
            { accessorKey: 'officePhone', header: 'Office Phone', type: 'text', required: false, size: 50 },
            { accessorKey: 'officeEmail', header: 'Office Email', type: 'text', required: false, size: 50 },
        ];

        The 'summaries' array contains: adjustedColumns, rows, addButtonText, tab, updateRow, createRow, deleteRow. Also included (but optional) is headerRow: an object that contains the accessorKey and value of what should be displayed as a 'header'.
        Ex. If you wanted to display a primary contact like in the client contacts table, accessorKey = isPrimary and value = true. The first row in the table that has this condition as true is displayed above the CRUD table for easy viewing, but not editable.

        const crudTableCardSummaries = [
            { adjustedColumns: summaries, rows: props.vendors, addButtonText: 'Add Vendor', tab: 1, updateRow: updateVendorRow, createRow: createVendorRow, deleteRow: deleteVendorRow},
            { adjustedColumns: vendorContactsAdjustedColumns, rows: props.vendorContacts, addButtonText: 'Add Vendor Contact', tab: 2, updateRow: updateVendorContactRow, createRow: createVendorContactRow, deleteRow: deleteVendorContactRow }
        ];
  
  */

const CrudTableCard = (props) => {
  const { tabs, summaries, numColumns, openCurrentTab = 1, isLoading = false, getNameOfDeleteRow = null, showBasicTable = false } = props;
  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const [currentTab, setCurrentTab] = useState(1);

  useEffect(() => {
    setCurrentTab(openCurrentTab);
  }, [openCurrentTab]);

  let onlyOneTab = false;
  if (tabs.length === 1) {
    onlyOneTab = true;
  }

  const renderTab = (tab) => {
    return (
      <Tab
        icon={
          <Box sx={{ paddingRight: '10px' }}>
            <FontAwesomeIcon icon={tab.iconPath} size="1x" />
          </Box>
        }
        iconPosition={'start'}
        value={tab.order}
        label={tab.header}
        disabled={tab?.isDisabled ?? false}
        sx={{
          zIndex: 1,
          minWidth: '265px',
          minHeight: '0px',
          textTransform: 'none',
          fontWeight: 'bold',
          color: 'black',
        }}
      />
    );
  };

  const renderHeader = (header) => {
    if (header?.multiTabComponent) return;
    return (
      <div className="row justify-space-between align-center">
        <div className={classes.iconRow}>
          {header.iconPath && (
            <Tooltip title={header.header} key={header.header} placement="right">
              <FontAwesomeIcon icon={header.iconPath} size="2x" />
            </Tooltip>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              paddingLeft: header.iconPath ? '20px' : '0px',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {header.header}
            </Typography>
            {header.headerMessage && (
              <Typography
                variant="h5"
                sx={{
                  color: 'green',
                }}
              >
                {header.headerMessage}
              </Typography>
            )}
          </Box>
        </div>
      </div>
    );
  };

  const renderValue = (type, value) => {
    // removed dropdown-v2 for now
    // removed advanced-search for now
    if (type === 'date') {
      return value ? new Date(value).toLocaleDateString('en-us') : <div>&mdash;</div>;
    } else if (type === 'datetime') {
      if (value) {
        let date = new Date(value);
        const formatter = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' });
        return `${getMonth(date.getMonth())} ${date.getDate()} ${date.getFullYear()} ${formatter.format(date)}`;
      } else {
        return <div>&mdash;</div>;
      }
    } else if (type === 'money') {
      return value ? `$${  value}` : <div>&mdash;</div>;
    } else {
      return value ? value : <div>&mdash;</div>;
    }
  };

  const getHeaderValue = (rows, headerRow) => {
    return rows.find((opt) => opt[headerRow.accessorKey] === headerRow.value);
  };

  const getHeaderRow = (rows, headerRow, adjustedColumns, tab) => {
    let header = getHeaderValue(rows, headerRow);
    // Pretty straightforward - use key for the key and value for the value.
    // Just to clarify: unlike object destructuring, the parameter names don't matter here.
    if (header) {
      return (
        <Grid container spacing={1} sx={{ display: 'flex', flexWrap: 'nowrap', width: '100%', marginLeft: '5px' }}>
          {Object.entries(header).map(([key, value]) => {
            let column = adjustedColumns.find((a) => a.accessorKey === key);
            if (column && column?.displayInHeaderRow === true) {
              return (
                <Grid item key={key} sx={{ flexGrow: 1, flexBasis: '20%', minWidth: '0' }}>
                  {renderOption(key, column.header, column.type, value, tab)}
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      );
    } else {
      return null;
    }
  };

  const renderOption = (accessorKey, header, type, value, tab) => {
    let isEven = numColumns % 2 === 0;
    if (onlyOneTab || tab === currentTab) {
      return (
        <Grid item xs={isEven ? numColumns / 2 : numColumns} sm={isEven ? 1 : 2} md={isEven ? 1 : 2} xl={isEven ? 1 : 2}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', marginBottom: '5px' }}>
              <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, marginRight: '5px' }}>
                {header}:
              </Typography>
            </Box>
            {type === 'checkbox' ? (
              <FormControl sx={{ marginTop: '-10px', marginBottom: '1px' }}>
                <FormControlLabel
                  key={accessorKey}
                  name={accessorKey}
                  control={<Checkbox />}
                  checked={value}
                  disabled={true}
                  variant="standard"
                  margin="dense"
                />
              </FormControl>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', marginBottom: '5px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                  {renderValue(type, value)}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      );
    }
    return null;
  };

  const renderCrudTable = (summary) => {
    if (summary.pinnedRow && summary.rows) {
      let pinnedRow = summary.rows.find((r) => r[summary.pinnedRow.name] === summary.pinnedRow.valueOne || r[summary.pinnedRow.name] === summary.pinnedRow.valueTwo);
      if (pinnedRow) {
        summary.rows = summary.rows.filter((r) => r[summary.pinnedRow.name] !== summary.pinnedRow.valueOne && r[summary.pinnedRow.name] !== summary.pinnedRow.valueTwo);
        summary.rows.unshift(pinnedRow);
      }
    }

    if (onlyOneTab) {
      return (
        <div>
          <Grid container spacing={1} sx={{ marginLeft: '5px', marginBottom: '10px', flexDirection: 'column' }}>
            {summary.headerRow && getHeaderValue(summary.rows, summary.headerRow) && (
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                <Typography variant="h6" sx={{ marginTop: '0' }}>
                  {summary.headerRow.title}
                </Typography>
              </Grid>
            )}
            {summary.headerRow && getHeaderRow(summary.rows, summary.headerRow, summary.adjustedColumns, summary.tab)}
          </Grid>
          <CrudTable
            editingMode={'modal'} // Can be changed to 'row' to edit inline
            columns={summary.adjustedColumns}
            rows={summary.rows}
            createRow={summary.createRow}
            updateRow={summary.updateRow}
            deleteRow={summary.deleteRow}
            customAddText={summary.addButtonText}
            isLoading={isLoading}
            getNameOfDeleteRow={getNameOfDeleteRow}
            hideEdit={summary.hideEdit}
            headerRowFields={summary?.headerRowFields}
            showBasicTable={showBasicTable}
            pinnedRow={summary.pinnedRow}
          />
        </div>
      );
    } else {
      // get the right summary for crud table
      if (summary.tab === currentTab) {
        return (
          <div>
            <Grid
              container
              spacing={1}
              columns={{ xs: numTotalColumns, sm: numTotalColumns, md: numTotalColumns, xl: numTotalColumns }}
              sx={{ marginTop: '5px', marginBottom: '15px' }}
            >
              {summary.headerRow && (
                <>
                  <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                    {summary.headerRow.title}
                  </Typography>
                </>
              )}
              {summary.headerRow &&
                // pass in the found instance in the CRUD table matching the header row data, all of the adjusted columns to rendor options, and the tab the header must be on
                getHeaderRow(summary.rows, summary.headerRow, summary.adjustedColumns, summary.tab)}
            </Grid>
            <CrudTable
              editingMode={'modal'} // Can be changed to 'row' to edit inline
              columns={summary.adjustedColumns}
              rows={summary.rows}
              createRow={summary.createRow}
              updateRow={summary.updateRow}
              deleteRow={summary.deleteRow}
              customAddText={summary.addButtonText}
              isLoading={isLoading}
              getNameOfDeleteRow={getNameOfDeleteRow}
              hideEdit={summary.hideEdit}
              headerRowFields={summary?.headerRowFields}
              showBasicTable={showBasicTable}
              pinnedRow={summary.pinnedRow}
            />
          </div>
        );
      }
    }
  };

  const handleChangeTab = (tab) => {
    setCurrentTab(tab);
  };

  let numTotalColumns = numColumns % 2 === 0 ? numColumns : numColumns * 2;

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ position: 'relative' }}>
        <Box>
          {!summaries && (
            <CardContent className={classes.cardContainer}>
              {onlyOneTab && <>{tabs.map(renderHeader)}</>}
              {!onlyOneTab && (
                <>
                  <div className="row justify-space-between align-center">
                    <Tabs variant="fullWidth" value={currentTab} onChange={(event, tab) => handleChangeTab(tab)} style={{ flex: 0.9 }}>
                      {tabs.map(renderTab)}
                    </Tabs>
                  </div>
                </>
              )}
            </CardContent>
          )}

          <CardContent sx={{ padding: '5px', marginLeft: '30px' }}>{summaries.map((opts) => renderCrudTable(opts))}</CardContent>
        </Box>
      </Card>
    </ThemeProvider>
  );
};
CrudTableCard.propTypes = {
  tabs: PropTypes.array.isRequired,
  summaries: PropTypes.array.isRequired,
  showToast: PropTypes.func.isRequired,
};
export default connect(null, { showToast })(CrudTableCard);

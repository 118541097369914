
import { POST_AUTH_DESTINATION_SERVICES_FAILURE, UPDATE_AUTH_DESTINATION_SERVICES_FAILURE } from 'modules/authorizations/store/types/destinationServiceTypes';
import { POST_AUTH_SERVICES_FAILURE, UPDATE_AUTH_SERVICES_FAILURE } from 'modules/authorizations/store/types/authorizationTypes';
import { POST_CANDIDATE_SERVICES_FAILURE, POST_INTERN_SERVICES_FAILURE, UPDATE_CANDIDATE_SERVICES_FAILURE, UPDATE_INTERN_SERVICES_FAILURE } from 'modules/authorizations/store/types/canidateTripTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postAuthDestinationServices, updateAuthDestinationServices } from 'modules/authorizations/store/actions/destinationService.actions';
import { postCandidateServices, postInternServices, updateCandidateServices, updateInternServices } from 'modules/authorizations/store/actions/canidateTrip.actions';
import { postServices, updateServices } from 'modules/authorizations/store/actions/authorizations.actions';
import { showToast } from 'modules/layout/layout.actions';
import React, { useState } from 'react';
import ServicesEditableCard from './servicesEditableCard.component';

const ServicesCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const authServices = props.authServices ? props.authServices : {};
  const homesaleService = props.details.authHomesale ? props.details.authHomesale : {};
  const globalService = props.details.authGlobal ? props.details.authGlobal : {};
  const householdGoodsService = props.details.authHouseHoldGoods ? props.details.authHouseHoldGoods : {};
  const destinationServices = props.destinationServices ? props.destinationServices : {};
  const candidateServices = props.candidateServices ? props.candidateServices : {};
  const internServices = props.internServices ? props.internServices : {};
  const moveType = props.moveType ? props.moveType : {};
  const status = props.fileStatus ? props.fileStatus : '';
  const webAccess = props.webAccess ? props.webAccess : '';
  
  /*
        If value = empty string or null => status = disabled
        From the card editing modal:
            If value = 'true' (checkbox is clicked) then status = init
            If value = 'false' (checkbox is unclicked) then status = disabled

        Customized Services editable card

    */


  const updateFields = async (temporaryLiving, taxAssistance, homesale, global, homefindRenter, homefindBuyer, householdGoods) => {
    setIsLoading(true);

    const values = {
      'authorizationId': props.id,
      taxAssistance,
      homesale,
      global,
      homefindRenter,
      homefindBuyer,
      temporaryLiving,
      'expenses': authServices.expenses,
      householdGoods,
    };

    // update values that are set as true/false

    if (authServices && authServices.length !== 0) {
      const resp = await props.updateServices(values);
      if (resp.type === UPDATE_AUTH_SERVICES_FAILURE) {
        props.showToast('Failed to update the employee services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else {
        if (homefindRenter === 'Initiated')
        {
          let newVals = { ...destinationServices };
          newVals.status = 'Initiated';
          newVals.initiationDate = new Date().toISOString().split('T')[0];
          newVals.authorizationId = props.id;
            
          // update destination services if set to true
          if (destinationServices && destinationServices.length !== 0) {
            const destResp = await props.updateAuthDestinationServices(newVals);
            if (destResp.type === UPDATE_AUTH_DESTINATION_SERVICES_FAILURE) {
              props.showToast('Failed to update the destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
              setIsLoading(false);
              return false;
            } else { 
              setIsLoading(false);
              return true; 
            }
          } else {
            const destResp = await props.postAuthDestinationServices(newVals);
            if (destResp.type === POST_AUTH_DESTINATION_SERVICES_FAILURE) {
              props.showToast('Failed to update the destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
              setIsLoading(false);
              return false;
            } else { 
              setIsLoading(false);
              return true; 
            }
          }
        }
        if (homefindBuyer === 'Initiated')
        {
          if (moveType === 'Candidate') {
            let newVals = { ...candidateServices };
            newVals.status = 'Initiated';
            newVals.initiationDate = new Date().toISOString().split('T')[0];
            newVals.authorizationId = props.id;
                    
            // update candidate services if set to true
            if (candidateServices && candidateServices.length !== 0) {
              const candidateResp = await props.updateCandidateServices(newVals);
              if (candidateResp.type === UPDATE_CANDIDATE_SERVICES_FAILURE) {
                props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
                setIsLoading(false);
                return false;
              } else { 
                setIsLoading(false);
                return true; 
              }
            } else {
              const candidateResp = await props.postCandidateServices(newVals);
              if (candidateResp.type === POST_CANDIDATE_SERVICES_FAILURE) {
                props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
                setIsLoading(false);
                return false;
              } else { 
                setIsLoading(false);
                return true; 
              }
            }
          }
          else {
            let newVals = { ...internServices };
            newVals.status = 'Initiated';
            newVals.initiationDate = new Date().toISOString().split('T')[0];
            newVals.authorizationId = props.id;
                    
            // update candidate services if set to true
            if (internServices && internServices.length !== 0) {
              const internResp = await props.updateInternServices(newVals);
              if (internResp.type === UPDATE_INTERN_SERVICES_FAILURE) {
                props.showToast('Failed to update the intern services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
                setIsLoading(false);
                return false;
              } else { 
                setIsLoading(false);
                return true; 
              }
            } else {
              const internResp = await props.postInternServices(newVals);
              if (internResp.type === POST_INTERN_SERVICES_FAILURE) {
                props.showToast('Failed to update the intern services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
                setIsLoading(false);
                return false;
              } else { 
                setIsLoading(false);
                return true; 
              }
            }
          }
                            
        }
        setIsLoading(false);
        return true; 
      }
    } 
    else {
      const resp = await props.postServices(values);
      if (resp.type === POST_AUTH_SERVICES_FAILURE) {
        props.showToast('Failed to update the employee services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        if (homefindRenter === 'Initiated')
        {
          let newVals = { ...destinationServices };
          newVals.status = 'Initiated';
          newVals.initiationDate = new Date().toISOString().split('T')[0];
          newVals.authorizationId = props.id;
            
          // update destination services if set to true
          if (destinationServices && destinationServices.length !== 0) {
            const destResp = await props.updateAuthDestinationServices(newVals);
            if (destResp.type === UPDATE_AUTH_DESTINATION_SERVICES_FAILURE) {
              props.showToast('Failed to update the destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
              setIsLoading(false);
              return false;
            } else { 
              setIsLoading(false);
              return true; 
            }
          } else {
            const destResp = await props.postAuthDestinationServices(newVals);
            if (destResp.type === POST_AUTH_DESTINATION_SERVICES_FAILURE) {
              props.showToast('Failed to update the destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
              setIsLoading(false);
              return false;
            } else { 
              setIsLoading(false);
              return true; 
            }
          }
        }
        if (homefindBuyer === 'Initiated')
        {
          if (moveType === 'Candidate') {
            let newVals = { ...candidateServices };
            newVals.status = 'Initiated';
            newVals.initiationDate = new Date().toISOString().split('T')[0];
            newVals.authorizationId = props.id;
                    
            // update candidate services if set to true
            if (candidateServices && candidateServices.length !== 0) {
              const candidateResp = await props.updateCandidateServices(newVals);
              if (candidateResp.type === UPDATE_CANDIDATE_SERVICES_FAILURE) {
                props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
                setIsLoading(false);
                return false;
              } else { 
                setIsLoading(false);
                return true; 
              }
            } else {
              const candidateResp = await props.postCandidateServices(newVals);
              if (candidateResp.type === POST_CANDIDATE_SERVICES_FAILURE) {
                props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
                setIsLoading(false);
                return false;
              } else { 
                setIsLoading(false);
                return true; 
              }
            }
          }
          else {
            let newVals = { ...internServices };
            newVals.status = 'Initiated';
            newVals.initiationDate = new Date().toISOString().split('T')[0];
            newVals.authorizationId = props.id;
                    
            // update candidate services if set to true
            if (internServices && internServices.length !== 0) {
              const internResp = await props.updateInternServices(newVals);
              if (internResp.type === UPDATE_INTERN_SERVICES_FAILURE) {
                props.showToast('Failed to update the intern services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
                setIsLoading(false);
                return false;
              } else { 
                setIsLoading(false);
                return true; 
              }
            } else {
              const internResp = await props.postInternServices(newVals);
              if (internResp.type === POST_INTERN_SERVICES_FAILURE) {
                props.showToast('Failed to update the intern services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
                setIsLoading(false);
                return false;
              } else { 
                setIsLoading(false);
                return true; 
              }
            }
          }
                            
        }
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <ServicesEditableCard
      status={status}
      webAccess={webAccess}
      servicesInfo={authServices}
      homesaleService={homesaleService}
      globalService={globalService}
      householdGoodsService={householdGoodsService}
      numColumns={4} 
      updateFields={updateFields}
      isLoading={isLoading}
    />
  );

};

export default compose(
  connect(null, {
    postServices,
    updateServices,
    postAuthDestinationServices,
    updateAuthDestinationServices,
    postCandidateServices,
    updateCandidateServices,
    postInternServices,
    updateInternServices,
    showToast,
  }),
)(ServicesCard);
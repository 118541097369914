import { Box, Button } from '@mui/material';
import { TemporaryLivingStatus } from '../TempLivingConstants';
import { createCompleteAuthorizationStepRequest } from '../Utils/tempLivingRequestUtils';
import { showCompleteButton } from '../Utils/tempLivingUtils';
import { useDeleteFormHandler, useFetchDataHandler, useUpdateOrCreateFormHandler } from '../useFormHandler';
import React, { useState } from 'react';
import TempLivingForms from './tempLivingForms.component';
import TemporaryLivingAssignmentSelectionComponent from './tempLivingAssignmentSelection.component';
import ServiceTriggeredComment from '../../commentsAndNotes/serviceTriggeredComment.component';
import SlidingCommentsAndNotes from '../../commentsAndNotes/commentsAndNotesSlidingPanel.component';

const TempLivingButtons = ({ onClickEvent, text, hoverColor, isDisabled, ...rest }) => (
  <Button
    onClick={onClickEvent}
    variant="contained"
    disabled={isDisabled}
    sx={{
      borderRadius: '25px',
      padding: '6px 16px',
      backgroundColor: 'gray',
      color: 'white',
      '&:hover': {
        backgroundColor: hoverColor
      },
      ...rest
    }}
  >
    {text}
  </Button>
);

const TempLivingHeader = ({
  showToast,
  setIsStatusCompleteLoading,
  isReadOnly,
  selectedTempLivingAssignment,
  setSelectedTempLivingAssignment,
  temporaryLivingAssignments,
  authId,
  showComment,
  setShowComment
}) => {
  const handleFetchData = useFetchDataHandler(showToast);
  const handleUpdateFormSubmission = useUpdateOrCreateFormHandler(showToast);
  const handleDeleteAssignment = useDeleteFormHandler(showToast);
  const [showForm, setShowForm] = useState(false);
  const [showTriggeredComment, setShowTriggeredComment] = useState(false);

  const onClickCompleteStatus = async status => {
    setIsStatusCompleteLoading(true);
    const request = createCompleteAuthorizationStepRequest(selectedTempLivingAssignment, status);
    await handleUpdateFormSubmission(request);
    setIsStatusCompleteLoading(false);
    if (request.activeStatus === 'MovedOut') {
      setShowTriggeredComment(true);
    }
  };

  const onClickCreateNewAssignment = () => {
    setShowForm(true);
  };

  const onClickDeleteAssignment = async () => {
    if (authId && temporaryLivingAssignments && selectedTempLivingAssignment?.temporaryLivingId) {
      await handleDeleteAssignment(authId, selectedTempLivingAssignment.temporaryLivingId);
      await handleFetchData(authId);
    }
  };

  const currentStatusConfig = TemporaryLivingStatus[selectedTempLivingAssignment?.activeStatus];

  return (
    <>
      {showForm ? (
        <div style={{ display: 'none' }}>
          <TempLivingForms
            authId={authId}
            showToast={showToast}
            isNewForm={true}
            onCloseNewForm={() => setShowForm(false)}
            setSelectedTempLivingAssignment={setSelectedTempLivingAssignment}
            selectedTempLivingAssignment={selectedTempLivingAssignment}
          />
        </div>
      ) : null}
      <Box
        sx={{
          position: 'sticky',
          top: 64,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px',
          boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
          zIndex: !isReadOnly ? 1000 : 0,
          backgroundColor: 'white'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          <TemporaryLivingAssignmentSelectionComponent
            selectedTempLivingAssignment={selectedTempLivingAssignment}
            setSelectedTempLivingAssignment={setSelectedTempLivingAssignment}
            temporaryLivingAssignments={temporaryLivingAssignments}
          />
          <TempLivingButtons
            onClickEvent={() => onClickCreateNewAssignment()}
            text="New Assignment"
            hoverColor="#007b55"
            backgroundColor="#67a783"
            isDisabled={isReadOnly}
          />
          <TempLivingButtons
            onClickEvent={() => onClickDeleteAssignment()}
            text="Delete Selected Assignment"
            hoverColor="#474747"
            backgroundColor="#868686"
            isDisabled={(temporaryLivingAssignments?.length ?? 0) === 0 || isReadOnly}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          {currentStatusConfig && showCompleteButton(selectedTempLivingAssignment, currentStatusConfig) && (
            <TempLivingButtons
              onClickEvent={() => onClickCompleteStatus(currentStatusConfig)}
              text={currentStatusConfig.buttonText}
              backgroundColor="#41B7C0"
              hoverColor="#279098"
            />
          )}
          <TempLivingButtons
            onClickEvent={() => console.log('Set On Hold')}
            text="Set On Hold"
            hoverColor="#707070"
            isDisabled={isReadOnly}
          />
          <TempLivingButtons
            onClickEvent={() => console.log('Cancel Service')}
            text="Cancel Service"
            hoverColor="#707070"
            isDisabled={isReadOnly}
          />
          <Button
            onClick={() => setShowComment(true)}
            variant="contained"
            sx={{
              backgroundColor: '#41B7C0',
              borderRadius: '25px',
              padding: '6px 16px',
              color: 'white',
              '&:hover': { backgroundColor: '#3d959c' }
            }}
          >
            Comments
          </Button>
        </Box>
      </Box>
      {showTriggeredComment && (
        <ServiceTriggeredComment
          authorizationId={authId}
          serviceOptionId={13}
          displayText={'Temporary Living - Service Completed Comment'}
          setShowTriggeredComment={setShowTriggeredComment}
        />
      )}
      {showComment && (
        <SlidingCommentsAndNotes authorizationId={authId} serviceOptionId={13} setShowComment={setShowComment} isOpen={true} />
      )}
    </>
  );
};

export default TempLivingHeader;

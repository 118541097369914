import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import CommentsAndNotesView from './commentsAndNotesView.container';

const SlidingCommentsAndNotes = ({ isOpen = false, ...props }) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  const handleClose = () => {
    setIsVisible(false);
    props.setShowComment(false);
  };

  const panelStyles = {
    position: 'fixed',
    top: 0,
    right: isVisible ? 0 : '-50%',
    width: '50%',
    height: '100%',
    backgroundColor: 'white',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    zIndex: 1300,
    transition: 'right 0.3s ease-in-out',
    display: 'flex',
    flexDirection: 'column'
  };

  const arrowButtonStyles = {
    position: 'absolute',
    top: '50%',
    left: '-25px',
    transform: 'translateY(-50%)',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
    borderRadius: '50%',
    zIndex: 1400,
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#41B7C0',
    '&:hover': {
      backgroundColor: '#3d959c'
    }
  };

  const arrowIconStyles = {
    fontSize: '2rem',
    color: '#000'
  };

  return (
    <Box sx={panelStyles}>
      <IconButton onClick={handleClose} sx={arrowButtonStyles} aria-label="close">
        <ArrowForwardIos sx={arrowIconStyles} />
      </IconButton>
      <Box
        sx={{
          flex: 1,
          width: '100%',
          height: '100%',
          overflow: 'auto'
        }}
      >
        <CommentsAndNotesView id={props.authorizationId} {...props} viewMode="halfView" />
      </Box>
    </Box>
  );
};

export default SlidingCommentsAndNotes;

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import Spinner from 'modules/common/spinner.component';
import { Box, Card, CardContent, Checkbox, Tooltip, Typography } from '@mui/material';
import CrudTable from 'modules/clients/details/crudTable.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useState } from 'react';
import { DELETE_VENDOR_BANKING_INFO_FAILURE, GET_VENDOR_BANKING_INFO_BY_VENDOR_ID_FAILURE, POST_VENDOR_BANKING_INFO_FAILURE, UPDATE_VENDOR_BANKING_INFO_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';
import { updateVendorBankingInfo, postVendorBankingInfo, deleteVendorBankingInfo, getVendorBanksByVendorId } from 'modules/authorizations/store/actions/dataManagement.actions';

const VendorBankingInfoCard = props => {
  const [isLoading, setIsLoading] = useState(false);

  const { vendorBanks, vendorId, isReadOnly } = props;

  const accountTypes = ['Checking', 'Savings'];
  const statusTypes = ['Active', 'Inactive'];
  const currencyOptions = ['$ USD', '£ British Pound', '$ CAD', '¥ Chinese Yuan', '€ Euros', '₹ Indian Rupee', '¥ Japanese Yen', '₣ Swiss Franc' ];

  const bankingSummaries = [
    {
      header: 'Status',
      accessorKey: 'bankingStatus',
      type: 'dropdown',
      options: statusTypes,
      required: false,
      tab: 1,
      size: 35,
      enableColumnDragging: false,
      enableColumnActions: false,
      enableResizing: false
    },
    {
      header: 'Account Type',
      accessorKey: 'accountType',
      type: 'dropdown',
      options: accountTypes,
      required: false,
      tab: 1,
      size: 35,
      enableColumnDragging: false,
      enableColumnActions: false,
      enableResizing: false
    },
    {
      header: 'Account Location',
      accessorKey: 'accountLocation',
      type: 'text',
      required: false,
      tab: 1,
      size: 100,
      enableColumnDragging: false,
      enableColumnActions: false,
      enableResizing: false
    },
    {
      header: 'Bank Name',
      accessorKey: 'bankName',
      type: 'text',
      required: false,
      tab: 1,
      size: 100,
      enableColumnDragging: false,
      enableColumnActions: false,
      enableResizing: false
    },
    {
      header: 'City',
      accessorKey: 'city',
      type: 'text',
      required: false,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'State',
      accessorKey: 'state',
      type: 'text',
      required: false,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Account Holder Name',
      accessorKey: 'accountHolderName',
      type: 'text',
      required: false,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Account Holder Address',
      accessorKey: 'accountHolderAddress',
      type: 'text',
      required: false,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Email',
      accessorKey: 'email',
      type: 'text',
      required: false,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Routing Number',
      accessorKey: 'routingNumber',
      type: 'text',
      required: false,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Account Number',
      accessorKey: 'accountNumber',
      type: 'text',
      required: false,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Currency Code',
      accessorKey: 'currencyCode',
      type: 'dropdown',
      options: currencyOptions,
      required: false,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Payment Code',
      accessorKey: 'paymentCode',
      type: 'text',
      required: false,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Transit Code',
      accessorKey: 'transitCode',
      type: 'text',
      required: false,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'IBAN',
      accessorKey: 'iban',
      type: 'text',
      required: false,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Swift Code',
      accessorKey: 'swiftCode',
      type: 'text',
      required: false,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Cuit Code',
      accessorKey: 'cuitCode',
      type: 'text',
      required: false,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Clabe',
      accessorKey: 'clabe',
      type: 'text',
      required: false,
      tab: 1,
      hideInTable: true,
    },
    {
      header: 'Institution Number',
      accessorKey: 'institutionNumber',
      type: 'text',
      required: false,
      tab: 1,
      hideInTable: true,
    },
    { 
      header: 'Default Account', 
      accessorKey: 'defaultAccount', 
      type: 'checkbox', 
      required: false,
      tab: 1, 
      Cell: ({ cell }) => <Checkbox name="defaultAccount" label="Active" checked={cell.row.original.defaultAccount} disabled={false} />,
      hideInTable: true,
    },
    { 
      header: 'Used For Wire', 
      accessorKey: 'usedForWire', 
      type: 'checkbox', 
      required: false,
      tab: 1, 
      Cell: ({ cell }) => <Checkbox name="usedForWire" label="Active" checked={cell.row.original.usedForWire} disabled={false} />,
      hideInTable: true,
    },
    { 
      header: 'Used For Ach', 
      accessorKey: 'usedForAch', 
      type: 'checkbox', 
      required: false,
      tab: 1, 
      Cell: ({ cell }) => <Checkbox name="usedForAch" label="Active" checked={cell.row.original.usedForAch} disabled={false} />,
      hideInTable: true,
    },
    { 
      header: 'Intermediary Required', 
      accessorKey: 'intermediaryRequired', 
      type: 'checkbox', 
      required: false,
      tab: 1, 
      Cell: ({ cell }) => <Checkbox name="intermediaryRequired" label="Active" checked={cell.row.original.intermediaryRequired} disabled={false} />,
      hideInTable: true,
    },
    {
      header: 'Special Instructions',
      accessorKey: 'specialInstructions',
      type: 'text',
      required: false,
      tab: 1,
      hideInTable: true,
    }
  ];

  const updateBank = async (record, values) => {
      let tempVal = {...values};
      tempVal.bankId = record.original.bankId;
      tempVal.vendorId = vendorId;

      if(tempVal.defaultAccount === "" || tempVal.defaultAccount === null)
        {
          tempVal.defaultAccount = false;
        }
        if(tempVal.usedForWire === "" || tempVal.usedForWire === null)
        {
          tempVal.usedForWire = false;
        }
        if(tempVal.usedForAch === "" || tempVal.usedForAch === null)
        {
          tempVal.usedForAch = false;
        }
        if(tempVal.intermediaryRequired === "" || tempVal.intermediaryRequired === null)
        {
          tempVal.intermediaryRequired = false;
        }

      setIsLoading(true);
      const resp = await props.updateVendorBankingInfo(tempVal);
      if (resp.type === UPDATE_VENDOR_BANKING_INFO_FAILURE) {
        setIsLoading(false);
        return false;
      } else {
        const getResp = await props.getVendorBanksByVendorId(vendorId);
        setIsLoading(false);
        if (getResp.type === GET_VENDOR_BANKING_INFO_BY_VENDOR_ID_FAILURE) {
          return false;
        } else {
          return true;
        }
      }
    };
  
    const createBank = async values => {
      let tempVal = {...values};
      tempVal.vendorId = vendorId;

      if(tempVal.defaultAccount === "" || tempVal.defaultAccount === null)
        {
          tempVal.defaultAccount = false;
        }
        if(tempVal.usedForWire === "" || tempVal.usedForWire === null)
        {
          tempVal.usedForWire = false;
        }
        if(tempVal.usedForAch === "" || tempVal.usedForAch === null)
        {
          tempVal.usedForAch = false;
        }
        if(tempVal.intermediaryRequired === "" || tempVal.intermediaryRequired === null)
        {
          tempVal.intermediaryRequired = false;
        }

      setIsLoading(true);
      const resp = await props.postVendorBankingInfo(tempVal);
      if (resp.type === POST_VENDOR_BANKING_INFO_FAILURE) {
        setIsLoading(false);
        return false;
      } else {
        const getResp = await props.getVendorBanksByVendorId(vendorId);
        setIsLoading(false);
        if (getResp.type === GET_VENDOR_BANKING_INFO_BY_VENDOR_ID_FAILURE) {
          return false;
        } else {
          return true;
        }
      }
    };
  
    const deleteBank = async (record) => {
        setIsLoading(true);
        const id = record.original.bankId;
        const resp = await props.deleteVendorBankingInfo(id);
        if (resp.type === DELETE_VENDOR_BANKING_INFO_FAILURE) {
          setIsLoading(false);
          return false;
        } else {
          const getResp = await props.getVendorBanksByVendorId(vendorId);
          if (getResp.type === GET_VENDOR_BANKING_INFO_BY_VENDOR_ID_FAILURE) {
            setIsLoading(false);
            return false;
          } else {
            setIsLoading(false);
            return true;
          }
        }
      };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip title={'File Contract'} key={'vendorBanks'} placement="right">
            <FontAwesomeIcon icon={['fas', 'file-contract']} size="2x" />
          </Tooltip>
          <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
            Banking
          </Typography>
        </Box>
        {!isLoading && (
          <CrudTable 
            editingMode={'modal'} // Can be changed to 'row' to edit inline 
            columns={bankingSummaries} 
            rows={vendorBanks ? vendorBanks : []} 
            updateRow={updateBank} 
            createRow={createBank} 
            deleteRow={deleteBank} 
            customAddText={'Add Bank'}
            hideEdit={isReadOnly}
          />
        )}
        {isLoading && (
          <Spinner />
        )}
      </CardContent>
    </Card>
  );
};

export default compose(
  connect(null, {
    updateVendorBankingInfo,
    postVendorBankingInfo,
    deleteVendorBankingInfo,
    getVendorBanksByVendorId,
    showToast
  })
)(VendorBankingInfoCard);

/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { formatDateForInput } from 'utilities/common';
import { getMonth } from './utils';
import { makeStyles } from '@material-ui/styles';
import { showToast } from 'modules/layout/layout.actions';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Spinner from './spinner.component';

import AdvancedSearch from 'modules/common/advancedSearch.component';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  chipClickable: {
    cursor: 'pointer'
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important'
  },
  spacingX: {
    marginRight: theme.spacing(1)
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2)
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px'
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important'
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important'
  },
  editModalInput: {
    minWidth: '332px'
  },
  dialogContentContainer: {
    margin: '15px'
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0
  }
}));

/*
    - Required Props: 
     * tabs - a list of tabs to be included in the card
     * summaries - a list of objects detailing the specific textfield requirements for the editing feature as well as the necessary values to display
     * numColumns - number of xl device columns
    - Optional Props: 
     * updateFields (typically an API PUT call followed by GET call to refresh the data, returns boolean) 

    - Required Label Fields: accessorKey, header, type, options (only required for dropdown type)
    - Optional Label Fields: required (defaults to false), size, enableColumnOrdering, enableEditing, enableSorting, hideInTable (if column should be displayed in the CRUD table or not)

    - Label Types: text, notes, number, date, dropdown, dropdownv2

    Tabs are displayed in the order they appear in the list. If there is only one item in the list, then it will be displayed as a 'header' (not clickable, no other visible tabs) and all items in the summaries list will be displayed and editable by default. If within the summaries list
    there is an item with a 'tab' option of a different value than the 'order' in the single item within the tabs list. (Example - there is an item in the summaries list with a 'tab' value of 3. There is only one item in the tabs list. That item will still be displayed within the card.)

    const tabs = [
	    { accessorKey: 'nameOfTabMatchingInSummaries', header: 'displayTitleOfTab', order: 1, iconPath: ['fas', 'user-friends'] },
    ];

    The editable card is largely based off of the CRUD table functionality. An example of a label input is:
    const summaries = [
        { header: 'Text Example', type: 'text', required: true, size: 72 },
        { header: 'Dropdown Example', type: 'dropdown', options: ['Yes', 'No'], required: true, size: 68 },
        { header: 'Dropdown Version 2 Example', type: 'dropdown-v2', options: arrayOfOptions, defaultVal: 0, required: true, Cell: ({cell}) => cell.fieldValues.original.dropdownV2Example != null && <p>{dropdownV2Exampoe.find(o => o.id === cell.fieldValues.dropdownV2Example).title}</p>} ,
        { header: 'Checkbox Example', type: 'checkbox', defaultVal: false, required: true, Cell: ({cell}) => <Checkbox name="checkboxExample" label="Active" checked=(cell.fieldValues.original.checkboxExample} disabled={false} },
        { header: 'Date Example', type: 'date', required: true, size: 72 },
        { header: 'Notes Example', type: 'notes', required: true},
    ]

    Included in the summaries array, you need a VALUE that contains the proper value you want displayed. An example is that fields = authorizationInformation. Values will then be gathered by the accessor key in the summaries array:

          We want to display the values for an authorization's ID and Client:
          const summaries = [
            { header: 'File #', value: this.props.authorizationInformation.mtAuthorizationId, type: 'text', required: true, disabled: true },
            { header: 'Client', value: this.props.authorizationInformation.client.description, type: 'text', required: true, disabled: true },
          ]

    The updateFields props is a FUNCTION for updating the fields via the editing modal. Here is an example of how to write an updateFields function:
    
    const updateAuthorizationInformation = async (authorizationInformation, values) => {
      const id = authorizationInformation.mtAuthorizationId;
      values.mtAuthorizationId = id;
      values.clientId = authorizationInformation.clients.clientId;
      const resp = await props.updateAuthorizationInformation(values);
      if (resp.type === UPDATE_AUTHORIZATION_INFORMATION_FAILURE) {
        return false;
      } else {
      const getResp = await props.getAuthorizationInformation(authorizationInformation.clients.clientId);
      if (getResp.type === GET_AUTHORIZATION_INFORMATION_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

*/

const EditableCard = props => {
  const {
    tabs,
    summaries,
    numColumns,
    updateFields,
    isLoading,
    topLink,
    smallerModal,
    openModel,
    hideEdit,
    onCancelProp,
    ButtonComponent,
    openCurrentTab = 1,
    showButtonComponentFunc = null
  } = props;
  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const [editingModalOpen, setEditingModalOpen] = useState(openModel ?? false);
  const [validationErrors, setValidationErrors] = useState({});
  const [currentTab, setCurrentTab] = useState(1);

  useEffect(() => {
    setCurrentTab(openCurrentTab);
  }, [openCurrentTab]);

  let onlyOneTab = false;
  if (tabs.length === 1) {
    onlyOneTab = true;
  }

  const showButtonComponent = () => {
    if (showButtonComponentFunc) {
      return showButtonComponentFunc(currentTab);
    }
    return true;
  };

  const handleSaveEdits = async (authId, values) => {
    if (!Object.keys(validationErrors).length) {
      // Send update call to parent to update and refresh values.
      if (updateFields) {
        const resp = await updateFields(authId, values);
        if (!resp) {
          showToast('Failed to update card data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        } else {
          setEditingModalOpen(false);
        }
      }
      setEditingModalOpen(false); //required to exit editing mode and close modal
    }
  };

  const handleCancelEdits = () => {
    setValidationErrors({});
    setEditingModalOpen(false); //required to exit editing mode and close modal
    if (onCancelProp) {
      onCancelProp();
    }
  };

  const renderTab = tab => {
    return (
      <Tab
        icon={
          <Box sx={{ paddingRight: '10px' }}>
            <FontAwesomeIcon icon={tab.iconPath} size="1x" />
          </Box>
        }
        key={tab.order}
        iconPosition={'start'}
        value={tab.order}
        label={tab.header}
        disabled={tab?.isDisabled ?? false}
        sx={{
          zIndex: 1,
          minWidth: '265px',
          minHeight: '0px',
          textTransform: 'none',
          fontSize: '1.25rem',
          fontWeight: 'bold',
          color: 'black'
        }}
      />
    );
  };

  const renderHeader = header => {
    if (header?.multiTabComponent) {
      return (
        <div className="row justify-space-between align-center" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
            <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
          </Fab>
        </div>
      );
    }

    return (
      <div className="row justify-space-between align-center">
        <div className={classes.iconRow}>
          {header.iconPath && (
            <Tooltip title={header.header} key={header.header} placement="right">
              <FontAwesomeIcon icon={header.iconPath} size="2x" />
            </Tooltip>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              paddingLeft: header.iconPath ? '20px' : '0px'
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: '1.3125rem',
                fontWeight: 'bold'
              }}
            >
              {header.header}
            </Typography>
            {header.headerMessage && (
              <Typography
                variant="h5"
                sx={{
                  fontSize: '0.875rem',
                  color: 'green'
                }}
              >
                {header.headerMessage}
              </Typography>
            )}
          </Box>
        </div>
        {!hideEdit && (
          <>
            {!props.removeButton && (
              <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
              </Fab>
            )}
            {props.removeButton && (
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ marginRight: '10px' }}>
                  <Fab className={classes.iconOverlayImg} color="gray" size="small" onClick={props.removeButtonAction}>
                    <FontAwesomeIcon size="1x" icon={['fas', 'xmark']} color={theme.palette.common.white} />
                  </Fab>
                </Box>
                <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                  <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                </Fab>
              </Box>
            )}
          </>
        )}
      </div>
    );
  };

  const renderTextBelow = opt => {
    return (
      <Grid item>
        <Box>
          <div className={classes.mb2}>
            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
              {opt.header}
            </Typography>
          </div>
          <div className={classes.mb2}>
            <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
              {opt.value ? opt.value : <div>&mdash;</div>}
            </Typography>
          </div>
        </Box>
      </Grid>
    );
  };

  const renderValue = opt => {
    if (opt.type === 'date') {
      return opt.value ? new Date(opt.value).toLocaleDateString('en-us') : <div>&mdash;</div>;
    } else if (opt.type === 'datetime') {
      if (opt.value) {
        let date = new Date(opt.value);
        const formatter = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' });
        return `${getMonth(date.getMonth())} ${date.getDate()} ${date.getFullYear()} ${formatter.format(date)}`;
      } else {
        return <div>&mdash;</div>;
      }
    } else if (opt.type === 'dropdown-v2') {
      return opt.value ? opt.options.find(o => o.id === opt.value).title : <div>&mdash;</div>;
    } else if (opt.type === 'money') {
      return opt.value ? `$${opt.value}` : <div>&mdash;</div>;
    } else if (opt.type === 'advanced-search') {
      return opt.nameValue ? opt.nameValue : <div>&mdash;</div>;
    } else if (opt.type === 'multi-select') {
      return opt.value && Array.isArray(opt.value) && opt.value.length > 0 ? (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '4px'
          }}
        >
          {opt.value.map((value, index) => (
            <Chip key={index} label={value} sx={{ margin: '2px' }} />
          ))}
        </div>
      ) : (
        <div>&mdash;</div>
      );
    } else if (opt.type === 'file') {
      return opt.value ? (
        <button
          type="button"
          style={{
            background: 'none',
            border: 'none',
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer',
            padding: 0
          }}
          onClick={e => {
            e.preventDefault();
            opt.fetchFile(opt.value.id);
          }}
        >
          {opt.value?.file?.name || 'Download File'}
        </button>
      ) : (
        <div>&mdash;</div>
      );
    } else {
      return opt.value ? opt.value : <div>&mdash;</div>;
    }
  };

  const renderOption = opt => {
    let isEven = numColumns % 2 === 0;
    let colSpan;
    if (opt.type === 'multi-select' && Array.isArray(opt.value)) {
      const count = opt.value.length;
      if (count > 6) {
        colSpan = 12;
      } else if (count > 4) {
        colSpan = 6;
      } else if (count > 2) {
        colSpan = 4;
      }
    }

    if (onlyOneTab) {
      if (opt.showBelow) {
        return renderTextBelow(opt);
      } else if (opt.dividerLine) {
        return (
          <Box sx={{ width: '100%' }}>
            <hr />
          </Box>
        );
      } else {
        return (
          <>
            {opt.type === 'multi-select' && Array.isArray(opt.value) && opt.value.length > 2 ? (
              <Grid item xs={colSpan} sm={colSpan} md={colSpan} xl={colSpan}>
                {opt.showFloatingIcon && (
                  <Box sx={{ position: 'absolute' }}>
                    <Box sx={{ marginLeft: '110px' }}>{opt.floatingIcon()}</Box>
                  </Box>
                )}
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                      {opt.header}
                    </Typography>
                  </div>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                      {opt.underline ? <Link>{renderValue(opt)}</Link> : renderValue(opt)}
                    </Typography>
                  </div>
                  {opt.lineBelow && <hr />}
                </Box>
              </Grid>
            ) : (
              <Grid item xs={isEven ? numColumns / 2 : numColumns} sm={isEven ? 1 : 2} md={isEven ? 1 : 2} xl={isEven ? 1 : 2}>
                {opt.showFloatingIcon && (
                  <Box sx={{ position: 'absolute' }}>
                    <Box sx={{ marginLeft: '110px' }}>{opt.floatingIcon()}</Box>
                  </Box>
                )}
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                      {opt.header}
                    </Typography>
                  </div>
                  {opt.type === 'checkbox' && (
                    <div>
                      <FormControl sx={{ marginTop: '-10px', marginBottom: '1px' }}>
                        <FormControlLabel
                          key={opt.accessorKey}
                          name={opt.accessorKey}
                          control={<Checkbox />}
                          checked={opt.value}
                          disabled={true}
                          variant="standard"
                          margin="dense"
                        />
                      </FormControl>
                    </div>
                  )}
                  {!(opt.type === 'checkbox') && (
                    <div className={classes.mb2}>
                      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                        {opt.underline ? <Link>{renderValue(opt)}</Link> : renderValue(opt)}
                      </Typography>
                    </div>
                  )}

                  {opt.lineBelow && <hr />}
                </Box>
              </Grid>
            )}
          </>
        );
      }
    } else {
      if (opt.tab === currentTab) {
        if (opt.showBelow) {
          return renderTextBelow(opt);
        } else if (opt.dividerLine) {
          return (
            <Box sx={{ width: '100%' }}>
              <hr />
            </Box>
          );
        } else {
          return (
            <>
              {opt.type === 'multi-select' && Array.isArray(opt.value) && opt.value.length > 2 ? (
                <Grid item xs={colSpan} sm={colSpan} md={colSpan} xl={colSpan}>
                  {opt.showFloatingIcon && (
                    <Box sx={{ position: 'absolute' }}>
                      <Box sx={{ marginLeft: '110px' }}>{opt.floatingIcon()}</Box>
                    </Box>
                  )}
                  <Box>
                    <div className={classes.mb2}>
                      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                        {opt.header}
                      </Typography>
                    </div>
                    <div className={classes.mb2}>
                      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                        {opt.underline ? <Link>{renderValue(opt)}</Link> : renderValue(opt)}
                      </Typography>
                    </div>
                    {opt.lineBelow && <hr />}
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={isEven ? numColumns / 2 : numColumns} sm={isEven ? 1 : 2} md={isEven ? 1 : 2} xl={isEven ? 1 : 2}>
                  <Box>
                    <div className={classes.mb2}>
                      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400 }}>
                        {opt.header}
                      </Typography>
                    </div>
                    {opt.type === 'checkbox' && (
                      <div>
                        <FormControl sx={{ marginTop: '-10px', marginBottom: '1px' }}>
                          <FormControlLabel
                            key={opt.accessorKey}
                            name={opt.accessorKey}
                            control={<Checkbox />}
                            checked={opt.value}
                            disabled={true}
                            variant="standard"
                            margin="dense"
                          />
                        </FormControl>
                      </div>
                    )}
                    {!(opt.type === 'checkbox') && (
                      <div className={classes.mb2}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500 }}>
                          {opt.underline ? <Link>{renderValue(opt)}</Link> : renderValue(opt)}
                        </Typography>
                      </div>
                    )}
                    {opt.lineBelow && <hr />}
                  </Box>
                </Grid>
              )}
            </>
          );
        }
      }
    }
  };

  const handleChangeTab = tab => {
    setCurrentTab(tab);
  };

  let numTotalColumns = numColumns % 2 === 0 ? numColumns : numColumns * 2;

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ position: 'relative' }}>
        <Box>
          <CardContent className={classes.cardContainer}>
            {onlyOneTab && <>{tabs.map(renderHeader)}</>}
            {!onlyOneTab && (
              <>
                <div className="row justify-space-between align-center">
                  <Tabs variant="fullWidth" value={currentTab} onChange={(event, tab) => handleChangeTab(tab)} style={{ flex: 0.9 }}>
                    {tabs.map(renderTab)}
                  </Tabs>
                  {!hideEdit && (
                    <>
                      <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                        <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                      </Fab>
                    </>
                  )}
                </div>
              </>
            )}
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            {topLink && topLink.tab === currentTab && (
              <Box sx={{ marginBottom: '18px', zIndex: 100 }}>
                <Link to={topLink.link}>({topLink.title})</Link>
              </Box>
            )}
            <Grid container spacing={1} columns={{ xs: numTotalColumns, sm: numTotalColumns, md: numTotalColumns, xl: numTotalColumns }}>
              {summaries.map(opts => !opts.hideInTable && renderOption(opts))}
            </Grid>
            {ButtonComponent && showButtonComponent() && <ButtonComponent />}
          </CardContent>
        </Box>
      </Card>
      {editingModalOpen && (
        <EditCardModal
          fieldSummaries={summaries}
          classes={classes}
          tab={tabs[currentTab - 1]}
          oneTab={onlyOneTab}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={handleCancelEdits}
          onSubmit={handleSaveEdits}
          smallerModal={smallerModal}
        />
      )}
    </ThemeProvider>
  );
};
EditableCard.propTypes = {
  tabs: PropTypes.array.isRequired,
  summaries: PropTypes.array.isRequired,
  updateFields: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired
};
export default connect(null, { showToast })(EditableCard);

export const EditCardModal = ({ open, classes, tab, fieldSummaries, oneTab, onClose, onSubmit, isLoading, smallerModal }) => {
  const [values, setValues] = useState(() =>
    fieldSummaries.reduce((acc, summary) => {
      if (summary.tab === tab.order) {
        acc[summary.accessorKey ?? ''] = summary.value;
        if (summary.nameKey) acc[summary.nameKey] = summary.nameValue;
      }
      return acc;
    }, {})
  );

  const [isDirty, setIsDirty] = useState(false);

  const checkDirty = (summaries, values) => {
    let dirty = false;
    summaries.map(s => {
      // Checks the datetime cases for equivalence which have inconsistent format
      if (
        typeof s.value === 'string' &&
        typeof values[s.accessorKey] === 'string' &&
        s.value.includes('T00:00:00') &&
        !values[s.accessorKey].includes('T00:00:00')
      ) {
        if (s.value !== `${values[s.accessorKey]}T00:00:00`) dirty = true;
      }
      // Checks case where new and old values are different (ignores case where one is undefined and one is empty string)
      else if (s.value !== values[s.accessorKey] && !(s.value == null && values[s.accessorKey]?.length === 0)) dirty = true;
    });
    setIsDirty(dirty);
  };

  useEffect(() => {
    checkDirty(fieldSummaries, values);
  }, [fieldSummaries, values]);

  const renderInput = summary => {
    if (summary.type === 'text' && !summary.hideInModal) {
      return (
        <div>
          <TextField
            key={summary.accessorKey}
            label={summary.header}
            name={summary.accessorKey}
            required={summary.required}
            value={values[summary.accessorKey]}
            disabled={summary.disabled}
            margin="dense"
            variant="standard"
            sx={{ maxWidth: '360px' }}
            onChange={e => {
              setValues({ ...values, [e.target.name]: e.target.value });
            }}
          />
          {summary.required && (
            <>
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </>
          )}
        </div>
      );
    }
    if (summary.type === 'notes' && !summary.hideInModal) {
      return (
        <div>
          <TextField
            key={summary.accessorKey}
            label={summary.header}
            name={summary.accessorKey}
            required={summary.required}
            value={values[summary.accessorKey]}
            disabled={summary.disabled}
            margin="dense"
            multiline
            variant="standard"
            rows={4}
            sx={{ width: '1100px' }}
            onChange={e => setValues({ ...values, [e.target.name]: e.target.value })}
          />
          {summary.required && (
            <>
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </>
          )}
        </div>
      );
    }
    if (summary.type === 'number' && !summary.hideInModal) {
      return (
        <div>
          <TextField
            key={summary.accessorKey}
            label={summary.header}
            name={summary.accessorKey}
            required={summary.required}
            value={values[summary.accessorKey]}
            margin="dense"
            disabled={summary.disabled}
            sx={{ maxWidth: '360px' }}
            variant="standard"
            type="number"
            onChange={e => setValues({ ...values, [e.target.name]: e.target.value })}
          />
          {summary.required && (
            <>
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </>
          )}
        </div>
      );
    }
    if (summary.type === 'decimal' && !summary.hideInModal) {
      return (
        <div>
          <TextField
            key={summary.accessorKey}
            label={summary.header}
            name={summary.accessorKey}
            required={summary.required}
            value={values[summary.accessorKey]}
            margin="dense"
            disabled={summary.disabled}
            sx={{ maxWidth: '360px' }}
            variant="standard"
            type="number"
            allowDecimal={true}
            onChange={e => setValues({ ...values, [e.target.name]: e.target.value })}
          />
          {summary.required && (
            <>
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </>
          )}
        </div>
      );
    }
    if (summary.type === 'checkbox' && !summary.hideInModal) {
      return (
        <div>
          <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
            <FormControlLabel
              key={summary.accessorKey}
              name={summary.accessorKey}
              label={summary.header}
              labelPlacement="start"
              control={<Checkbox />}
              required={summary.required}
              disabled={summary.disabled}
              checked={values[summary.accessorKey]}
              variant="standard"
              margin="dense"
              sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
              onClick={e => setValues({ ...values, [e.target.name]: e.target.checked })}
            />
          </FormControl>
          {summary.required && (
            <>
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </>
          )}
        </div>
      );
    }
    if (summary.type === 'dropdown' && !summary.hideInModal) {
      return (
        <div>
          <FormControl sx={{ marginTop: '6px' }}>
            <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={summary.header}>
              {summary.header}
            </InputLabel>
            <Select
              labelId={summary.header}
              key={summary.accessorKey}
              label={summary.header}
              name={summary.accessorKey}
              required={summary.required}
              disabled={summary.disabled}
              value={values[summary.accessorKey]}
              margin="dense"
              variant="standard"
              sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
              onChange={e => setValues({ ...values, [e.target.name]: e.target.value })}
            >
              {summary.options.map(opt => (
                <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                  {opt}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {summary.required && (
            <>
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </>
          )}
        </div>
      );
    }
    if (summary.type === 'dropdown-v2' && !summary.hideInModal) {
      return (
        <div>
          <FormControl sx={{ marginTop: '6px' }}>
            <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={summary.header}>
              {summary.header}
            </InputLabel>
            <Select
              labelId={summary.header}
              key={summary.accessorKey}
              label={summary.header}
              name={summary.accessorKey}
              required={summary.required}
              disabled={summary.disabled}
              value={values[summary.accessorKey]}
              margin="dense"
              variant="standard"
              sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '360px', minWidth: '180px' }}
              onChange={e => setValues({ ...values, [e.target.name]: e.target.value })}
            >
              {summary.options.map(opt => (
                <MenuItem key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                  {opt.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {summary.required && (
            <>
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </>
          )}
        </div>
      );
    }
    if (summary.type === 'date' && !summary.hideInModal) {
      return (
        <div>
          <TextField
            key={summary.accessorKey}
            label={summary.header}
            name={summary.accessorKey}
            required={summary.required}
            disabled={summary.disabled}
            value={formatDateForInput(values[summary.accessorKey])}
            sx={{ maxWidth: '360px' }}
            margin="dense"
            variant="standard"
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={e => setValues({ ...values, [e.target.name]: e.target.value })}
          />
          {summary.required && (
            <>
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </>
          )}
        </div>
      );
    }
    if (summary.type === 'money' && !summary.hideInModal) {
      return (
        <div>
          <TextField
            key={summary.accessorKey}
            label={summary.header}
            name={summary.accessorKey}
            required={summary.required}
            value={values[summary.accessorKey]}
            margin="dense"
            disabled={summary.disabled}
            sx={{ maxWidth: '360px' }}
            variant="standard"
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
            allowDecimal={true}
            onChange={e => setValues({ ...values, [e.target.name]: e.target.value })}
          />
          {summary.required && (
            <>
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </>
          )}
        </div>
      );
    }
    if (summary.type === 'file' && !summary.hideInModal) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <TextField
            key={summary.accessorKey}
            name={summary.accessorKey}
            label={summary.header}
            required={summary.required}
            disabled={summary.disabled}
            margin="dense"
            sx={{ maxWidth: '360px' }}
            variant="standard"
            type="file"
            inputProps={{ accept: summary.accept || '*/*' }}
            onChange={e => {
              const file = e.target.files?.[0];
              setValues({
                ...values,
                [e.target.name]: {
                  id: null,
                  file: file || null
                }
              });
            }}
            helperText={values[summary.accessorKey]?.file?.name ? `${values[summary.accessorKey]?.file?.name} Selected` : ''}
            InputLabelProps={{ shrink: true }}
          />
          <button
            type="button"
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              color: 'red',
              fontSize: '1.2rem'
            }}
            onClick={() =>
              setValues({
                ...values,
                [summary.accessorKey]: { id: null, file: null }
              })
            }
          >
            &#x2715;
          </button>
        </div>
      );
    }

    if (summary.type === 'advanced-search' && !summary.hideInModal) {
      return (
        <AdvancedSearch
          labelText={summary.header}
          displayValueAccessorKey={summary.searchInfo.nameKey}
          saveIdValueAccessorKey={summary.searchInfo.idKey}
          searchingCriteria={summary.searchInfo.searchingColumns}
          searchingCriteriaExtensions={summary.searchInfo.searchingColumnExtensions}
          searchingRows={summary.searchInfo.searchingRows}
          id={values[summary.accessorKey]}
          value={values[summary.nameKey]}
          setIdValue={(id, value) => {
            setValues({ ...values, [summary.nameKey]: value, [summary.accessorKey]: id });
          }}
        />
      );
    }
    if (summary.type === 'multi-select' && !summary.hideInModal) {
      return (
        <div>
          <FormControl sx={{ marginTop: '6px' }}>
            <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={summary.header}>
              {summary.header}
            </InputLabel>
            <Select
              labelId={summary.header}
              key={summary.accessorKey}
              label={summary.header}
              name={summary.accessorKey}
              required={summary.required}
              disabled={summary.disabled}
              value={values[summary.accessorKey]}
              renderValue={selected => (
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '4px'
                  }}
                >
                  {selected.map(value => (
                    <Chip
                      key={value}
                      label={value}
                      onDelete={() => {
                        const updatedValues = values[summary.accessorKey].filter(v => v !== value);
                        setValues({ ...values, [summary.accessorKey]: updatedValues });
                      }}
                      onMouseDown={e => {
                        e.stopPropagation();
                      }}
                    />
                  ))}
                </div>
              )}
              multiple
              margin="dense"
              variant="standard"
              sx={{ marginTop: '6px', marginBottom: '6px', maxWidth: '1000px', minWidth: '360px' }}
              onChange={e => setValues({ ...values, [e.target.name]: e.target.value })}
            >
              {summary.options.map(opt => (
                <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                  {opt}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {summary.required && (
            <>
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </>
          )}
        </div>
      );
    }
  };

  const handleSubmit = e => {
    //put your validation logic here
    // need to add 'modified on / by' logic here
    e.preventDefault();
    onSubmit(tab.order, values);
  };

  const handleCancel = e => {
    //put your validation logic here
    e.preventDefault();
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={smallerModal ? false : true} maxWidth={'lg'}>
      <form onSubmit={handleSubmit} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            {tab.iconPath && (
              <Tooltip title={tab.header} key={tab.header} placement="right">
                <FontAwesomeIcon icon={tab.iconPath} size="3x" />
              </Tooltip>
            )}
            <Typography variant="h5" sx={{ paddingLeft: tab.iconPath ? '20px' : '0px' }}>
              {tab.header}
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem'
            }}
          >
            <Grid container spacing={{ xs: 1, md: 1 }} sx={{ padding: '30px', paddingTop: '0px' }}>
              {fieldSummaries.map((opts, index) => {
                if (!oneTab) {
                  if (opts.tab === tab.order && !opts.hideInModal) {
                    return (
                      <Grid item key={index}>
                        {renderInput(opts)}
                      </Grid>
                    );
                  }
                } else {
                  if (!opts.hideInModal) {
                    return (
                      <Grid item key={index}>
                        {renderInput(opts)}
                      </Grid>
                    );
                  }
                }
              })}
            </Grid>
          </Stack>
        </div>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        )}
        {!isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button color="primary" type="submit" variant="contained" disabled={!isDirty}>
              Save
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS } from 'styles/theme';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import classNames from 'classnames';
import { updateVendor } from 'modules/authorizations/store/actions/dataManagement.actions';
import { UPDATE_VENDORS_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';
import { showToast } from 'modules/layout/layout.actions';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import VendorDetailsCard from './vendorDetailsCard.component';
import VendorTaxesEditableCard from './vendorTaxesEditableCard.component';
import ContactsCard from './contactsCard.component';
import DocumentsCard from './documentsCard.component';
import VendorsBankingInfoCard from './vendorsBankingInfoCard.component';

const styles = theme => ({
  container: {
    height: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`
    }
  },
  headerFixed: {
    zIndex: 4
  },
  estimateHeaderFixed: {
    paddingTop: '115px',
    zIndex: 2
  }
});

const VendorDetails = props => {
  const { vendorDetails, vendorContacts, vendorDocuments, vendorBanks } = props;

  const [isLoading, setIsLoading] = useState(false);

  const updateStatus = async () => {
    setIsLoading(true);
    let newStatus = vendorDetails?.status === 'Active' ? 'Inactive' : 'Active';
    let request = { ...vendorDetails, status: newStatus, statusLastUpdated: new Date().toISOString() };
    let response = await props.updateVendor(request);
    if (response.type === UPDATE_VENDORS_FAILURE) {
      props.showToast('Failed to update vendor, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
    setIsLoading(false);
  };

  return (
    <>
      {!isLoading && (
        <Box>
          <Paper square className={classNames('position-fixed', props.classes.headerFixed, props.classes.container)}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', width: '100%' }}>
              <Box sx={{ paddingLeft: '100px' }}>&nbsp;</Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '10px' }}>
                    <InfoBox label="Vendor Id" value={vendorDetails?.vendorId} />
                    <InfoBox label="Company Name" value={vendorDetails?.companyName} />
                    <InfoBox label="Vendor Type" value={vendorDetails?.vendorType} />
                    <InfoBox label="Status" value={vendorDetails?.status} />
                    <InfoBox
                      label="Contract Date"
                      value={
                        vendorDetails?.contractDate ? new Date(vendorDetails.contractDate).toLocaleDateString('en-US') : null
                      }
                    />
                    <InfoBox
                      label="Contract Expires"
                      value={
                        vendorDetails?.contractExpires
                          ? new Date(vendorDetails.contractExpires).toLocaleDateString('en-US')
                          : null
                      }
                    />
                    <InfoBox
                      label="Status Updated"
                      value={
                        vendorDetails?.statusLastUpdated
                          ? new Date(vendorDetails.statusLastUpdated).toLocaleDateString('en-US')
                          : null
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={{ padding: '10px' }}>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{
                    maxHeight: '40px',
                    marginLeft: '12px',
                    marginTop: '8px',
                    backgroundColor: '#ffffff',
                    '&:hover': { backgroundColor: '#faf8f2' },
                    color: '#080600'
                  }}
                  onClick={updateStatus}
                >
                  {vendorDetails?.status === 'Active' ? 'Set As Inactive' : 'Set As Active'}
                </Button>
              </Box>
            </Box>
          </Paper>

          <Box sx={{ padding: '16px', paddingTop: '120px' }}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
              <Grid item xs={12} xl={8} rowSpacing={2}>
                <VendorDetailsCard vendorDetails={vendorDetails} isReadOnly={vendorDetails?.status !== 'Active'} />
                <Box sx={{ paddingTop: '16px' }}>
                  <ContactsCard vendorId={vendorDetails?.vendorId} vendorContacts={vendorContacts} />
                </Box>
                <Box sx={{ paddingTop: '16px' }}>
                  <DocumentsCard vendorId={vendorDetails?.vendorId} vendorDocuments={vendorDocuments} />
                </Box>
                {vendorDetails?.bankingRequired === true && (
                <>
                  <Box sx={{ paddingTop: '16px' }}>
                    <VendorsBankingInfoCard vendorBanks={vendorBanks} isReadOnly={vendorDetails?.status !== 'Active'} vendorId={vendorDetails?.vendorId} />
                  </Box>
                </>
                )}
              </Grid>
              <Grid item xs={12} xl={4} rowSpacing={2}>
                <Box>
                  <VendorTaxesEditableCard vendorDetails={vendorDetails} isReadOnly={vendorDetails?.status !== 'Active'} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
      {isLoading && <FullscreenSpinner />}
    </>
  );
};

const InfoBox = ({ label, value }) => (
  <Box sx={{ paddingLeft: '10px' }}>
    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
        {label}
      </Typography>
    </Box>
    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
        {value}
      </Typography>
    </Box>
  </Box>
);

const mapStateToProps = state => ({});

export default compose(withStyles(styles), connect(mapStateToProps, { showToast, updateVendor }))(VendorDetails);

/* eslint-disable react-hooks/exhaustive-deps */

import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';

import {
  getAuthClientPolicies,
  getAuthDateSummary,
  getAuthorizationSummary,
  getCommunicationInfo,
  getDepartureAddress,
  getDestinationAddress,
  getEmployeeInfo,
  getFamilyMembers,
  getFamilyPets,
  getFinanceAndTax,
  getServices,
  getTeam,
  getUserSettings,
  setAuthId
} from '../../store/actions/authorizations.actions';
import { setLoadingComplete } from 'modules/clients/clients.actions';
import { showToast } from 'modules/layout/layout.actions';
import CommentsAndNotesComponent from './commentsAndNotes.component';
import { fetchAuthDetails, fetchCommentsAndNotes } from 'modules/authorizations/utils/fetchDataDetailsService';
import { getCommentsAndNotes } from 'modules/authorizations/store/actions/commentsAndNotes.actions';

const CommentsAndNotesView = props => {
  const { authorizationDetails, currentAuthId, serviceOptionId, viewMode, id, match } = props;

  const details = authorizationDetails ? authorizationDetails[currentAuthId] : null;
  const [isLoading, setIsLoading] = useState(false);

  let authId = id ? id : match?.params?.id;

  useEffect(() => {
    const fetchData = async () => {
      props.setAuthId(authId);
      setIsLoading(true);

      await fetchAuthDetails(details, authId, props);
      await fetchCommentsAndNotes(details, authId, props);

      props.setLoadingComplete();
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      {!isLoading && details && (
        <CommentsAndNotesComponent
          details={details}
          authorizationId={authId}
          history={props.history}
          serviceOptionId={serviceOptionId}
          viewMode={viewMode}
        />
      )}
      {isLoading && <FullscreenSpinner />}
    </>
  );
};

CommentsAndNotesView.propTypes = {
  authorizationDetails: PropTypes.object,
  currentAuthId: PropTypes.string,
  match: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { authorizationDetails, isLoading, currentAuthId } = state.authorizations;
  return {
    authorizationDetails,
    isLoading,
    currentAuthId
  };
};

export default compose(
  connect(mapStateToProps, {
    setAuthId,
    setLoadingComplete,
    showToast,
    getAuthorizationSummary,
    getEmployeeInfo,
    getAuthDateSummary,
    getCommunicationInfo,
    getDepartureAddress,
    getDestinationAddress,
    getAuthClientPolicies,
    getTeam,
    getServices,
    getUserSettings,
    getFamilyPets,
    getFamilyMembers,
    getFinanceAndTax,
    getCommentsAndNotes
  })
)(CommentsAndNotesView);

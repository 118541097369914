/* eslint-disable react-hooks/exhaustive-deps */

import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import  { withStyles } from '@material-ui/core';

import {
  Box, Button, Checkbox, Dialog, DialogActions, FormControl, FormControlLabel, Grid, MenuItem, Paper, Select, TextField, Typography,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { DELETE_CANDIDATE_TRIP_FAILURE, GET_ALL_CANDIDATE_TRIPS_FAILURE, GET_CANDIDATE_SERVICES_FAILURE, GET_CANDIDATE_TRIP_FAILURE, POST_CANDIDATE_SERVICES_FAILURE, POST_CANDIDATE_TRIP_FAILURE, UPDATE_CANDIDATE_SERVICES_FAILURE } from 'modules/authorizations/store/types/canidateTripTypes';
import { deleteCandidateTrip, getCandidateServices, getCandidateTripById, getCandidateTrips, postCandidateServices, postCandidateTrip, updateCandidateServices } from 'modules/authorizations/store/actions/canidateTrip.actions';
import { getAuthDateSummary, getAuthorizationSummary, setAuthId, setLoadingComplete } from '../../store/actions/authorizations.actions';
import { getBrokerAgents, getBrokerAgentsById } from 'modules/authorizations/store/actions/dataManagement.actions';

import { calculateTripDuration } from 'modules/authorizations/utils';
import { formatDateForInput } from 'utilities/common';
import { showToast } from 'modules/layout/layout.actions';
import CandidateTripCard from './candidateTrip.component';
import CostEstimatesCard from './costEstimatesCard.component';
import EditableCard from 'modules/common/editableCard.component';
import Spinner from 'modules/common/spinner.component';
import SummaryCard from '../summaryCard.component';

const styles = (theme) => ({
  wideControl: {
    minWidth: 174,
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  selfServicePolicySelect:{
    minWidth: 300,
  },
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
});

const CandidateServices = (props) => {    

  const { brokerAgents, id, details, location, isReadOnly } = props;

  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(details && details?.authCanidateTrips?.candidateServices ? details?.authCanidateTrips?.candidateServices.status : 'Initiated');
  const [currentTripId, setCurrentTripId] = useState(details?.authCanidateTrips?.candidateTrips && details?.authCanidateTrips?.candidateTrips[0] ? details?.authCanidateTrips?.candidateTrips[0].tripId : null);
  const [newTripTitle, setNewTripTitle] = useState('');
  const [newSpouseTrip, setNewSpouseTrip] = useState(false);
  const [newTripStartDate, setNewTripStartDate] = useState(null);
  const [newTripEndDate, setNewTripEndDate] = useState(null);
  const [newTripDuration, setNewTripDuration] = useState(0);
  const [showAddTrip, setShowAddTrip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const brokerAgentSearchInfo = {
    searchingColumns: [{ name: 'typeOfAgent', title: 'Type of Agent' }, { name: 'agentName', title: 'Agent Name' }, { name: 'companyName', title: 'Company Name' }, { name: 'city', title: 'City' }, { name: 'state', title: 'State' }],
    searchingColumnExtensions: [{ columnName: 'typeOfAgent', width: 150 }, { columnName: 'agentName', width: 200 }, { columnName: 'companyName', width: 300 }, { columnName: 'city', width: 150 }, { columnName: 'state', width: 150 }],
    searchingRows: brokerAgents,
    nameKey: 'agentName',
    idKey: 'brokerId',
  };

  const candidateCardTabs = [
    { header: 'Candidate Services Overview', order: 1, iconPath: ['fas', 'fa-id-card'] },
  ];

  const candidateCardsSummaries = [
    { header: 'Initiation Date', accessorKey: 'initiationDate', type: 'date', required: false, value: details && details?.authCanidateTrips?.candidateServices ? details?.authCanidateTrips?.candidateServices.initiationDate : null, tab: 1 },
    { header: 'Area Tour', accessorKey: 'areaTour', type: 'checkbox', required: false, value: details && details?.authCanidateTrips?.candidateServices ? details?.authCanidateTrips?.candidateServices.areaTour : null, tab: 1 },
    { header: 'Area Tour Initiated Date', accessorKey: 'areaTourInitiatedDate', type: 'date', required: false, value: details && details?.authCanidateTrips?.candidateServices ? details?.authCanidateTrips?.candidateServices.areaTourInitiatedDate : null, tab: 1 },
    { header: 'Area Tour Canceled Date', accessorKey: 'areaTourCanceledDate', type: 'date', required: false, value: details && details?.authCanidateTrips?.candidateServices ? details?.authCanidateTrips?.candidateServices.areaTourCanceledDate : null, tab: 1 },
    { header: 'Area Tour Completed Date', accessorKey: 'areaTourCompletedDate', type: 'date', required: false, value: details && details?.authCanidateTrips?.candidateServices ? details?.authCanidateTrips?.candidateServices.areaTourCompletedDate : null, tab: 1 },
    { header: 'Area Tour Agent', accessorKey: 'areaTourAgentId', nameKey: 'areaTourAgentName', nameValue: details && details?.authCanidateTrips?.candidateServices ? details?.authCanidateTrips?.candidateServices.areaTourAgentName : '', type: 'advanced-search', searchInfo: brokerAgentSearchInfo, required: false, value: details && details?.authCanidateTrips?.candidateServices ? details?.authCanidateTrips?.candidateServices.areaTourAgentId : 0, tab: 1 },
    { header: 'Notes', accessorKey: 'notes', type: 'notes', required: false, value: details && details?.authCanidateTrips?.candidateServices ? details?.authCanidateTrips?.candidateServices.notes : '', tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);
    let newVals = { ...details?.authCanidateTrips?.candidateServices };
    newVals.authorizationId = props.id;

    newVals.status = currentStatus;
    newVals.initiationDate = values.initiationDate;
    newVals.areaTour = values.areaTour;
    newVals.areaTourInitiatedDate = values.areaTourInitiatedDate;
    newVals.areaTourCanceledDate = values.areaTourCanceledDate;
    newVals.areaTourCompletedDate = values.areaTourCompletedDate;
    newVals.areaTourAgentId = values.areaTourAgentId;
    newVals.areaTourAgentName = values.areaTourAgentName;
    newVals.notes = values.notes;
        
    if (details?.authCanidateTrips?.candidateServices && details?.authCanidateTrips?.candidateServices.length !== 0) {
      const resp = await props.updateCandidateServices(newVals);
      if (resp.type === UPDATE_CANDIDATE_SERVICES_FAILURE) {
        props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getCandidateServices(props.id);
        if (getResp.type === GET_CANDIDATE_SERVICES_FAILURE) {
          return false;
        } else {
          setIsLoading(false);
          return true; 
        }
      }
    } else {
      values.authorizationId = id;

      const resp = await props.postCandidateServices(values);
      if (resp.type === POST_CANDIDATE_SERVICES_FAILURE) {
        props.showToast('Failed to update candidate service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  const cancelService = async () => {
    setIsLoading(true);
    setCurrentStatus('Canceled');
    let newVals = { ...details?.authCanidateTrips?.candidateServices };
    newVals.authorizationId = props.id;
    newVals.status = currentStatus;

    if (details?.authCanidateTrips?.candidateServices && details?.authCanidateTrips?.candidateServices.length !== 0) {
      const resp = await props.updateCandidateServices(newVals);
      if (resp.type === UPDATE_CANDIDATE_SERVICES_FAILURE) {
        props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getCandidateServices(props.id);
        if (getResp.type === GET_CANDIDATE_SERVICES_FAILURE) {
          return false;
        } else {
          setIsLoading(false);
          return true; 
        }
      }
    } else {
      const resp = await props.postCandidateServices(newVals);
      if (resp.type === POST_CANDIDATE_SERVICES_FAILURE) {
        props.showToast('Failed to update candidate service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  const completeService = async () => {
    setIsLoading(true);
    setCurrentStatus('Complete');
    let newVals = { ...details?.authCanidateTrips?.candidateServices };
    newVals.authorizationId = props.id;
    newVals.status = currentStatus;

    if (details?.authCanidateTrips?.candidateServices && details?.authCanidateTrips?.candidateServices.length !== 0) {
      const resp = await props.updateCandidateServices(newVals);
      if (resp.type === UPDATE_CANDIDATE_SERVICES_FAILURE) {
        props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getCandidateServices(props.id);
        if (getResp.type === GET_CANDIDATE_SERVICES_FAILURE) {
          return false;
        } else {
          setIsLoading(false);
          return true; 
        }
      }
    } else {
      const resp = await props.postCandidateServices(newVals);
      if (resp.type === POST_CANDIDATE_SERVICES_FAILURE) {
        props.showToast('Failed to update candidate service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  const trip = details?.authCanidateTrips?.candidateTrips ? details?.authCanidateTrips?.candidateTrips.find((s) => s.tripId === currentTripId) : null;

  const createTrip = async () => {
    const authId = details?.authDetails?.summary.authorizationId;
    setIsLoadingModal(true);

    const resp = await props.postCandidateTrip({
      authorizationId: authId,
      tripName: newTripTitle,
      spouseTrip: newSpouseTrip,
      tripStartDate: newTripStartDate,
      tripEndDate: newTripEndDate,
      tripDuration: newTripDuration,
    });

    if (resp.type === POST_CANDIDATE_TRIP_FAILURE) {
      props.showToast('Failed to update the candidate trips info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setShowAddTrip(false);
    } else {
      const allTripsResp = await props.getCandidateTrips(authId);
      if (allTripsResp.type === GET_ALL_CANDIDATE_TRIPS_FAILURE) {
        props.showToast('Failed to fetch candidate trips, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('New Offer created successfully.', { userMustDismiss: false, severity: 'success' });
        setCurrentTripId(resp.response.tripId);
        setShowAddTrip(false);
      }
      setIsLoadingModal(false);
    }
  };

  const deleteTrip = async () => {
    const deleteTripResp = await props.deleteCandidateTrip(currentTripId);
    if (deleteTripResp.type === DELETE_CANDIDATE_TRIP_FAILURE) {
      props.showToast('Failed to delete the current candidate trip info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      props.showToast('Trip deleted successfully.', { userMustDismiss: false, severity: 'success' });
      setCurrentTripId(details?.authCanidateTrips?.candidateTrips[0].tripId);
      const allTripsResp = await props.getCandidateTrips(id);
      if (allTripsResp.type === GET_ALL_CANDIDATE_TRIPS_FAILURE) {
        props.showToast('Failed to get the candidate trips info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }     
    }
  };

  const getTripData = async () => {
    const tripResp = await props.getCandidateTripById(currentTripId);
    if (tripResp.type === GET_CANDIDATE_TRIP_FAILURE) {
      props.showToast('Failed to get the candidate trip info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } 
  };

  useEffect(() => {
    if (currentTripId) {
      getTripData();
    }
  }, [currentTripId]);

  return (
    <Box>
      <Box sx={{ padding: '20px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Paper
            square
            className={classNames('position-fixed', props.classes.headerFixed, props.classes.container)}
            sx={{
              position: 'sticky',
              paddingRight: '10px',
              paddingLeft: '10px',
              marginBottom: '5px',
              minWidth: '100%'
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px', marginBottom: '10px' }}>
              <Box />
              <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                <Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                      Move Type
                    </Typography>
                  </Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                      {details?.authDetails?.summary.moveType}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                      {details?.authDetails?.summary.moveType ? details?.authDetails?.summary.moveType : ''} Status
                    </Typography>
                  </Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                      {currentStatus}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                      Policy
                    </Typography>
                  </Box>
                  <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                      {details?.authDetails?.authClientPolicies?.clientPolicyCode}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {location && !location.pathname.includes('expenses') && !isReadOnly && (
                <>
                  <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{
                        maxHeight: '40px',
                        marginLeft: '12px',
                        marginTop: '8px',
                        backgroundColor: '#868686',
                        '&:hover': { backgroundColor: '#474747' }
                      }}
                      onClick={cancelService}
                    >
                      {' '}
                      Cancel Service{' '}
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{
                        maxHeight: '40px',
                        marginLeft: '12px',
                        marginTop: '8px',
                        backgroundColor: '#f0b11d',
                        '&:hover': { backgroundColor: '#ba8a00' }
                      }}
                    >
                      {' '}
                      Set On Hold{' '}
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                      onClick={completeService}
                    >
                      {' '}
                      Complete Service{' '}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Paper>
        </Grid>
        <Paper
          square
          className={classNames('position-fixed', props.classes.headerFixed, props.classes.container)}
          sx={{
            position: 'sticky',
            paddingRight: '10px',
            paddingLeft: '10px',
            marginBottom: '15px',
            minWidth: '100%'
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
            <Box>
              <div className={props.classes.mb2}>
                <Typography variant="subtitle2">Trip</Typography>
              </div>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Select
                  labelId={'tripSelect'}
                  key={'tripSelect'}
                  label={''}
                  name={'tripId'}
                  value={currentTripId}
                  sx={{ maxWidth: '360px', minWidth: '180px' }}
                  onChange={e => setCurrentTripId(e.target.value)}
                >
                  {details &&
                    details?.authCanidateTrips?.candidateTrips &&
                    details?.authCanidateTrips?.candidateTrips.map(opt => (
                      <MenuItem key={opt.tripId} value={opt.tripId} sx={{ margin: 'dense' }}>
                        {opt.tripName}
                      </MenuItem>
                    ))}
                </Select>
                {location && !location.pathname.includes('expenses') && !isReadOnly && (
                  <>
                    <Button
                      color="secondary"
                      type="submit"
                      variant="contained"
                      sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }}
                      onClick={() => setShowAddTrip(true)}
                    >
                      {' '}
                      New Trip{' '}
                    </Button>
                    <Button
                      color="secondary"
                      type="submit"
                      variant="contained"
                      sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: '#868686' }}
                      onClick={deleteTrip}
                    >
                      {' '}
                      Delete Trip{' '}
                    </Button>
                  </>
                )}
              </Box>
            </Box>
            {trip && trip.spouseTrip && (
              <>
                <Box>
                  <Box
                    style={{
                      marginTop: '10px',
                      padding: 20,
                      paddingTop: 10,
                      paddingBottom: 10,
                      borderRadius: 50,
                      backgroundColor: 'orange',
                      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
                    }}
                  >
                    <Typography style={{ fontSize: '1.25rem', color: 'white' }}>Spouse Trip</Typography>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Paper>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} xl={8} rowSpacing={2}>
            <Grid item sx={{ marginBottom: '10px' }}>
              <EditableCard
                tabs={candidateCardTabs}
                summaries={candidateCardsSummaries}
                numColumns={5}
                updateFields={updateFields}
                isLoading={isLoading}
                hideEdit={location.pathname.includes('expenses') || isReadOnly}
              />
            </Grid>
            {currentTripId && (
              <>
                <Grid item sx={{ marginBottom: '10px' }}>
                  <CandidateTripCard trip={trip} hideEdit={location.pathname.includes('expenses') || isReadOnly} />
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12} xl={4} rowSpacing={2}>
            <Grid item sx={{ marginBottom: '10px' }}>
              <SummaryCard dateSummary={details?.authDetails?.dateSummary} id={props.id} hideEdit={true} />
            </Grid>
            <Grid item sx={{ marginBottom: '10px' }}>
              <CostEstimatesCard
                costEstimate={details?.authDetails?.costEstimate}
                id={props.id}
                hideEdit={location.pathname.includes('expenses') || isReadOnly}
              />
            </Grid>
          </Grid>
          <Dialog open={showAddTrip} fullWidth={false} maxWidth={'lg'}>
            <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
              <Box sx={{ marginRight: '20px' }}>
                <TextField
                  key={'newTripTitle'}
                  label={'Trip Title'}
                  name={'newTripTitle'}
                  required={true}
                  disabled={false}
                  value={newTripTitle}
                  sx={{ maxWidth: '360px' }}
                  margin="dense"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setNewTripTitle(e.target.value)}
                />
                <Typography variant="caption" display="block" color="primary">
                  Required
                </Typography>
              </Box>
              <Box sx={{ marginRight: '20px' }}>
                <FormControl sx={{ marginTop: '20px', marginBottom: '1px' }}>
                  <FormControlLabel
                    key={'spouseTrip'}
                    name={'spouseTrip'}
                    label={'Spouse Trip'}
                    labelPlacement="start"
                    control={<Checkbox />}
                    required={false}
                    disabled={false}
                    checked={newSpouseTrip}
                    variant="standard"
                    margin="dense"
                    sx={{ marginTop: '6px', minWidth: '180px', maxWidth: '360px' }}
                    onClick={e => setNewSpouseTrip(e.target.checked)}
                  />
                </FormControl>
              </Box>
              <Box sx={{ marginRight: '20px' }}>
                <TextField
                  key={'newTripStartDate'}
                  label={'Start Date'}
                  name={'newTripStartDate'}
                  required={true}
                  disabled={false}
                  value={formatDateForInput(newTripStartDate)}
                  sx={{ maxWidth: '360px' }}
                  margin="dense"
                  variant="standard"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setNewTripStartDate(e.target.value)}
                />
                <Typography variant="caption" display="block" color="primary">
                  Required
                </Typography>
              </Box>
              <Box sx={{ marginRight: '20px' }}>
                <TextField
                  key={'newTripEndDate'}
                  label={'End Date'}
                  name={'newTripEndDate'}
                  required={true}
                  disabled={false}
                  value={formatDateForInput(newTripEndDate)}
                  sx={{ maxWidth: '360px' }}
                  margin="dense"
                  variant="standard"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setNewTripEndDate(e.target.value)}
                />
                <Typography variant="caption" display="block" color="primary">
                  Required
                </Typography>
              </Box>
              <Box sx={{ marginRight: '20px' }}>
                <TextField
                  key={'newTripDuration'}
                  label={'Trip Duration'}
                  name={'newTripDuration'}
                  required={true}
                  disabled={false}
                  value={newTripStartDate && newTripEndDate ? calculateTripDuration(newTripStartDate, newTripEndDate) : newTripDuration}
                  sx={{ maxWidth: '360px' }}
                  margin="dense"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setNewTripDuration(e.target.value)}
                />
              </Box>
            </Box>
            <DialogActions sx={{ p: '1.25rem' }}>
              {isLoadingModal && <Spinner />}
              {!isLoadingModal && (
                <>
                  <Button onClick={() => setShowAddTrip(false)}>Cancel</Button>
                  <Button
                    onClick={createTrip}
                    color="primary"
                    type="submit"
                    variant="contained"
                    disabled={!(newTripTitle && newTripTitle.length > 0)}
                  >
                    Create Trip
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>
        </Grid>
      </Box>
    </Box>
  );
};

CandidateServices.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getAuthDateSummary,
    getBrokerAgents,
    setAuthId,
    setLoadingComplete,
    getBrokerAgentsById,
    getCandidateServices,
    updateCandidateServices,
    postCandidateServices,
    getCandidateTripById,
    getCandidateTrips,
    postCandidateTrip,
    deleteCandidateTrip,
    showToast,
  }),
)(CandidateServices);
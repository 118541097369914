/* eslint-disable react-hooks/exhaustive-deps */

import { Box, ThemeProvider, createTheme } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { getVendorContacts, getVendorDocuments, getVendors, getVendorBanksByVendorId } from 'modules/authorizations/store/actions/dataManagement.actions';

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import VendorsDetails from './vendorsDetails.component';
import { GET_VENDOR_BANKING_INFO_BY_VENDOR_ID_FAILURE, GET_VENDOR_CONTACT_FAILURE, GET_VENDOR_DOCUMENTS_FAILURE, GET_VENDORS_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';

const styles = theme => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px'
  }
});

const VendorDetailsView = props => {
  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const vendorDetails = props.vendors ? props.vendors.find(v => v.vendorId === parseInt(props.match.params.id)) : null;

  useEffect(() => {
    async function fetchData () {
      if (!props.vendors) {
          let { type } = await props.getVendors();
          if (type === GET_VENDORS_FAILURE) {
              props.showToast('Failed to retrieve vendors, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          }

          let vendorContactsResp = await props.getVendorContacts();
          if (vendorContactsResp.type === GET_VENDOR_CONTACT_FAILURE) {
              props.showToast('Failed to retrieve vendor contacts, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          }
      }

      let vendorDocumentsResp = await props.getVendorDocuments(props.match.params.id);
      if (vendorDocumentsResp.type === GET_VENDOR_DOCUMENTS_FAILURE) {
          props.showToast('Failed to retrieve vendor documents, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }

      let vendorBanksResp = await props.getVendorBanksByVendorId(props.match.params.id);
      if (vendorBanksResp.type === GET_VENDOR_BANKING_INFO_BY_VENDOR_ID_FAILURE) {
          props.showToast('Failed to retrieve vendor banks, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
    }
    fetchData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!props.isLoading && vendorDetails && <VendorsDetails vendorDetails={vendorDetails} vendorContacts={props.vendorContacts} vendorDocuments={props.vendorDocuments} vendorBanks={props.vendorBanks} />}
        {props.isLoading && <FullscreenSpinner />}
      </Box>
    </ThemeProvider>
  );
};

VendorDetailsView.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const {
    authorizations: { vendors, vendorContacts, vendorDocuments, vendorBanks, isLoading }
  } = state;

  return { vendors, vendorContacts, vendorDocuments, vendorBanks, isLoading };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getVendors,
    getVendorContacts,
    getVendorDocuments,
    getVendorBanksByVendorId,
  })
)(VendorDetailsView);

export const GET_COMMENT_AND_NOTES_FAILURE = 'GET_COMMENT_AND_NOTES_FAILURE';
export const GET_COMMENT_AND_NOTES_REQUEST = 'GET_COMMENT_AND_NOTES_REQUEST';
export const GET_COMMENT_AND_NOTES_SUCCESS = 'GET_COMMENT_AND_NOTES_SUCCESS';

export const POST_COMMENT_AND_NOTES_FAILURE = 'POST_COMMENT_AND_NOTES_FAILURE';
export const POST_COMMENT_AND_NOTES_REQUEST = 'POST_COMMENT_AND_NOTES_REQUEST';
export const POST_COMMENT_AND_NOTES_SUCCESS = 'POST_COMMENT_AND_NOTES_SUCCESS';

export const UPDATE_COMMENT_AND_NOTES_FAILURE = 'UPDATE_COMMENT_AND_NOTES_FAILURE';
export const UPDATE_COMMENT_AND_NOTES_REQUEST = 'UPDATE_COMMENT_AND_NOTES_REQUEST';
export const UPDATE_COMMENT_AND_NOTES_SUCCESS = 'UPDATE_COMMENT_AND_NOTES_SUCCESS';

export const DELETE_COMMENT_AND_NOTES_FAILURE = 'DELETE_COMMENT_AND_NOTES_FAILURE';
export const DELETE_COMMENT_AND_NOTES_REQUEST = 'DELETE_COMMENT_AND_NOTES_REQUEST';
export const DELETE_COMMENT_AND_NOTES_SUCCESS = 'DELETE_COMMENT_AND_NOTES_SUCCESS';

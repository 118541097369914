/* eslint-disable react-hooks/exhaustive-deps */

import  { Grid, withStyles } from '@material-ui/core';

import {
  Box, Button, MenuItem, Paper, Select, Tab, Tabs, Typography,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { getHhgEstimates, getHhgItems, postHhgEstimate } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import { getHhgMovingDates, getHhgSegments, postHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';

import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS, DRAWER_WIDTH_OPEN } from 'styles/theme';
import classNames from 'classnames';

import { GlobalDestinationServices } from './DestServices/globalDestinationServices.component';
import AssignmentLanding from './assignmentLanding.component';
import PropertyManagement from './propertyManagement.component';
import countries from 'modules/common/countries.json';
import queryString from 'query-string';
import VisaTab from './visaTab.component';
import ServiceTriggeredComment from '../commentsAndNotes/serviceTriggeredComment.component';
import SlidingCommentsAndNotes from '../commentsAndNotes/commentsAndNotesSlidingPanel.component';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  container: {
    height: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`,
    },
  },
  drawerOpen: {
    width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
  },
  headerFixed: {
    zIndex: 4,
  },
  tabFixed: {
    paddingTop: '100px',
    zIndex: 3,
  }, 
  estimateHeaderFixed: {
    paddingTop: '150px',
    zIndex: 2,
  },
});

const validTabs = ['ASSIGNMENT', 'VISA', 'DESTINATION', 'PROPERTY', 'FINANCIAL'];

const isValidQueryStringTab = (tab) => validTabs.includes(tab);

const getDefaultTab = (location) => {
  const tabFromQuery = queryString.parse(location.search)?.tab ?? 'ASSIGNMENT';
  return isValidQueryStringTab(tabFromQuery) ? tabFromQuery : 'ASSIGNMENT';
};

const GlobalService = (props) => {    
  const { details, id, location, shouldShowSideDrawer, isReadOnly } = props;
  const [currentTab, setCurrentTab] = useState(getDefaultTab(location));

  const [hostServiceSelected, setHostServiceSelected] = useState(true);
  const [familyMemberId, setFamilyMemberId] = useState(0);

  const [showTriggeredComment, setShowTriggeredComment] = useState(false);
  const [showComment, setShowComment] = useState(false);

  const completeService = () => {
    setShowTriggeredComment(true);
  }
  

  const changeTab = (tab) => {
    setCurrentTab(tab);
    const values = queryString.parse(location.search);
    values.tab = tab;
    props.history.replace(`${location.pathname}?${queryString.stringify(values)}`);
  };

  return (
    <>
    <Box>
      {showComment && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1200, 
            pointerEvents: 'none',
          }}
        />
      )}
      <Paper
        square
        className={classNames({
          'position-fixed': !isReadOnly,
          [props.classes.headerFixed]: !isReadOnly,
          [props.classes.container]: true,
          [props.classes.drawerOpen]: shouldShowSideDrawer
        })}
        sx={isReadOnly ? { paddingTop: '5px', zIndex: 4, position: 'relative'  } : {}}
      >    
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                {details?.authGlobal?.global?.type === 3 ? 'Destination' : 'Host'} Location
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  {(details?.authGlobal?.global && details?.authGlobal?.global.country) ? countries[details?.authGlobal?.global.country - 1].title : details?.authDetails?.destinationAddress.country}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  Move Type
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  {details?.authDetails?.summary.moveType}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                  Policy
                </Typography>
              </Box>
              <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                  {details?.authDetails?.authClientPolicies?.clientPolicyCode}
                </Typography>
            </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
          {!isReadOnly && <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: '#868686', '&:hover': { backgroundColor: '#474747' } }}> Cancel Service </Button> }
            {!isReadOnly && <Button color="secondary"variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: '#f0b11d', '&:hover': { backgroundColor: '#ba8a00' } }}> Set On Hold </Button>}
            {!isReadOnly && <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }} onClick={completeService}> Complete Service </Button>}
            <Button
              onClick={() => setShowComment(true)}
              variant="contained"
              sx={{
                maxHeight: '40px',
                marginLeft: '12px',
                marginTop: '8px',
                backgroundColor: '#41B7C0',
                color: 'white',
                '&:hover': { backgroundColor: '#3d959c' }
              }}
            >
              Comments
            </Button>
          </Box>
        </Box>
      </Paper>
      <Paper
        square
        className={classNames({
          'position-fixed': !isReadOnly,
          [props.classes.tabFixed]: !isReadOnly,
          [props.classes.container]: true,
          [props.classes.drawerOpen]: shouldShowSideDrawer
        })}
        sx={isReadOnly ? { paddingTop: '5px', zIndex: 3, position: 'relative'  } : {}}
      >
        <Tabs
          variant="fullWidth"
          value={currentTab}
          onChange={(event, tab) => changeTab(tab)}
          sx={{}}
        >
          <Tab value={'ASSIGNMENT'} label="Assignment Details" />
          <Tab value={'VISA'} label="Visa & Vaccine" />
          <Tab value={'DESTINATION'} label="Destination Services" />
          <Tab value={'PROPERTY'} label="Property Management" />
          <Tab value={'FINANCIAL'} label="Financial" />
        </Tabs>
      </Paper>

      {currentTab === 'PROPERTY' && (
        <Paper
        square
        className={classNames({
          'position-fixed': !isReadOnly,
          [props.classes.estimateHeaderFixed]: !isReadOnly,
          [props.classes.container]: true,
          [props.classes.drawerOpen]: shouldShowSideDrawer
        })}
        sx={isReadOnly ? { paddingTop: '5px', zIndex: 2, position: 'relative'  } : {}}
        >  
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '20px' }}>
              <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: hostServiceSelected ? '#5D9878' : '#ffffff', '&:hover': { backgroundColor: hostServiceSelected ? '#416A54' : '#faf8f2' }, color: hostServiceSelected ? 'ffffff' : '#080600' }} onClick={() => setHostServiceSelected(true)}> Host Service </Button>
              <Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: !hostServiceSelected ? '#5D9878' : '#ffffff', '&:hover': { backgroundColor: !hostServiceSelected ? '#416A54' : '#faf8f2' }, color: !hostServiceSelected ? 'ffffff' : '#080600' }} onClick={() => setHostServiceSelected(false)}> Home Service </Button>
            </Box>
            <Box />
          </Box>
        </Paper>
      )}

      {currentTab === 'VISA' && (
        <Paper
          square
          className={classNames({
            'position-fixed': !isReadOnly,
            [props.classes.estimateHeaderFixed]: !isReadOnly,
            [props.classes.container]: true,
            [props.classes.drawerOpen]: shouldShowSideDrawer
          })}
          sx={isReadOnly ? { paddingTop: '5px', zIndex: 2, position: 'relative'  } : {}}
        >  
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '20px' }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '1rem', textAlign: 'center', paddingTop: '12px', paddingRight: '16px' }}>
                Family Member
              </Typography>
              <Select
                labelId={'familyMemberId'}
                key={'familyMemberId'}
                label={''}
                name={'familyMemberId'}
                value={familyMemberId}
                sx={{ maxWidth: '360px', minWidth: '180px' }}
                onChange={(e) =>
                  setFamilyMemberId(e.target.value)
                }
              >
                {details && details.authDetails && details.authDetails.summary && (
                  <MenuItem key={0} value={0} sx={{ margin: 'dense' }}>
                    {`${details.authDetails.summary.transfereeName} (Transferee)`}
                  </MenuItem>
                )}

                {details && details.authDetails && details.authDetails.family && details.authDetails.family.map((opt) => (
                  <MenuItem key={opt.familyMemberId} value={opt.familyMemberId} sx={{ margin: 'dense' }}>
                    {`${opt.firstName} ${opt.lastName} (${opt.relationship})`}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Paper>
      )}

      <Box sx={{ padding: '20px', paddingTop:  isReadOnly ? '20px' : (currentTab === 'PROPERTY' || currentTab === 'VISA') ? '245px' : '165px' }}>
        {currentTab === 'ASSIGNMENT' && <AssignmentLanding details={details} id={id} isReadOnly={isReadOnly}/>}
        {currentTab === 'VISA' && <VisaTab details={details} id={id} familyMemberId={familyMemberId} isReadOnly={isReadOnly}/>}
        {currentTab === 'DESTINATION' && <GlobalDestinationServices details={details} id={id} isReadOnly={isReadOnly}/>}
        {currentTab === 'PROPERTY' && <PropertyManagement details={details} id={id} hostServiceSelected={hostServiceSelected} isReadOnly={isReadOnly}/>}
        {currentTab === 'FINANCIAL' && 
          <Box sx={{ width: '100%', minHeight: '300px' }}>
            <Grid container spacing={2} sx={{ padding: '20px' }}>
              <Grid item xs={12}>
                <h1> FINANCIAL</h1>
              </Grid>
            </Grid>
          </Box>
        }
      </Box>
    </Box>
    {showTriggeredComment && (
        <ServiceTriggeredComment
          authorizationId={id}
          serviceOptionId={5}
          displayText={'Global - Service Completed Comment'}
          setShowTriggeredComment={setShowTriggeredComment} 
        />
      )}
    {showComment && (
        <SlidingCommentsAndNotes
          authorizationId={id}
          serviceOptionId={5}
          setShowComment={setShowComment}
          isOpen={true}
        />
      )}
    </>
  );

};

GlobalService.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    shouldShowSideDrawer: state.layout.shouldShowSideDrawer,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getHhgSegments,
    postHhgSegment,
    showToast,
    getHhgMovingDates,
    getHhgItems,
    getHhgEstimates,
    postHhgEstimate,
  }),
)(GlobalService);
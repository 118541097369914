/* eslint-disable array-callback-return */
import { postCommunicationInfo, updateCommunicationInfo } from '../store/actions/authorizations.actions';
import { POST_COMMUNICATION_INFO_FAILURE, UPDATE_COMMUNICATION_INFO_FAILURE } from '../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import React, { useState } from 'react';
import NotesAndCommunicationEditableCard from './notesAndCommunicationEditableCard.component';

const NotesAndCommunication = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const authServices = props.authServices ? props.authServices : {};
  const communication = props.communication ? props.communication : {};
  const commentsAndNotes = props.commentsAndNotes ? props.commentsAndNotes : {};
  let tempComments = [];

  // if the commentsAndNotes array contains any comments:
  if(commentsAndNotes && commentsAndNotes.length > 0)
  {
    // iterate through and grab the pinned comments. Push to pinnedComments
    Object.values(commentsAndNotes).map(value => {
      if(value.pinnedComment === true)
      {
        tempComments.push(value);
      }
    });
  }

  // send that value to the component

const updateFields = async (tab, values) => {
    setIsLoading(true);

    values.authorizationId = props.id;
        
    if (communication && communication.length !== 0) {
        const resp = await props.updateCommunicationInfo(values);
        if (resp.type === UPDATE_COMMUNICATION_INFO_FAILURE) {
        props.showToast('Failed to update the employee communication info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
        } else { 
        setIsLoading(false);
        return true; 
        }
    } else {
        const resp = await props.postCommunicationInfo(values);
        if (resp.type === POST_COMMUNICATION_INFO_FAILURE) {
        props.showToast('Failed to update employee communication info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
        } else { 
        setIsLoading(false);
        return true; 
        }
    }
    };
    
  return (
    <NotesAndCommunicationEditableCard
      servicesInfo={authServices}
      comments={commentsAndNotes}
      pinnedComments={tempComments}
      numColumns={2} 
      updateFields={updateFields}
      isLoading={isLoading}
      authorizationId={props.id}
    />
  );

};

export default compose(
  connect(null, {
    postCommunicationInfo,
    updateCommunicationInfo,
    showToast,
  }),
)(NotesAndCommunication);
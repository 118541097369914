/* eslint-disable no-case-declarations */
import {
  DELETE_COMMENT_AND_NOTES_SUCCESS,
  GET_COMMENT_AND_NOTES_SUCCESS,
  POST_COMMENT_AND_NOTES_SUCCESS,
  UPDATE_COMMENT_AND_NOTES_SUCCESS
} from '../types/commentsAndNotesTypes';

const handleCommentsAndNotesReducer = (draft, action) => {
  if (draft.currentAuthId && !draft.authorizationDetails[draft.currentAuthId]) draft.authorizationDetails[draft.currentAuthId] = {};
  if (draft.currentAuthId && !draft.authorizationDetails[draft.currentAuthId].commentsAndNotes)
    draft.authorizationDetails[draft.currentAuthId].commentsAndNotes = [];

  switch (action.type) {
    case GET_COMMENT_AND_NOTES_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].commentsAndNotes = action.response;
      break;

    case POST_COMMENT_AND_NOTES_SUCCESS: {
      const commentsAndNotes = draft.authorizationDetails[draft.currentAuthId]?.commentsAndNotes || [];
      draft.authorizationDetails[draft.currentAuthId].commentsAndNotes = [...commentsAndNotes, action.response];
      break;
    }

    case UPDATE_COMMENT_AND_NOTES_SUCCESS: {
      const commentsAndNotes = draft.authorizationDetails[draft.currentAuthId]?.commentsAndNotes;
      if (commentsAndNotes) {
        const indexToUpdate = commentsAndNotes.findIndex(s => s.commentId === action.response?.commentId);
        if (indexToUpdate !== -1) {
          draft.authorizationDetails[draft.currentAuthId].commentsAndNotes = commentsAndNotes.map((comment, index) =>
            index === indexToUpdate ? action.response : comment
          );
        }
      }
      break;
    }

    //TODO -We should return the ID in the api response or find some way to get the id of the item we want to delete
    case DELETE_COMMENT_AND_NOTES_SUCCESS: {
      const deletedCommentId = parseInt(action.response.split(' ')[1], 10);
      const commentsAndNotes = draft.authorizationDetails[draft.currentAuthId]?.commentsAndNotes;
      if (commentsAndNotes) {
        draft.authorizationDetails[draft.currentAuthId].commentsAndNotes = commentsAndNotes.filter(
          comment => comment.commentId !== deletedCommentId
        );
      }
      break;
    }

    default:
      break;
  }
  return draft;
};

export default handleCommentsAndNotesReducer;

import { TemporaryLivingStatus } from '../TempLivingConstants';

export const getNextStatus = (currentStatus) => {
  let nextStatus = null;
  const nextOrder = currentStatus.sequenceOrder + 1;

  Object.values(TemporaryLivingStatus).forEach((status) => {
    if (status.sequenceOrder === nextOrder) {
      nextStatus = status;
    }
  });

  return nextStatus;
};

export const getStatusKey = (obj) => {
  for (const key in TemporaryLivingStatus) {
    if (TemporaryLivingStatus[key] === obj) {
      return key;
    }
  }
  return null;
};

export const createFormTabs = (currentStatus) => {
  const tempLivingHeaderTabs = [
    { header: 'Initialization', order: 1, iconPath: ['fas', 'user'], isDisabled: false },
    { header: 'Property', order: 2, iconPath: ['fas', 'house'], isDisabled: false },
    { header: 'Moved In/Moved Out', order: 3, iconPath: ['fas', 'truck-moving'], isDisabled: true },
  ];
  if (currentStatus >= TemporaryLivingStatus.Initiated.sequenceOrder) {
    tempLivingHeaderTabs[2].isDisabled = false;
  }
  return tempLivingHeaderTabs;
};

export const getSelectedTab = (isNewForm, currentStatus) => {
  if (isNewForm) {
    return 1;
  } else if (currentStatus === 0) {
    return 2;
  } else {
    return 3;
  }
};
